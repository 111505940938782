import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getMediaMgtData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/mediaManagement/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `mediaMgt`,
      message: `Something went wrong in getMediaMgtData API => ${error}`,
    });
    logMessage(
      `mediaMgt`,
      `Something went wrong in getMediaMgtData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getMediaMgtData api endpoint");
  }
};

export const getMediaMgt = createAsyncThunk(
  "mediaMgt/getMediaMgt",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getMediaMgtData(instanceId, rejectWithValue);
  }
);

export const getMediaFile = createAsyncThunk(
  "mediaMgt/getMediaFile",
  async (mediaMgtData, { rejectWithValue }) => {
    const { instanceId, languageCode, language, fileName, type } = mediaMgtData;
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/mediaFile/${instanceId}/${languageCode}-${language}/${fileName}/${type}`,
        method: "GET",
      });
      return response;
    } catch (error) {
      clientLog({
        component: `mediaMgt`,
        message: `Something went wrong in getMediaFile API => ${error}`,
      });
      logMessage(
        `mediaMgt`,
        `Something went wrong in getMediaFile API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getMediaFile api endpoint");
    }
  }
);

export const createOrUpdateMediaMgt = createAsyncThunk(
  "mediaMgt/createOrUpdateMediaMgt",
  async (mediaMgtData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/mediaManagement/${instanceId}`,
        method: "POST",
        body: { ...mediaMgtData },
      });
      return await getMediaMgtData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `mediaMgt`,
        message: `Something went wrong in createOrUpdateMediaMgt API => ${error}`,
      });
      logMessage(
        `mediaMgt`,
        `Something went wrong in createOrUpdateMediaMgt API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in createOrUpdateMediaMgt api endpoint");
    }
  }
);

export const deleteMediaMgt = createAsyncThunk(
  "mediaMgt/deleteMediaMgt",
  async (mediaMgtData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/mediaManagement/${instanceId}`,
        method: "DELETE",
        body: { ...mediaMgtData },
      });
      return await getMediaMgtData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `mediaMgt`,
        message: `Something went wrong in deleteMediaMgt API => ${error}`,
      });
      logMessage(
        `mediaMgt`,
        `Something went wrong in deleteMediaMgt API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in deleteMediaMgt api endpoint");
    }
  }
);

export const getSubActiveTabId = createAction("mediaMgt/getSubActiveTabId");

export const getMediaExpandCollapseInfo = createAction(
  "mediaMgt/getMediaExpandCollapseInfo"
);

export const getResetMediaMgt = createAction("mediaMgt/getResetMediaMgt");
export const getMediaShowEdit = createAction("mediaMgt/getMediaShowEdit");
export const getMediaHideEdit = createAction("mediaMgt/getMediaHideEdit");
