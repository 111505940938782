export const columns = [
  {
    label: "Name",
    id: "Name",
    minWidth: 1000,
    sortable: true,
  },
  {
    label: "Action",
    id: "",
    minWidth: 200,
    sortable: true,
  },
];
