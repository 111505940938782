import SbuxCard from "../../../components/SbuxCard";
import { useSelector, useDispatch } from "react-redux";
import MasterTable from "./table/MasterTable";
import SbuxFallback from "../../../components/SbuxFallback";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import { getCallHistory } from "../../../services/history";
import { TRANSCRIPTS_FEATURE_FLAG_NAME } from "../../../constants";
import {
  getHistoryDataSelector,
  getStatusSelector,
} from "../../../selectors/historySelector";
import { selectFeatureFlags } from "../../../selectors/featureFlagSelector";

const History = () => {
  const dispatch = useDispatch();

  const historyData = useSelector(getHistoryDataSelector);
  const status = useSelector(getStatusSelector);
  const featureFlags = useSelector(selectFeatureFlags);

  const areTranscriptsEnabledForInstance =
    featureFlags?.find((ff) => ff?.name === TRANSCRIPTS_FEATURE_FLAG_NAME)
      ?.enabled ?? false;

  const handleError = (error) => {
    clientLog({
      component: `History`,
      message: `Something went wrong in History Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in History`,
      `Something went wrong in History Component => ${error}`,
      `error`
    );
  };

  const handleRefrehClick = async () => {
    await dispatch(getCallHistory());
  };

  return (
    <>
      <ErrorBoundary
        FallbackComponent={SbuxFallback}
        onError={handleError}
        onReset={handleRefrehClick}
      >
        <SbuxCard className="insideTabContainer">
          {status === "rejected" ? (
            <SbuxFallback />
          ) : (
            <MasterTable
              historyData={historyData}
              handleRefrehClick={handleRefrehClick}
              areTranscriptsEnabledForInstance={
                areTranscriptsEnabledForInstance ?? false
              }
            />
          )}
        </SbuxCard>
      </ErrorBoundary>
    </>
  );
};

export default History;
