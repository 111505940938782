import { createSlice } from "@reduxjs/toolkit";
import { getUserInstance, getConnectedInstance } from "../services/instance";

const initialState = {
  status: "idle",
  instances: [],
  connectedInstance: null,
  showModal: false,
};

const instanceSlice = createSlice({
  name: "userInstance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserInstance.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getUserInstance.fulfilled, (state, action) => {
        const { payload: { data: { instances = [] } = {} } = {} } = action;
        state.status = "success";
        state.instances = instances;
        state.showModal = instances && instances.length > 1 ? true : false;
        state.connectedInstance =
          instances && instances.length === 1 ? instances[0] : null;
      })
      .addCase(getUserInstance.rejected, (state) => {
        state.status = "rejected";
        state.instances = [];
        state.showModal = false;
      })
      .addCase(getConnectedInstance, (state, action) => {
        const { payload } = action;
        state.connectedInstance = payload;
        state.showModal = false;
      });
  },
});

export default instanceSlice.reducer;
