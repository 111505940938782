import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";
import { ellipse, getTitleForEllipse } from "../../../../utils/stringUtil";
import {
  QUEUE_METRICS_CALL_WAITING_WARNING_COUNT,
  QUEUE_METRICS_CALL_WAITING_THRSHOLD_COUNT,
} from "../../../../constants";
import { useSelector } from "react-redux";
import Icon from "@cxeweb/pattern-library/lib/components/icon";
import warningIcon from "@cxeweb/pattern-library/lib/icons/caution-solid";
import dangerIcon from "@cxeweb/pattern-library/lib/icons/close-solid";
import {
  selectCallsWaitingWarningThreshold,
  selectCallsWaitingErrorThreshold,
} from "../../../../selectors/userInstanceSelector";

const MetricsRow = ({ row }) => {
  const callsWaitingWarningThreshold = useSelector(
    selectCallsWaitingWarningThreshold
  );
  const callsWaitingErrorThreshold = useSelector(
    selectCallsWaitingErrorThreshold
  );

  const callsWaitingWarningThresholdCount = callsWaitingWarningThreshold
    ? callsWaitingWarningThreshold
    : QUEUE_METRICS_CALL_WAITING_WARNING_COUNT;
  const callsWaitingErrorThresholdCount = callsWaitingErrorThreshold
    ? callsWaitingErrorThreshold
    : QUEUE_METRICS_CALL_WAITING_THRSHOLD_COUNT;

  const displayIcons = () => {
    return (
      <div style={{ display: "flex" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            paddingLeft: "40%",
          }}
        >
          {row.contactsInQueue}
        </div>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            paddingRight: "20%",
          }}
        >
          {row.contactsInQueue >= callsWaitingWarningThresholdCount &&
          row.contactsInQueue < callsWaitingErrorThresholdCount ? (
            <Icon size="20px" path={warningIcon} className="color-yellow" />
          ) : (
            row.contactsInQueue >= callsWaitingErrorThresholdCount && (
              <Icon size="20px" path={dangerIcon} className="color-red" />
            )
          )}
        </div>
      </div>
    );
  };
  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell title={getTitleForEllipse(row.queueName)}>
          {ellipse(row.queueName)}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentsOnline}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentsAvailable}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentsOnContact}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentsAfterContactWork}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentsNonProductive}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell>{displayIcons()}</SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.oldestContactAge}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.contactsScheduled}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default MetricsRow;
