import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import HeaderCrate from "./HeaderCreate";
import ContentCrate from "./ContentCrate";
import Navbar from "./Navbar";
import Footer from "../components/SbuxFooter";
import { ThemeProvider } from "@mui/material/styles";
import { Grid, Box } from "@mui/material";
import { default as theme } from "../themes/sbux";
import { getConnectedInstanceSelector } from "../selectors/userInstanceSelector";

const CrateLayout = ({ children }) => {
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const [instanceSelection, setInstanceSelection] = useState(false);

  useEffect(() => {
    if (!instanceSelection && !!connectedInstance) {
      setInstanceSelection(true);
    }
  }, [connectedInstance]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid
          container
          rowSpacing={1}
          columnSpacing={{ xs: 2, md: 3 }}
          columns={{ xs: 12, md: 12 }}
        >
          <Grid item xs={12} md={12}>
            <Navbar />
          </Grid>

          <Grid container spacing={2} item xs={12} md={12}>
            <Grid item xs={12} md={4}>
              <HeaderCrate />
            </Grid>
            <Grid item xs={12} md={8}>
              <ContentCrate />
              {children}
            </Grid>
          </Grid>

          <Grid item xs={12} md={12}>
            <Box>{instanceSelection && <Footer />}</Box>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default CrateLayout;
