const styles = (theme) => ({
  ccpRoot: {
    height: "79.2vh",
    position: "relative",
    overflow: "hidden",
  },
  overlayContainer: {
    display: "flex",
  },
  overlayButton: {
    flex: 1,
    textAlign: "center",
    position: "absolute",
    fontSize: "1.5rem",
    fontWeight: 600,
    padding: 15,
    minHeight: 60,
    bottom: "0",
    cursor: "pointer",
    background: "grey",
    color: theme.palette.common.white,
    width: "100%",
    height: "75px" /* default height */,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    /*
     * IMPORTANT: If the CCP close-button-panel changes config, this style might
     * require adjustments again.
     */
    "@media only screen and (max-width: 899px)": {
      height: "110px",
    },
    "@media only screen and (min-width: 900px) and (max-width: 1041px)": {
      height: "220px",
    },
    "@media only screen and (min-width: 1042px)": {
      height: "150px",
    },
    "@media only screen and (max-width: 353px)": {
      height: "220px",
    },
  },
  overlayDropdownRoot: {
    display: "flex",
    background: `${theme.palette.darkGray.main} !important`,
    color: theme.palette.common.white,
    paddingTop: 10,
    paddingLeft: 20,
    paddingBottom: 3,
    paddingRight: 8,
    gap: 8,
    alignItems: "center",
  },
  overlayDropdownLabel: {
    fontSize: 14,
    fontWeight: 600,
    display: "inline-flex",
    textWrap: "nowrap",
  },
  overlayDropdown: {
    position: "relative",
    fontWeight: 600,
    cursor: "pointer",
    width: "100%",
    marginRight: 4,
    flex: 1,
    maxWidth: "400px",
    [theme.breakpoints.down('sm')]: {
      maxWidth: "100%",
    },
    [theme.breakpoints.up('md')]: {
      display: "inline-grid"
    },
  },

  iframeDisplay: {
    display: "none",
  },
  iframeLockOverlay: {
    width: "100%",
    height: 640,
    position: "absolute",
    top: "0",
    left: "0.5%",
    cursor: "not-allowed",
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    border: "1px solid lightgrey",
    height: "88vh",
  },
  heading: {
    flex: 1,
    marginTop: "40%",
  },
  outboundPhoneList: {
    color: `${theme.palette.common.white} !important`,
    "& > .MuiMenuItem-root": {
      fontWeight: 400,
      "&& .Mui-selected": {
        backgroundColor: "rgb(71, 89, 110)",
        color: theme.palette.common.white,
      },
      "&&:hover .Mui-selected": {
        backgroundColor: "rgb(71, 89, 110)",
        color: theme.palette.common.white,
      },
    },
    "& > .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.common.white} !important`
    },
    "& > .MuiSvgIcon-root": {
      color: `${theme.palette.common.white} !important`
    },
  }
});

export default styles;
