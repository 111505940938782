import { createSlice } from "@reduxjs/toolkit";
import {
  setOutboundCallerId,
  getCallEndedStatus,
} from "../services/outboundCall";

const initialState = {
  outboundPhoneCallStatus: "idle",
  showLoadingIndicator: false,
  outboundPhoneCallerId: null,
  isCallEnded: false,
};

const outboundCallSlice = createSlice({
  name: "outboundCall",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(setOutboundCallerId.pending, (state) => {
        state.outboundPhoneCallStatus = "pending";
        state.showLoadingIndicator = true;
        state.isCallEnded = false;
        state.outboundPhoneCallerId = null;
      })
      .addCase(setOutboundCallerId.fulfilled, (state, action) => {
        const { payload } = action;
        state.outboundPhoneCallStatus = "success";
        state.showLoadingIndicator = false;
        state.isCallEnded = false;
        state.outboundPhoneCallerId = payload;
      })
      .addCase(setOutboundCallerId.rejected, (state, action) => {
        state.outboundPhoneCallStatus = "rejected";
        state.showLoadingIndicator = false;
        state.isCallEnded = false;
        state.outboundPhoneCallerId = null;
      })
      .addCase(getCallEndedStatus, (state, action) => {
        state.isCallEnded = true;
      });
  },
});

export default outboundCallSlice.reducer;
