import { createSlice } from "@reduxjs/toolkit";
import {
  getFeatureFlags,
  createOrUpdateFeatureFlags,
  deleteFeatureFlags,
  getTicketOrCaseFeatureFlagEnabled,
} from "../services/featureFlags";
import { CURRENT_TICKET_OR_CASE_NUMBER } from "../constants";

const initialState = {
  status: "idle",
  ticketOrCaseFeatureFlagEnabled: false,
  featureFlags: [],
};

const getFeatureFlagsDataFromPayload = (payload) => {
  return (
    payload &&
    payload.reduce((acc, cur) => {
      acc.push({
        ...cur,
      });
      return acc;
    }, [])
  );
};

const featureFlagsSlice = createSlice({
  name: "featureFlag",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFeatureFlags.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getFeatureFlags.fulfilled, (state, action) => {
        const { payload: { data: { featureFlags = [] } = {} } = {} } = action;
        const ticketOrCaseFeatureFlag = featureFlags.find(
          (flag) => flag.name === CURRENT_TICKET_OR_CASE_NUMBER
        );
        state.status = "success";
        state.featureFlags = getFeatureFlagsDataFromPayload(featureFlags);
        state.ticketOrCaseFeatureFlagEnabled =
          ticketOrCaseFeatureFlag &&
          Object.keys(ticketOrCaseFeatureFlag).length > 0
            ? ticketOrCaseFeatureFlag.enabled
            : false;
      })
      .addCase(getFeatureFlags.rejected, (state) => {
        state.status = "rejected";
        state.ticketOrCaseFeatureFlagEnabled = false;
      })
      .addCase(createOrUpdateFeatureFlags.fulfilled, (state, action) => {
        const { payload: { data: { featureFlags = [] } = {} } = {} } = action;
        state.featureFlags = getFeatureFlagsDataFromPayload(featureFlags);
        state.status = "success";
      })
      .addCase(createOrUpdateFeatureFlags.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(deleteFeatureFlags.fulfilled, (state, action) => {
        const { payload: { data: { featureFlags = [] } = {} } = {} } = action;
        state.featureFlags = getFeatureFlagsDataFromPayload(featureFlags);
        state.status = "success";
      })
      .addCase(deleteFeatureFlags.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(getTicketOrCaseFeatureFlagEnabled, (state, action) => {
        const { payload } = action;
        state.ticketOrCaseFeatureFlagEnabled = payload;
      });
  },
});

export default featureFlagsSlice.reducer;
