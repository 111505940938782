export const escapeRegExp = (value) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

const buildComparator = (a, b, orderBy, sortOrder) => {
  if (orderBy) {
    if (
      (a[orderBy] === undefined || !a[orderBy]) &&
      b[orderBy] &&
      sortOrder === "asc"
    )
      return -1;
    if (
      (b[orderBy] === undefined || !b[orderBy]) &&
      a[orderBy] &&
      sortOrder === "asc"
    )
      return 1;
    if (!a[orderBy]) return 1;
    if (!b[orderBy]) return -1;
    return (
      a[orderBy].toString().localeCompare(b[orderBy].toString(), "en", {
        numeric: true,
      }) * (sortOrder === "asc" ? 1 : -1)
    );
  }
};

export const getComparator = (order, orderBy) => {
  return (a, b) => buildComparator(a, b, orderBy, order);
};
