import { styled } from "@mui/material/styles";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import { IconButton } from "@mui/material";

/**
 * Preferred component styling by @mui/materials
 */
export const StyledFiltersButton = styled(
  ({ ...props }) => (
    <IconButton {...props} slots={{ root: "span" }}>
      <TuneRoundedIcon fontSize="large"/>
    </IconButton>
  ),
  {
    shouldForwardProp: (prop) => prop !== "isFiltered",
  }
)(({ theme, isFiltered }) => ({
  marginTop: 1,
  marginRight: 0,
  marginLeft: "-5px",
  fontSize: 32,
  flex: 1,
  cursor: "pointer",
  color: !!isFiltered
    ? theme.palette.darkGray.main
    : theme.palette.redAlert.main,
  width: "25px",
}));
