import { createSelector } from "@reduxjs/toolkit";

const getFeatureFlagsState = (state) => state.featureFlags;

export const selectFeatureFlags = createSelector(
  [getFeatureFlagsState],
  (featureFlagsState) => featureFlagsState.featureFlags
);

export const getFeatureFlagsStatusSelector = createSelector(
  [getFeatureFlagsState],
  (featureFlags) => featureFlags?.status
);

export const getTicketOrCaseFeatureFlagEnabledSelector = createSelector(
  [getFeatureFlagsState],
  (featureFlags) => featureFlags?.ticketOrCaseFeatureFlagEnabled
);
