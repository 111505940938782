import { useState } from "react";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import { Switch } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  createOrUpdateFeatureFlags,
  getTicketOrCaseFeatureFlagEnabled,
} from "../../../../../services/featureFlags";
import { getLastUpdated } from "../../../utils";
import styles from "../../styles";
import { TICKET_OR_CASE_NUMBER } from "../../../../../constants";
import useCss from "../../../../../hooks/useCss";
import { useSelector } from "react-redux";
import { getTimezoneSelector } from "../../../../../selectors/settingsSelector";

const EditFeatureFlagsRow = ({ row }) => {
  const [switchToggle, setSwitchToggle] = useState(row["enabled"]);
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const timezone = useSelector(getTimezoneSelector);

  const handleSwitchChange = async (event) => {
    const value = event.target.checked;
    setSwitchToggle(value);
    const updatedRow = {
      ...row,
      enabled: value,
    };
    await dispatch(createOrUpdateFeatureFlags(updatedRow));
    if (row["name"] === TICKET_OR_CASE_NUMBER) {
      await dispatch(getTicketOrCaseFeatureFlagEnabled(event.target.checked));
    }
  };

  return (
    <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <SbuxStyledTableCell className={classes.cellFocus}>
        {row["name"]}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell className={classes.cellFocus}>
        {row["label"]}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell className={classes.cellFocus}>
        <Switch
          size="small"
          checked={switchToggle}
          onChange={handleSwitchChange}
        />
      </SbuxStyledTableCell>
      <SbuxStyledTableCell>{getLastUpdated(row, timezone)}</SbuxStyledTableCell>
    </SbuxStyledTableRow>
  );
};

export default EditFeatureFlagsRow;
