export const typography = {
  fontFamily: [
    '"SoDoSans"',
    '"Segoe UI"',
    '"Roboto"',
    '"Oxygen"',
    '"Ubuntu"',
    '"Cantarell"',
    '"Fira Sans"',
    '"Droid Sans"',
    '"Helvetica Neue"',
    "sans-serif",
  ].join(","),
  fontSize: 16,
  button: {
    fontSize: '13px',
    fontFamily: ['"Roboto"', '"Heverica"', '"Arial"', "sans-serif"].join(',')
  }
};
