import { createSelector } from "@reduxjs/toolkit";

// Root selector
const getGenesysRecordingsState = (state) => state.genesysRecordings;

// GenesysRecordings Selectors
export const getQMPendingSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.isQMPending
);

export const getQMErrorSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.isQMError
);

export const getGenesysRecordingsDataSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.genesysRecordingsData
);

export const getPageKeyTrailSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.pageKeyTrail
);

export const getVideoMediaContentSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.videoMediaContent
);

export const getAudioMediaContentSelector = createSelector(
  [getGenesysRecordingsState],
  (genesysRecordings) => genesysRecordings?.audioMediaContent
);
