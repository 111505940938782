import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import RowSource from "./RowSource";
import { columns } from "./columns";
import {
  retrieveApiData,
  deleteApiData,
} from "../../../../../services/connect";
import {
  getConnectDatasetsSelector,
  getConnectStatusSelector,
} from "../../../../../selectors/connectSelector";
import { getInstanceIdSelector } from "../../../../../selectors/userInstanceSelector";

const ConnectTable = ({ apiName }) => {
  const dispatch = useDispatch();

  const instanceId = useSelector(getInstanceIdSelector);
  const datasets = useSelector(getConnectDatasetsSelector);
  const status = useSelector(getConnectStatusSelector);

  const dataset = datasets[apiName] || [];
  const [data, setData] = useState([]);

  const dataRequest = async () => {
    const payload = {
      apiName,
      instanceId: instanceId,
    };

    await dispatch(retrieveApiData(payload));
  };

  const handleClickRefreshEvent = async () => {
    if (!status.endsWith("pending")) {
      await dataRequest();
    }
  };

  const handleClickDeleteRowEvent = async (row) => {
    const payload = {
      apiName,
      instanceId: instanceId,
      id: row["Id"],
    };

    await dispatch(deleteApiData(payload));
  };

  useEffect(() => {
    if (Array.isArray(dataset) && dataset.length > 0) {
      setData(dataset);
    }
  }, [dataset]);

  useEffect(() => {
    dataRequest();
  }, [instanceId]);

  return (
    <SbuxEnhancedTable
      rows={data}
      columns={columns}
      defaultSortingField={"Name"}
      defaultSortOrder={"asc"}
      RowSource={RowSource}
      showFooter={true}
      showSearchBar={true}
      showRefreshButton={true}
      isManager={true}
      handleRefreshClick={handleClickRefreshEvent}
      handleDeleteRowClick={handleClickDeleteRowEvent}
    />
  );
};

export default ConnectTable;
