import { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import AutoCompleteTextField from "./AutoCompleteTextField";
import { getDays } from "../../utils/timeUtil";
import moment from "moment";

const SbuxAutocomplete = ({
  type,
  value,
  options = null,
  compareTo,
  compareToValue,
  handleDayChange = null,
  handleTimeChange = null,
  isOpen24Hours = false,
}) => {
  const filterOption = options.find((f) => f.value === value);
  const defaultSelectedValue =
    type === "time" && !filterOption ? value : filterOption;
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);
  const [inputValue, setInputValue] = useState("");

  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleHighlightChange = (event, option, reason) => {
    if (option && reason === "keyboard") {
      setSelectedValue(option);
    }
  };
  const handleOnChange = (event, newValue) => {
    if (type === "time") {
      setInputValue(newValue.value);
      handleTimeChange(newValue.value);
    } else {
      setSelectedValue(newValue);
      handleDayChange(newValue);
    }
  };

  const validateInput = (value) => {
    let isValidInput = false;
    if (typeof value === "string" && value.length > 0) {
      const days = getDays();
      const lookUpDays = days.find((f) => f.label === value);
      isValidInput = !!lookUpDays?.label ? true : false;
    }
    isValidInput
      ? setErrorMessage("")
      : setErrorMessage("Please enter or select a valid day");
    isValidInput ? setError(false) : setError(true);
  };
  const handleOnInputChange = (event, newValue) => {
    if (type === "time") {
      const compareTime = moment(compareToValue, "hh:mm A", true);
      const newTime = moment(newValue, "hh:mm A", true);
      if (newTime.isValid()) {
        let isNextDay =
          (compareTo === "startTime" && newValue === "12:00 AM") ||
          (compareTo === "endTime" &&
            compareTime.format("hh:mm A") === "12:00 AM")
            ? true
            : false;
        const isDiff =
          compareTo === "endTime"
            ? compareTime.isSameOrAfter(newTime)
            : compareTo === "startTime" && compareTime.isSameOrBefore(newTime);

        isDiff || isNextDay
          ? setErrorMessage("")
          : setErrorMessage("Please enter a valid time");

        isDiff || isNextDay ? setError(false) : setError(true);
      } else {
        setError(true);
        setErrorMessage("Please enter a valid time");
      }
      setInputValue(newValue);
      handleTimeChange(newValue);
    } else {
      setInputValue(newValue);
      validateInput(newValue);
    }
  };
  return (
    <Autocomplete
      size="small"
      freeSolo
      disableClearable
      sx={{
        fontSize: 14,
        marginTop: -0.5,
      }}
      disabled={isOpen24Hours}
      defaultValue={selectedValue}
      options={options}
      inputValue={inputValue}
      value={selectedValue}
      onHighlightChange={handleHighlightChange}
      onChange={handleOnChange}
      onInputChange={handleOnInputChange}
      renderInput={(params) => (
        <AutoCompleteTextField
          params={params}
          isError={error}
          errorMessage={errorMessage}
        />
      )}
      ListboxProps={{
        style: {
          maxHeight: 150,
          fontSize: 12,
        },
      }}
    />
  );
};

export default SbuxAutocomplete;
