export const quickConnectsColumns = [
  {
    label: "Name",
    id: "name",
    flex: 1,
    minWidth: 480,
    sortable:true,
  },
  {
    label: "Phone No.",
    id: "phoneNumber",
    flex: 1,
    minWidth: 480,
    sortable:true,
  },
];

export const quickConnectsFilterColumns=[
  {
    id:0,
    key:"All",
    value:"all"
  },
  {
    id:1,
    key:"External",
    value:"phone_number"
  },
  {
    id:2,
    key:"Agent",
    value:"agent"
  },
  {
    id:3,
    key:"Queue",
    value:"queue"
  },
]
