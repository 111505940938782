import { useEffect, useState } from "react";
import SbuxEnhancedTable from "../../../../components/SbuxTable/SbuxEnhancedTable";
import MetricsRow from "./MetricsRow";
import MyMetricsRow from "./MyMetricsRow";
import SbuxSubTabs from "../../../../components/SbuxTabs/SbuxSubTabs";
import { Paper } from "@mui/material";
import {
  metricsColumns,
  metricsGroupColumns,
  myMetricsColumns,
} from "./columns";
import {
  getMetricsQueue,
  getMyMetricsData,
} from "../../../../services/metrics";
import { useDispatch, useSelector } from "react-redux";
import { getCcpQueuesSelector } from "../../../../selectors/ccpSelector";
import {
  getInstanceIdSelector,
  getArnSelector,
} from "../../../../selectors/userInstanceSelector";
import {
  getQueueMetricsSelector,
  getMyMetricsDataSelector,
} from "../../../../selectors/metricsSelector";

const MetricsTable = ({ subTabs }) => {
  const dispatch = useDispatch();

  const queues = useSelector(getCcpQueuesSelector);
  const instanceId = useSelector(getInstanceIdSelector);
  const arn = useSelector(getArnSelector);
  const queueMetrics = useSelector(getQueueMetricsSelector);
  const metrics = useSelector(getMyMetricsDataSelector);

  const [metricId, setMetricId] = useState("queueMetrics");
  const [metricsData, setMetricsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (metrics && metrics.length > 0) {
      setMetricsData(metrics);
      handleSubTabChange(metricId);
    }
  }, [metrics]);

  useEffect(() => {
    loadData();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      loadData();
    }, 5000);
    return () => clearInterval(interval);
  }, [queues]);

  const loadData = async () => {
    const metricsPayload = {
      instanceId,
      queues,
    };
    await dispatch(getMetricsQueue(metricsPayload));
  };

  const loadMyMetricsData = async () => {
    const myMetricsPayload = {
      instanceArn: arn,
    };
    await dispatch(getMyMetricsData(myMetricsPayload));
  };

  const handleRefrehClick = async () => {
    if (metricId === "queueMetrics") await loadData();
    else await loadMyMetricsData();
  };

  const handleSubTabChange = (tab) => {
    if (tab && tab.value) {
      setMetricId(tab.value);
    }
  };

  const SubTabsComponent = () => {
    return (
      <SbuxSubTabs
        tabList={subTabs}
        tabId={metricId}
        handleTabClick={handleSubTabChange}
      />
    );
  };

  return (
    <Paper>
      <SbuxEnhancedTable
        rows={metricId === "queueMetrics" ? queueMetrics : metricsData}
        columns={
          metricId === "queueMetrics" ? metricsColumns : myMetricsColumns
        }
        groupColumns={metricId === "queueMetrics" ? metricsGroupColumns : ""}
        defaultSortingField={"contactsInQueue"}
        defaultSortOrder={"desc"}
        RowSource={metricId === "queueMetrics" ? MetricsRow : MyMetricsRow}
        showSearchBar={true}
        showSubTabs={true}
        SubTabs={SubTabsComponent}
        showRefreshButton={metricId === "queueMetrics" ? false : true}
        handleRefreshClick={handleRefrehClick}
        showFooter={true}
        isLoading={isLoading}
      />
    </Paper>
  );
};

export default MetricsTable;
