import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getClosureMsgData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/closureMessages/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `closureMsg`,
      message: `Something went wrong in getClosureMsgData API => ${error}`,
    });
    logMessage(
      `closureMsg`,
      `Something went wrong in getClosureMsgData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getClosureMsgData api endpoint");
  }
};

export const getClosureMsg = createAsyncThunk(
  "closureMsg/getClosureMsg",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getClosureMsgData(instanceId, rejectWithValue);
  }
);

export const getClosureMsgMediaFile = createAsyncThunk(
  "clsoureMsg/getClosureMsgMediaFile",
  async (mediaMgtData, { rejectWithValue }) => {
    const { instanceId, languageCode, language, fileName, type } = mediaMgtData;
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/mediaFile/${instanceId}/${languageCode}-${language}/${fileName}/${type}`,
        method: "GET",
      });
      const result = {
        ...mediaMgtData,
        mediaContent: response.data.mediaContent,
      };
      return result;
    } catch (error) {
      clientLog({
        component: `clsoureMsg`,
        message: `Something went wrong in getClosureMsgMediaFile API => ${error}`,
      });
      logMessage(
        `clsoureMsg`,
        `Something went wrong in getClosureMsgMediaFile API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getClosureMsgMediaFile api endpoint");
    }
  }
);

export const createOrUpdateClosureMsg = createAsyncThunk(
  "closureMsg/createOrUpdateClosureMsg",
  async (closureMsgData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/closureMessages/${instanceId}`,
        method: "POST",
        body: { ...closureMsgData },
      });
      return await getClosureMsgData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `closureMsg`,
        message: `Something went wrong in createOrUpdateClosureMsg API => ${error}`,
      });
      logMessage(
        `closureMsg`,
        `Something went wrong in createOrUpdateClosureMsg API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in createOrUpdateClosureMsg api endpoint");
    }
  }
);

export const deleteClosureMsg = createAsyncThunk(
  "closureMsg/deleteClosureMsg",
  async (clsoureMsgData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/closureMessages/${instanceId}`,
        method: "DELETE",
        body: { ...clsoureMsgData },
      });
      return await getClosureMsgData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `closureMsg`,
        message: `Something went wrong in deleteClosureMsg API => ${error}`,
      });
      logMessage(
        `closureMsg`,
        `Something went wrong in deleteClosureMsg API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in deleteClosureMsg api endpoint");
    }
  }
);

export const updateRepeatOccureneceClosureMsg = createAsyncThunk(
  "closureMsg/updateRepeatOccureneceClosureMsg",
  async (clsoureMsgData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/closureMessages/${instanceId}`,
        method: "POST",
        body: clsoureMsgData,
      });
      return await getClosureMsgData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `closureMsg`,
        message: `Something went wrong in updateRepeatOccureneceClosureMsg API => ${error}`,
      });
      logMessage(
        `closureMsg`,
        `Something went wrong in updateRepeatOccureneceClosureMsg API => ${error}`,
        `error`
      );
      return rejectWithValue(
        "Error in updateRepeatOccureneceClosureMsg api endpoint"
      );
    }
  }
);

export const getResetClosureMsg = createAction("closureMsg/getResetClosureMsg");
