import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";

export const clientLog = async (logging) => {
  try {
    await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: "/clientLog",
      method: "POST",
      body: { ...logging },
    });
  } catch (err) {
    logMessage(
      `clientLog`,
      `Something went wrong in clientLog API : ${err}`,
      `error`
    );
  }
};
