import SbuxTranslate from "../SbuxTranslate";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { Box, Button, Typography } from "@mui/material";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";

const SbuxFallback = ({
  error = null,
  resetErrorBoundary = null,
  isServicePortalMessage = false,
}) => {
  const classes = useCss(styles);

  const servicePortalLinkRenderer = () => {
    return (
      <div>
        <div>
          <span>
            <SbuxTranslate>{`You may not be provisioned in Amazon Connect, or an error occurred.  To gain access, please speak with your manager or supervisor.`}</SbuxTranslate>
            <SbuxTranslate>{` If you believe there is a system error, please open a ticket with the Service Desk at 888-728-9411, option 2.`}</SbuxTranslate>
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.img}>
        <SbuxLogo className={classes.sbuxLogo} />
      </div>

      <Typography variant="h5" className={classes.headingPrimary}>
        <SbuxTranslate>{`Oops, an error occurred.`}</SbuxTranslate>
      </Typography>
      <Typography variant="h5" className={classes.headingSecondary}>
        {servicePortalLinkRenderer()}
      </Typography>
      <Typography variant="h5" className={classes.headingSecondary}>
        <Box>
          <Button
            onClick={resetErrorBoundary}
            variant="contained"
            size="medium"
          >
            <SbuxTranslate>{`Refresh`}</SbuxTranslate>
          </Button>
        </Box>
      </Typography>
    </div>
  );
};

export default SbuxFallback;
