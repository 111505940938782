const styles = (theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 30,
    gap: 10,
    "& > button": {
      width: 'auto',
    }
  },
  statusSpan: {
    textTransform: "none",
    marginLeft: "4px",
  },
  saveButton: {
    marginLeft: 6,
    fontSize: 13,
    height: 24,
    width: 80,
    paddingTop: 0,
    paddingBottom: 1,
    outline: "0 !important",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.neutral,
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: theme.palette.white.neutral,
    },
  },
  cancelButton: {
    marginLeft: 6,
    fontSize: 13,
    height: 24,
    width: 80,
    paddingTop: 0,
    paddingBottom: 1,
    outline: "0 !important",
  },
});

export default styles;
