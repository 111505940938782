import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getAuthState = (state) => state.auth;

// Auth Selectors
export const getCognitoUserIdSelector = createSelector(
  [getAuthState],
  (auth) => auth?.cognitoUserId
);

export const getIsResetTimersSelector = createSelector(
  [getAuthState],
  (auth) => auth?.isResetTimers || false
);

export const getStatusSelector = createSelector(
  [getAuthState],
  (auth) => auth?.status || false
);
