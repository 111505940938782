const styles = (theme) => ({
  root: {
    paddingTop: 0,
  },
  input: {
    padding: 0,
    "& .MuiInput-input": {
      paddingLeft: "4px",
    }
  },
  error: {
    color: theme.palette.redAlert.main,
    paddingLeft: 5,
  },
  inputContainer: {
    display: "contents",
  },
  inputError: {
    "& .MuiInput-input": {
      paddingLeft: "4px",
      borderRadius: "4px",
      border: `2px solid ${theme.palette.redAlert.main} !important`,
      height: 16
    },
  },
});

export default styles;
