import { styled } from "@mui/material/styles";

export const StyledGridOverlay = styled("div")((/*{ theme }*/) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "20%",
  padding: "2%",
}));
