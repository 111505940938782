import { createSelector } from "@reduxjs/toolkit";

const getMainHoursState = (state) => state.mainHours;

export const getMainHoursDataSelector = createSelector(
  [getMainHoursState],
  (mainHours) => mainHours?.mainHoursData
);

export const getMainHoursIsSaveSelector = createSelector(
  [getMainHoursState],
  (mainHours) => mainHours?.isSave
);

export const getMainHoursIsErrorSelector = createSelector(
  [getMainHoursState],
  (mainHours) => mainHours?.isError
);
