import { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  SNACK_BARS_SEVERITY_SUCCESS,
  COPIED_TO_CLIPBOARD,
} from "../../constants";
import SbuxSnackbars from "../SbuxSnackbars";

const SbuxContentCopy = ({ content, disabled = false, handleClick }) => {
  const [open, setOpen] = useState(false);

  if (!handleClick) {
    handleClick = async () => {
      await navigator.clipboard.writeText(content);
      setOpen(true);
    };
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        color="primary"
        sx={{
          "&:focus": {
            outline: 0,
          },
          "&.MuiIconButton-root": {
            padding: "0",
            paddingLeft: "5px",
          },
        }}
      >
        <ContentCopyIcon />
      </IconButton>

      <SbuxSnackbars
        open={open}
        message={COPIED_TO_CLIPBOARD}
        severity={SNACK_BARS_SEVERITY_SUCCESS}
        handleClose={handleClose}
      />
    </>
  );
};

export default SbuxContentCopy;
