import { useState } from "react";
import SbuxDialog2 from "../../../../components/SbuxDialog2";
import SbuxTranslate from "../../../../components/SbuxTranslate";
import {
  Box,
  Button,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
} from "@mui/material";
import DatePicker from "react-datepicker";
import moment from "moment";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";

const ClosureMsgCustomFilter = ({
  title,
  open,
  handleCustomFilterOkClick,
  toggleOpen,
}) => {
  const classes = useCss(styles);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  const handleFromDateChange = (value) => {
    setFromDate(value);
    setToDate(value);
  };
  const handleToDateChange = (value) => {
    setToDate(value);
  };

  const handleCustomOkClick = () => {
    handleCustomFilterOkClick(fromDate, toDate);
    toggleOpen();
  };

  const dialogConfig = {
    title,
    hasBackdrop: false,
    asModal: true,
    disableBackdropClose: false,
  };

  return (
    <SbuxDialog2 open={open} config={dialogConfig} handleOnClose={toggleOpen}>
      <DialogContent dividers>
        <Box component="form" className={classes.customFilterForm}>
          <Box>
            <FormControl>
              <InputLabel>From</InputLabel>
              <DatePicker
                className={classes.datePicker}
                selected={fromDate}
                minDate={moment().subtract(90, "days").toDate()}
                onChange={(date) => handleFromDateChange(date)}
                timeFormat="YYYY-MM-DD"
                popperProps={{strategy: 'fixed'}}
              />
            </FormControl>
          </Box>

          <Box>
            <FormControl>
              <InputLabel>To</InputLabel>
              <DatePicker
                className={classes.datePicker}
                selected={toDate}
                minDate={moment(fromDate).add(1, "days").toDate()}
                maxDate={moment(fromDate).add(31, "days").toDate()}
                onChange={(date) => handleToDateChange(date)}
                timeFormat="YYYY-MM-DD"
                popperProps={{strategy: 'fixed'}}
              />
            </FormControl>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box className="actionContainer">
          <Button
            variant="contained"
            size="medium"
            onClick={handleCustomOkClick}
          >
            <SbuxTranslate>{`Okay`}</SbuxTranslate>
          </Button>
          <Button variant="outlined" size="medium" onClick={toggleOpen}>
            <SbuxTranslate>{`Cancel`}</SbuxTranslate>
          </Button>
        </Box>
      </DialogActions>
    </SbuxDialog2>
  );
};

export default ClosureMsgCustomFilter;
