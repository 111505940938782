import { useEffect, useState } from "react";
import SbuxTabs from "../../components/SbuxTabs";
import QuickConnects from "./quickConnects";
import History from "./history";
import Metrics from "./metrics";
import Resources from "./resources";
import Current from "./current";
import Manager from "./manager";
import Admin from "./admin";
import { Paper } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getActiveTabId } from "../../services/tabs";
import defaultSettingsConfig from "../../components/SbuxSettings/config/default.json";
import {
  getAdminSelector,
  getManagerOrSupervisorSelector,
  getReOrderedTablistSelector,
} from "../../selectors/settingsSelector";
import { getActiveTabIdSelector } from "../../selectors/tabsSelector";

const ContentTabs = () => {
  const dispatch = useDispatch();
  const admin = useSelector(getAdminSelector);
  const managerOrSupervisor = useSelector(getManagerOrSupervisorSelector);
  const reOrderedTablist = useSelector(getReOrderedTablistSelector);
  const activeTabId = useSelector(getActiveTabIdSelector);

  const [tabList, setTabList] = useState([]);

  useEffect(() => {
    reOrderedTablist.length === 0
      ? setTabList(defaultSettingsConfig.config.tabOrder)
      : setTabList(reOrderedTablist);
  }, [reOrderedTablist]);

  const handleTabChange = (event, newValue) => {
    dispatch(getActiveTabId(newValue));
  };
  return (
    <Paper sx={{ height: "79vh", width: "100%", position: "relative" }}>
      <SbuxTabs
        handleTabChange={handleTabChange}
        tabList={tabList}
        tabPanelList={[
          {
            tabPanelId: "tab1",
            component: <Current />,
            visible: true,
          },

          {
            tabPanelId: "tab2",
            component: activeTabId === "tab2" && <QuickConnects />,
            visible: true,
          },
          {
            tabPanelId: "tab3",
            component: activeTabId === "tab3" && <Metrics />,
            visible: true,
          },
          {
            tabPanelId: "tab4",
            component: activeTabId === "tab4" && <History />,
            visible: true,
          },
          {
            tabPanelId: "tab5",
            component: activeTabId === "tab5" && <Resources />,
            visible: true,
          },
          {
            tabPanelId: "tab6",
            component: activeTabId === "tab6" && <Manager />,
            visible: admin ? true : managerOrSupervisor,
          },
          {
            tabPanelId: "tab7",
            component: activeTabId === "tab7" && <Admin />,
            visible: admin,
          },
        ]}
      />
    </Paper>
  );
};

export default ContentTabs;
