import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getGenesysRecordingsData = async (bodyArgs, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/genesys`,
      method: "POST",
      body: bodyArgs,
    });
    return response;
  } catch (error) {
    clientLog({
      component: `genesysRecordings`,
      message: `Something went wrong in getGenesysRecordingsData API => ${error}`,
    });
    logMessage(
      `genesysRecordings`,
      `Something went wrong in getGenesysRecordingsData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getGenesysRecordingsData api endpoint");
  }
};

export const getGenesysRecordings = createAsyncThunk(
  "genesysRecordings/getGenesysRecordings",
  async (bodyArgs, { getState, rejectWithValue }) => {
    return await getGenesysRecordingsData(bodyArgs, rejectWithValue);
  }
);

export const getGenesysRecordingsVideo = createAsyncThunk(
  "genesysRecordings/getGenesysRecordingsVideo",
  async (bodyArgs, { getState, rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/genesys`,
        method: "POST",
        body: { url: bodyArgs },
      });
      return response;
    } catch (error) {
      clientLog({
        component: `genesysRecordings`,
        message: `Something went wrong in getGenesysRecordingsVideo API => ${error}`,
      });
      logMessage(
        `genesysRecordings API`,
        `Something went wrong in getGenesysRecordingsVideo API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getGenesysRecordingsVideo api endpoint");
    }
  }
);

export const getGenesysRecordingsAudio = createAsyncThunk(
  "genesysRecordings/getGenesysRecordingsAudio",
  async (bodyArgs, { getState, rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/genesys`,
        method: "POST",
        body: { url: bodyArgs },
      });
      return response;
    } catch (error) {
      clientLog({
        component: `genesysRecordings`,
        message: `Something went wrong in getGenesysRecordingsAudio API => ${error}`,
      });
      logMessage(
        `genesysRecordings API`,
        `Something went wrong in getGenesysRecordingsAudio API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getGenesysRecordingsAudio api endpoint");
    }
  }
);

export const getResetGenesysRecordings = createAction(
  "genesysRecordings/getResetGenesysRecordings"
);
