import IconButton from "@mui/material/IconButton";
import Icon from "@cxeweb/pattern-library/lib/components/icon";

const SbuxContentButton = ({
  iconName,
  disabled = false,
  handleClick,
  color,
  iconSize,
}) => {
  return (
    <>
      <IconButton
        disabled={disabled}
        onClick={handleClick}
        color={color}
        sx={{
          "&:focus": {
            outline: 0,
          },
          "&.MuiIconButton-root": {
            padding: "0",
            paddingLeft: "5px",
          },
        }}
      >
        <Icon path={iconName} size={iconSize} />
      </IconButton>
    </>
  );
};

export default SbuxContentButton;
