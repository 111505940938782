import { createSlice } from "@reduxjs/toolkit";
import {
  getTableListData,
  getTableMetaData,
  createOrUpdateListData,
} from "../services/dataMgt";

const initialState = {
  tableListStatus: "idle",
  tableListLoading: false,
  metaConfigData: [],
  tableListData: [],
  isDMPending: false,
  isDMSave: false,
  isDMDelete: false,
  isDMError: false,
};

const dataMgtSlice = createSlice({
  name: "dataMgt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTableListData.pending, (state) => {
        state.tableListStatus = "pending";
        state.tableListLoading = true;
      })
      .addCase(getTableListData.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableListStatus = "success";
        state.tableListLoading = false;
        state.tableListData = payload.tableListData?.data?.data;
      })
      .addCase(getTableListData.rejected, (state) => {
        state.tableListStatus = "rejected";
        state.tableListLoading = false;
      })
      .addCase(createOrUpdateListData.pending, (state) => {
        state.isDMPending = true;
        state.isDMSave = false;
        state.isDMError = false;
        state.isDMDelete = false;
      })
      .addCase(createOrUpdateListData.fulfilled, (state, action) => {
        const { payload } = action;

        state.isDMPending = false;
        state.isDMError = false;
        if (payload === "delete") {
          state.isDMDelete = true;
        } else {
          state.isDMSave = true;
        }
      })
      .addCase(createOrUpdateListData.rejected, (state) => {
        state.isDMPending = false;
        state.isDMError = true;
        state.isDMSave = false;
        state.isDMDelete = false;
      })

      .addCase(getTableMetaData.pending, (state) => {
        state.tableListStatus = "pending";
        state.tableListLoading = true;
        state.metaConfigData = [];
      })
      .addCase(getTableMetaData.fulfilled, (state, action) => {
        const { payload } = action;
        state.tableListStatus = "success";
        state.tableListLoading = false;
        state.metaConfigData = payload.headerData?.data;
      })
      .addCase(getTableMetaData.rejected, (state) => {
        state.tableListStatus = "rejected";
        state.tableListLoading = false;
        state.metaConfigData = [];
      });
  },
});

export default dataMgtSlice.reducer;
