import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getSettingsState = (state) => state.settings;

// User Selectors
export const getAdminSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.user?.admin
);

export const getManagerOrSupervisorSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.user?.managerOrSupervisor
);

export const getManagerNavSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.user?.accessLevels?.managerNav
);

// New Selector for user.config
export const getUserConfigSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.user?.config
);

export const getReOrderedTablistSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.reOrderedTablist
);

// Locale and Timezone Selectors
export const getLocaleSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.locale
);

export const getTimezoneSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.timezone
);

// New Selector for selectedTimezone
export const getSelectedTimezoneSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.selectedTimezone
);

// CCP Locale and Translation Selectors
export const getCcpLocaleStatusSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.ccpLocaleStatus
);

export const getTranslatedStatusSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.translatedStatus
);

export const getTranslatedTextSelector = createSelector(
  [getSettingsState],
  (settings) => settings?.translatedText
);
