import { createSlice } from "@reduxjs/toolkit";
import { createOrUpdateIvrTable, setStatusUpdate } from "../services/ivrTable";

const initialState = {
  status: "idle",
  data: {},
  statusCode: null,
  message: null,
};

const ivrTableSlice = createSlice({
  name: "ivrTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createOrUpdateIvrTable.pending, (state) => {
        state.status = "pending";
        state.statusCode = null;
        state.message = null;
      })
      .addCase(createOrUpdateIvrTable.fulfilled, (state, action) => {
        const {
          payload: {
            data: { message },
          },
        } = action;
        state.status = "success";
        state.statusCode = message.includes("Success") ? 200 : 400;
        const msg = message.replaceAll("ResourceInUseException:", "");
        state.message = msg;
      })
      .addCase(createOrUpdateIvrTable.rejected, (state) => {
        state.status = "rejected";
        state.data = {};
        state.statusCode = null;
        state.message = null;
      })
      .addCase(setStatusUpdate, (state) => {
        state.status = "idle";
        state.data = {};
        state.statusCode = null;
        state.message = null;
      });
  },
});

export default ivrTableSlice.reducer;
