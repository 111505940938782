import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import instanceReducer from "../slices/instanceSlice";
import ccpReducer from "../slices/ccpSlice";
import featureFlagsReducer from "../slices/featureFlagsSlice";
import historyReducer from "../slices/historySlice";
import currentReducer from "../slices/currentSlice";
import metricsReducer from "../slices/metricsSlice";
import resourcesReducer from "../slices/resourcesSlice";
import userSettingsReducer from "../slices/settingsSlice";
import ivrTableReducer from "../slices/ivrTableSlice";
import filterReducer from "../slices/filterSlice";
import tabsReducer from "../slices/tabsSlice";
import mainHoursReducer from "../slices/mainHoursSlice";
import mediaMgtReducer from "../slices/mediaMgtSlice";
import closureMsgReducer from "../slices/closureMsgSlice";
import temporaryMsgReducer from "../slices/temporaryMsgSlice";
import outboundCallReducer from "../slices/outboundCallSlice";
import globalConfigReducer from "../slices/globalConfigSlice";
import { screenRecordingReducer } from "../slices/screenRecordingSlice";
import genesysRecordingsReducer from "../slices/genesysRecordingsSlice";
import queueReducer from "../slices/queueSlice";
import dataMgtSlice from "../slices/dataMgtSlice";
import connectReducer from "../slices/connect";
import textToSpeechSlice from "../slices/textToSpeechSlice";
import stopContactSlice from "../slices/stopContactSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  userInstance: instanceReducer,
  ccp: ccpReducer,
  featureFlags: featureFlagsReducer,
  history: historyReducer,
  current: currentReducer,
  metrics: metricsReducer,
  resources: resourcesReducer,
  filter: filterReducer,
  tabs: tabsReducer,
  settings: userSettingsReducer,
  ivrTable: ivrTableReducer,
  mainHours: mainHoursReducer,
  mediaMgt: mediaMgtReducer,
  closureMsg: closureMsgReducer,
  temporaryMsg: temporaryMsgReducer,
  globalConfig: globalConfigReducer,
  screenRecording: screenRecordingReducer,
  outboundCall: outboundCallReducer,
  genesysRecordings: genesysRecordingsReducer,
  queue: queueReducer,
  connect: connectReducer,
  textToSpeech: textToSpeechSlice,
  dataMgt: dataMgtSlice,
  stopContact: stopContactSlice,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
