import SbuxCard from "../../../../../components/SbuxCard";
import { useEffect } from "react";
import GenesysRecordingsDataGrid from "./components/GenesysRecordingsDataGrid";
import { useSelector, useDispatch } from "react-redux";
import {
  getGenesysRecordingsAudio,
  getGenesysRecordingsVideo,
  getGenesysRecordings,
} from "../../../../../services/genesysRecordings";
import { getDataGridColumns } from "./columns";
import { filterList } from "./filters";
import { getGenesysConfig } from "../../../../../selectors/userInstanceSelector";
import {
  getVideoMediaContentSelector,
  getAudioMediaContentSelector,
} from "../../../../../selectors/genesysRecordingsSelector";

const GenesysRecordingsTabs = () => {
  const dispatch = useDispatch();
  const videoMediaContent = useSelector(getVideoMediaContentSelector);
  const audioMediaContent = useSelector(getAudioMediaContentSelector);
  const genesysConfig = useSelector(getGenesysConfig);

  useEffect(() => {
    if (!!genesysConfig?.callType)
      dispatch(
        getGenesysRecordings({
          limit: 25,
          callType: genesysConfig?.callType,
        })
      );
  }, []);

  useEffect(() => {
    if (videoMediaContent) {
      window.open(videoMediaContent.mediaContent, "_blank");
    }
  }, [videoMediaContent]);

  useEffect(() => {
    if (audioMediaContent) {
      window.open(audioMediaContent.mediaContent, "_blank");
    }
  }, [audioMediaContent]);

  const handleAudioDowload = async (value) => {
    await dispatch(getGenesysRecordingsAudio(value));
  };

  const handleVideoDowload = async (value) => {
    await dispatch(getGenesysRecordingsVideo(value));
  };

  return (
    <SbuxCard className="insideTabContainer">
      <GenesysRecordingsDataGrid
        columns={getDataGridColumns(handleAudioDowload, handleVideoDowload)}
        filterList={filterList}
      />
    </SbuxCard>
  );
};

export default GenesysRecordingsTabs;
