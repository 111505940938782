import SbuxCard from "../../../components/SbuxCard";
import QuickConnectsTable from "./table/QuickConnectsTable";
import { useSelector } from "react-redux";
import SbuxFallback from "../../../components/SbuxFallback";
import { getQuickConnectsFilterItem } from "../../../services/filter";
import { QUICKCONNTECTS_DEFAULT_FILTER_ITEMS } from "../../../constants";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import {
  getCcpContactStatusSelector,
  getCcpEndpointsSelector,
} from "../../../selectors/ccpSelector";
import {
  getQuickConnectsFilterItemSelector,
  getQuickConnectsResetSelector,
} from "../../../selectors/filterSelector";

const QuickConnects = () => {
  const ccpContactStatus = useSelector(getCcpContactStatusSelector);
  const endpoints = useSelector(getCcpEndpointsSelector);
  const quickConnectsFilterItem = useSelector(
    getQuickConnectsFilterItemSelector
  );
  const quickConnectsReset = useSelector(getQuickConnectsResetSelector);
  const handleError = (error) => {
    clientLog({
      component: `QuickConnects`,
      message: `Something went wrong in QuickConnects Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in QuickConnects`,
      `Something went wrong in QuickConnects Component => ${error}`,
      `error`
    );
  };

  return (
    <ErrorBoundary FallbackComponent={SbuxFallback} onError={handleError}>
      <SbuxCard className="insideTabContainer">
        <QuickConnectsTable
          endpoints={endpoints}
          ccpContactStatus={ccpContactStatus}
          filteredItem={
            !quickConnectsReset && quickConnectsFilterItem.length === 0
              ? QUICKCONNTECTS_DEFAULT_FILTER_ITEMS
              : quickConnectsFilterItem
          }
          quickConnectsReset={quickConnectsReset}
          setFilteredItem={getQuickConnectsFilterItem}
        />
      </SbuxCard>
    </ErrorBoundary>
  );
};

export default QuickConnects;
