import { useState, useEffect } from "react";
import { Box, FormControlLabel, Checkbox } from "@mui/material";
import SbuxTextField from "../../../../../components/SubxTextField";
import MainHoursConfigTable from "../mainHours/MainHoursConfigTable";
import styles from "./styles";
import useCss from "../../../../../hooks/useCss";

const MainHoursAdd = ({ toggleDialogOpen, memoDataset }) => {
  const classes = useCss(styles);

  const initFormValues = {
    hoursName: "",
    description: "",
    hoursConfig: [],
    showForManagers: false,
  };

  const [formValue, setFormValue] = useState(initFormValues);
  const [hoursNameError, sethoursNameError] = useState(false);

  useEffect(() => {
    memoDataset(initFormValues);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = { ...formValue, [name]: value };

    setFormValue(data);
    name === "hoursName" && sethoursNameError(value.length < 1 ? true : false);

    memoDataset({ [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { checked } = e.target;

    const data = { ...formValue, showForManagers: checked };
    setFormValue(data);

    memoDataset({ showForManagers: checked });
  };

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      className={classes.formControl}
    >
      <Box>
        <SbuxTextField
          isError={hoursNameError}
          error={hoursNameError}
          label={"Hours Name"}
          handleChange={handleChange}
          value={formValue.hoursName}
          name={"hoursName"}
          required
          errorMessage={
            hoursNameError ? "Hours name required at least 1 character" : " "
          }
        />
      </Box>

      <Box>
        <SbuxTextField
          label={"Description"}
          handleChange={handleChange}
          value={formValue.description}
          name={"description"}
        />
      </Box>

      {/* Show for Managers Checkbox */}
      <Box>
        <FormControlLabel
          control={
            <Checkbox
              disableFocusRipple
              disableRipple
              checked={formValue.showForManagers}
              onChange={handleCheckboxChange}
              name="showForManagers"
              className={classes.checkbox}
            />
          }
          className={classes.checkboxLabel}
          label="Show for Managers"
        />
      </Box>

      {/* Hours Config Table */}
      <Box className="fullWidth">
        <MainHoursConfigTable
          rowData={{}}
          toggleDialogOpen={toggleDialogOpen}
          memoDataset={memoDataset}
        />
      </Box>
    </Box>
  );
};

export default MainHoursAdd;
