import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getQueueData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/connectApis/${instanceId}?apiName=queue`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `queue`,
      message: `Something went wrong in getQueueData API => ${error}`,
    });
    logMessage(
      `queue`,
      `Something went wrong in getQueueData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getQueueData api endpoint");
  }
};

export const getQueue = createAsyncThunk(
  "queue/getQueue",
  async (instanceId, { getState, rejectWithValue }) => {

    return await getQueueData(instanceId, rejectWithValue);
  }
);

export const deleteQueue = createAsyncThunk(
  "queue/deleteQueue",
  async (id, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/connectApis/${instanceId}/${id}?apiName=queue`,
        method: "DELETE",
      });
      return await getQueueData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `queue`,
        message: `Something went wrong in deleteQueue API => ${error}`,
      });
      logMessage(
        `queue`,
        `Something went wrong in deleteQueue API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in deleteQueue api endpoint", error);
    }
  }
);

export const getResetQueue = createAction("queue/getResetQueue");
