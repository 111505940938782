import { createSelector } from "@reduxjs/toolkit";

const getMediaMgtState = (state) => state.mediaMgt;

export const getMediaMgtDataSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.mediaMgtData
);

export const getMMPendingSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isMMPending
);

export const getMMSaveSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isMMSave
);

export const getMMDeleteSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isMMDelete
);

export const getMMErrorSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isMMError
);

export const getMediaMgtRowsSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.mediaMgtData?.data
);

export const getEditDataSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.editData
);

export const getIsPendingSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isPending
);

export const getIsErrorSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isError
);

export const getIsEditOpenSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.isEditOpen
);

export const getMediaExpandCollapseInfoSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.mediaExpandCollapseInfo
);

export const getMediaContentSelector = createSelector(
  [getMediaMgtState],
  (mediaMgt) => mediaMgt?.mediaContent
);
