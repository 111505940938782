import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";
import InputTableCell from "./InputTableCell";
import SbuxContentCopy from "../../../../components/SbuxContentCopy";
import {
  isPhoneNumber,
  phoneNumberDetail,
} from "../../../../utils/phoneNumber";
import { isTicketOrCaseEditable } from "../utils";

let callInfo = {};

const DetailRow = ({ row, linkRenderer }) => {
  return (
    <>
      {row.detailsRows &&
        row.detailsRows.map((contact, index) => {
          callInfo[contact.name] = contact.value;
          return (
            <SbuxStyledTableRow
              key={index}
              sx={{ "& > *": { borderBottom: "unset" } }}
            >
              <SbuxStyledTableCell>{contact.name}</SbuxStyledTableCell>
              <SbuxStyledTableCell>
                {contact.name === "Ticket or Case #" &&
                isTicketOrCaseEditable(callInfo) ? (
                  <InputTableCell
                    row={contact}
                    contactId={row.ContactID}
                    timeStamp={row.timestamp}
                  />
                ) : (
                  <span>
                    {isPhoneNumber(contact.value) ? (
                      <div>
                        {linkRenderer(contact.value)}
                        <SbuxContentCopy
                          content={
                            phoneNumberDetail(contact.value)?.phoneNumber ?? ""
                          }
                        />
                      </div>
                    ) : (
                      contact.value
                    )}
                  </span>
                )}
              </SbuxStyledTableCell>
            </SbuxStyledTableRow>
          );
        })}
    </>
  );
};

export default DetailRow;
