import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import { Box, FormControlLabel, Radio } from "@mui/material";

const TemporaryMsgMode = ({ row, mode, handleModeValueChange }) => {
  return (
    <SbuxRadioGroup
      value={mode}
      handleRadioButtonChange={handleModeValueChange}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}>
          <FormControlLabel
            disabled={
              (row && row.status === "Expired") ||
              row.status === "Disabled" ||
              mode === "durationMode"
            }
            value={"draftMode"}
            control={<Radio />}
            label={
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    pointerEvents: mode !== "draftMode" && "none",
                    opacity: mode !== "draftMode" && 0.6,
                  }}
                />
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.5,
                    flex: 1,
                    marginRight: 1,
                    minWidth: 100,
                  }}
                >
                  {"Draft mode"}
                </Box>
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <FormControlLabel
            value={"durationMode"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  fontSize: 13,
                  flex: 1,
                  pointerEvents: mode !== "durationMode" && "none",
                  opacity: mode !== "durationMode" && 0.6,
                }}
              >
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.5,
                    flex: 1,
                    marginRight: 1,
                    minWidth: 100,
                  }}
                >
                  {"Set Duration"}
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default TemporaryMsgMode;
