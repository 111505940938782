import { fetchAuthSession } from "aws-amplify/auth";
import { AUTH_HEADER_NAME } from "../../constants";
import { logMessage } from "../../utils/amplifyLogger";

export const jwtToken = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session) {
      throw new Error(`Something went wrong in Session`);
    }

    const { tokens: { idToken } = {} } = session;
    return {
      headers: {
        [AUTH_HEADER_NAME]: idToken.toString(),
      },
    };
  } catch (err) {
    logMessage(
      `JWT Token`,
      `Could not get the jwt token due to error => ${err}`,
      `error`
    );
  }
};
