import {
  CONTACT_ATTRIBUTES_ORDER_TEMPLATE,
  CONTACT_NEW_ATTRIBUTE_ORDER_ID,
  CONTACT_HIDE_ATTRIBUTES,
  TRANSCRIPTS_COLUMN_NAME,
} from "../../../constants";
import { predicateBy } from "../utils";
export const getMappingData = (data) => {
  let newAttributeId = CONTACT_NEW_ATTRIBUTE_ORDER_ID;
  const getTemplateData = (name) =>
    CONTACT_ATTRIBUTES_ORDER_TEMPLATE.find(
      (template) => template.name === name
    );

  const result = Object.keys(data)
    .filter((data) => ![TRANSCRIPTS_COLUMN_NAME].includes(data))
    .sort()
    .reduce((acc, cur) => {
      const templateData = getTemplateData(cur);
      if (templateData) {
        acc.push({
          name: cur,
          value: data[cur],
          id: templateData.id,
        });
      } else {
        !CONTACT_HIDE_ATTRIBUTES.includes(cur) &&
          acc.push({
            name: cur,
            value: data[cur],
            id: newAttributeId++,
          });
      }
      return acc;
    }, [])
    .sort(predicateBy("id"));
  return result;
};

export const getMasterRows = (historyData) => {
  return (
    historyData &&
    historyData.reduce((acc, cur) => {
      acc.push({
        id: cur.id,
        ["Time Stamp (PST)"]: cur["Time Stamp (PST)"],
        ["Method"]: cur["Method"],
        ["Queue"]: cur["Queue"],
        ["Ticket or Case #"]: cur["Ticket or Case #"],
        ["Caller ID"]: cur["Caller ID"],
        ["Dialed #"]: cur["Dialed #"],
      });
      return acc;
    }, [])
  );
};
