import { useEffect, useState, useCallback } from "react";
import { Input, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { updateCallHistory } from "../../../../services/history";
import { updateCurrentCtr } from "../../../../services/current";
import { Box } from "@mui/material";
import {
  CURRENT_TICKET_OR_CASE_NUMBER,
  CURRENT_VAR_TICKET_OR_CASE_NUMBER,
} from "../../../../constants";
import styles from "../styles";
import useCss from "../../../../hooks/useCss";
import { getInstanceIdSelector } from "../../../../selectors/userInstanceSelector";

const InputTableCell = ({ row, contactId, timeStamp }) => {
  const classes = useCss(styles);
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState(row);
  const [previous, setPrevious] = useState({});

  const instanceId = useSelector(getInstanceIdSelector);
  useEffect(() => {
    setPrevious(row);
  }, []);

  const { isEditMode } = tableRow;
  const handleChange = useCallback(
    (e) => {
      const newRow = { ...tableRow, value: e.target.value };
      setTableRow(newRow);
    },
    [tableRow]
  );

  const handleCancelMode = useCallback(() => {
    setTableRow(() => {
      return { ...previous };
    });
  }, [tableRow]);

  const handleEditMode = useCallback(() => {
    setTableRow(() => {
      return { ...tableRow, isEditMode: !tableRow.isEditMode };
    });
  }, [tableRow]);

  const handleSaveMode = useCallback(async () => {
    const ticketOrCaseNumberValue = tableRow.value;
    const updateHistoryData = {
      timestamp: timeStamp,
      updates: {
        [CURRENT_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
        [CURRENT_VAR_TICKET_OR_CASE_NUMBER]: ticketOrCaseNumberValue,
      },
    };
    setTableRow(() => {
      return { ...tableRow, isEditMode: !tableRow.isEditMode };
    });
    await dispatch(updateCallHistory(updateHistoryData));
    await dispatch(
      updateCurrentCtr({ instanceId, contactId, ticketOrCaseNumberValue })
    );
  }, [tableRow]);

  return (
    <>
      {isEditMode ? (
        <>
          <Box sx={{ display: "flex", width: 220 }}>
            <Input
              disableUnderline={true}
              className={classes.input}
              value={tableRow.value}
              onChange={(e) => handleChange(e)}
            />

            <IconButton
              disabled={tableRow.value === ""}
              aria-label="save"
              color="primary"
              onClick={handleSaveMode}
            >
              <SaveIcon fontSize="medium" />
            </IconButton>
            <IconButton aria-label="cancel" onClick={handleCancelMode}>
              <CancelIcon fontSize="medium" />
            </IconButton>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", width: 220 }}>
          <Box className={classes.inputValue}>{tableRow.value}</Box>
          <IconButton
            aria-label="edit"
            color="primary"
            onClick={handleEditMode}
          >
            <EditIcon fontSize="medium" />
          </IconButton>
        </Box>
      )}
    </>
  );
};

export default InputTableCell;
