import React from "react";
import { StyledGridOverlay } from "./styled";
import SbuxTranslate from "../SbuxTranslate";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";

export const CustomNoRowsOverlay = React.memo(() => {
  const classes = useCss(styles);

  return (
    <StyledGridOverlay>
      <SbuxLogo className={classes.sbuxLogo} />

      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        <SbuxTranslate>
          {"Information will populate on an active contact"}
        </SbuxTranslate>
      </div>
    </StyledGridOverlay>
  );
});
