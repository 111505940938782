import { createSelector } from "@reduxjs/toolkit";

// Root selectors

const getTextToSpeechState = (state) => state.textToSpeech;

export const getAudioContentSelector = createSelector(
  [getTextToSpeechState],
  (textToSpeech) => textToSpeech?.audioContent
);
