import { createSelector } from "@reduxjs/toolkit";

const getScreenRecordingState = (state) => state.screenRecording;

export const getContactRecordingStateSelector = createSelector(
  [getScreenRecordingState],
  (screenRecording) => screenRecording?.contactRecordingState
);

export const getContactRecordingLoadingStateSelector = createSelector(
  [getScreenRecordingState],
  (screenRecording) => screenRecording?.loading
);

export const getContactRecordingStateErrorSelector = createSelector(
  [getScreenRecordingState],
  (screenRecording) => screenRecording?.error
);
