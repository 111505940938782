import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styles from "../styles";
import useCss from "../../../hooks/useCss";
import { Divider } from "@mui/material";
import SbuxTranslate from "../../SbuxTranslate";

const HeaderList = ({ handleModal }) => {
  const classes = useCss(styles);

  return (
    <>
      <Box sx={{ paddingLeft: 2, paddingTop: 1, paddingBottom: 0.5 }}>
        <span className={classes.headerTitle}>
          <SbuxTranslate>{"Settings"}</SbuxTranslate>
        </span>
        <IconButton
          aria-label="close"
          onClick={handleModal}
          sx={{
            position: "absolute",
            right: 12,
            top: 0,
            "& svg": {
              fontSize: 25,
              outline: "none",
            },
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
    </>
  );
};

export default React.memo(HeaderList);
