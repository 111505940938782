import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getMetricsState = (state) => state.metrics;

export const getQueueMetricsSelector = createSelector(
  [getMetricsState],
  (metrics) => metrics?.queueMetrics
);

export const getMyMetricsDataSelector = createSelector(
  [getMetricsState],
  (metrics) => metrics?.myMetricsData?.metrics
);
