import SbuxEnhancedTable from "../../../../components/SbuxTable/SbuxEnhancedTable";
import Row from "../common/Row";
import { getMappingData } from "../helper";
import { detailColumns } from "./columns";

const DetailTable = ({ masterRow }) => {
  const rows = [{ ...masterRow, detailsRows: getMappingData(masterRow) }];

  return (
    <SbuxEnhancedTable
      rows={rows}
      columns={detailColumns}
      groupColumns={null}
      defaultSortOrder={"asc"}
      RowSource={Row}
      showSearchBar={false}
      showFooter={false}
      showModalTable={true}
    />
  );
};

export default DetailTable;
