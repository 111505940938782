import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getFeatureFlagsData = async (getState, rejectWithValue) => {
  const { connectedInstance: { instanceId = {} } = {} } =
    getState().userInstance;
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/featureFlag/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `featureFlags`,
      message: `Something went wrong in getFeatureFlags API => ${error}`,
    });
    logMessage(
      `featureFlags`,
      `Something went wrong in getFeatureFlags API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getFeatureFlags api endpoint");
  }
};

export const getFeatureFlags = createAsyncThunk(
  "featureFlag/getFeatureFlags",
  async (args, { getState, rejectWithValue }) =>
    await getFeatureFlagsData(getState, rejectWithValue)
);

export const createOrUpdateFeatureFlags = createAsyncThunk(
  "/content/createOrUpdateFeatureFlags",
  async (featureFlagsAttributes, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    try {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/featureFlag/${instanceId}`,
        method: "POST",
        body: { ...featureFlagsAttributes },
      });
      return await getFeatureFlagsData(getState, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `featureFlags`,
        message: `Something went wrong in createOrUpdateFeatureFlags API => ${error}`,
      });
      logMessage(
        `featureFlags`,
        `Something went wrong in createOrUpdateFeatureFlags API => ${error}`,
        `error`
      );
      return rejectWithValue(
        "Error in createOrUpdateFeatureFlags api endpoint"
      );
    }
  }
);

export const deleteFeatureFlags = createAsyncThunk(
  "/content/deleteFeatureFlags",
  async (deleteAttribute, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    try {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/featureFlag/${instanceId}/`,
        method: "DELETE",
        body: { ...deleteAttribute },
      });
      return await getFeatureFlagsData(getState, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `featureFlags`,
        message: `Something went wrong in deleteFeatureFlags API => ${error}`,
      });
      logMessage(
        `featureFlags`,
        `Something went wrong in deleteFeatureFlags API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in deleteFeatureFlags api endpoint");
    }
  }
);

export const getTicketOrCaseFeatureFlagEnabled = createAction(
  "ccp/getTicketOrCaseFeatureFlagEnabled"
);
