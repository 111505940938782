import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getTextToSpeechData = async (textToSpeechPayload, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/textToSpeech`,
      method: "POST",
      body: { ...textToSpeechPayload },
    });
    return response;
  } catch (error) {
    clientLog({
      component: `textToSpeech`,
      message: `Something went wrong in textToSpeech API => ${error}`,
    });
    logMessage(
      `textToSpeech`,
      `Something went wrong in textToSpeech API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in textToSpeech api endpoint");
  }
};
export const getTextToSpeech = createAsyncThunk(
  "textToSpeech/getTextToSpeech",
  async (textToSpeechPayload, { getState, rejectWithValue }) => {
    return await getTextToSpeechData(textToSpeechPayload, rejectWithValue);
  }
);

export const getResetTextToSpeech = createAction(
  "textToSpeech/getResetTextToSpeech"
);
