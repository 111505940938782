import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const updateContactRecordingState = async (
  { action, contactId, initialContactId, instanceId },
  rejectWithValue
) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/screenRecording/${instanceId}/${contactId}/${initialContactId}/${action}`,
      method: "POST",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `screenRecording`,
      message: `Something went wrong in ${action} contactRecording API => ${error}`,
    });
    logMessage(
      `screenRecording API`,
      `Something went wrong in ${action} screenRecording API => ${error}`,
      `error`
    );
    return rejectWithValue(`Error in ${action} screenRecording api endpoint`);
  }
};

export const updateCotactRecordingStateAction = createAsyncThunk(
  "screenRecording/update",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await updateContactRecordingState(
      { ...args, instanceId },
      rejectWithValue
    );
  }
);
