import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import { logMessage } from "./amplifyLogger";
import { clientLog } from "../services/logging";

export const isPhoneNumber = (uiValue) => {
  try {
    return isValidPhoneNumber(uiValue);
  } catch (err) {
    return false;
  }
};

export const phoneNumberDetail = (phoneNumber) => {
  let phoneNumberInfo = {};
  try {
    const validationResult = isPhoneNumber(phoneNumber);
    if (validationResult) {
      const parsedPhoneNumber = parsePhoneNumber(phoneNumber);
      phoneNumberInfo.country = parsedPhoneNumber.country;
      phoneNumberInfo.phoneNumber =
        parsedPhoneNumber.country === "US" || parsedPhoneNumber.country === "CA"
          ? parsedPhoneNumber.nationalNumber
          : parsedPhoneNumber.number;
      phoneNumberInfo.isValid = parsedPhoneNumber.isValid();
      phoneNumberInfo.formattedPhoneNumber =
        formatPhoneNumber(parsedPhoneNumber);
      phoneNumberInfo.errorMessage = null;
    } else {
      phoneNumberInfo.country = null;
      phoneNumberInfo.phoneNumber = null;
      phoneNumberInfo.isValid = false;
      phoneNumberInfo.formattedPhoneNumber = null;
      phoneNumberInfo.errorMessage = validationResult;
    }
  } catch (error) {
    clientLog({
      component: `phonenumber`,
      message: `Something went wrong in phoneNumberDetail => ${error}`,
    });
    logMessage(
      "phoneNumber",
      `Unable to get phoneNumber detail from method phoneNumberDetail => ${error}`,
      "error"
    );
  }

  return phoneNumberInfo;
};

const formatPhoneNumber = (parsedPhoneNumber) => {
  let formattedPhoneNumber;

  try {
    formattedPhoneNumber = parsedPhoneNumber.number;
  } catch (err) {
    clientLog({
      component: `phonenumber`,
      message: `Something went wrong in format PhoneNumber. ${err}`,
    });
    logMessage(
      "phoneNumber",
      `Unable to format phonenumber from method formatPhoneNumber. Error: ${err}`,
      "error"
    );
  }

  return formattedPhoneNumber;
};
