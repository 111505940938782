import { createSlice } from "@reduxjs/toolkit";
import {
  getMainHours,
  createOrUpdateMainHours,
} from "../services/mainHours";

const initialState = {
  mainHoursStatus: "idle",
  isSave: false,
  isError: false,
  mainHoursData: [],
  currentItem: null,
};

const mainHoursSlice = createSlice({
  name: "mainHours",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMainHours.pending, (state) => {
        state.mainHoursStatus = "pending";
      })
      .addCase(getMainHours.fulfilled, (state, action) => {
        const {
          payload: {
            data: { data },
          },
        } = action;
        state.mainHoursStatus = "success";
        state.mainHoursData = data;
      })
      .addCase(getMainHours.rejected, (state) => {
        state.mainHoursStatus = "rejected";
        state.mainHoursData = [];
      })
      .addCase(createOrUpdateMainHours.pending, (state) => {
        state.isSave = true;
        state.isError = false;
        state.mainHoursStatus = "pending";
      })
      .addCase(createOrUpdateMainHours.fulfilled, (state, action) => {
        const {
          payload: {
            data: { data },
          },
        } = action;
        state.mainHoursStatus = "success";
        state.isSave = false;
        state.isError = false;
        state.mainHoursData = data;
      })
      .addCase(createOrUpdateMainHours.rejected, (state) => {
        state.mainHoursStatus = "rejected";
        state.mainHoursData = [];
        state.isSave = false;
        state.isError = true;
      });
  },
});

export default mainHoursSlice.reducer;
