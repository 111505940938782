import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getHistoryState = (state) => state.history;

export const getHistoryDataSelector = createSelector(
  [getHistoryState],
  (history) => history?.historyData
);

export const getUpdatedTicketOrCaseNumberSelector = createSelector(
  [getHistoryState],
  (history) => history.updatedTicketOrCaseNumber
);

export const getTimestampSelector = createSelector(
  [getHistoryState],
  (history) => history.timestamp
);

export const getStatusSelector = createSelector(
  [getHistoryState],
  (history) => history?.status
);

export const getHistoryDialNumberValidateStatusSelector = createSelector(
  [getHistoryState],
  (history) => history.historyDialNumberValidateStatus
);
