import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import SbuxStyledTablePagination from "./SbuxStyledTablePagination";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import SbuxTablePaginationActions from "./SbuxTablePaginationActions";
import SbuxTranslate from "../SbuxTranslate";
import SbuxEnhancedTableHead from "./SbuxEnhancedTableHead";
import { StyledGridOverlay } from "../SbuxDataGrid/styled";
import { Paper, Box, Button } from "@mui/material";
import { getRowsPerPage } from "../../features/content/utils";
import useTextTranslate from "../../features/content/hooks/useTextTranslate";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";

const SbuxBasicTable = ({
  BodySource,
  headerGroupCells,
  headerCells,
  rows,
  rowsPerPage,
  page,
  order,
  orderBy,
  handleChangePage,
  handleChangeRowsPerPage,
  handleRefreshClick,
  onRequestSort,
  showFooter,
  showModalTable = false,
  defaultRowsPerPage,
  isManager = false,
  isLoading = false,
  isTranslationRequired = true,
  handleCheckboxChange,
  isAllChecked,
}) => {
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const classes = useCss(styles);

  const textOfTranslatedText = useTextTranslate("of");

  return (
    <>
      <Paper>
        <TableContainer
          sx={{
            height:
              showModalTable && isManager // admin modal
                ? "35vh"
                : showModalTable && !isManager //history modal
                ? "65vh"
                : "75vh",
            width: "100%",
            minWidth: showModalTable && !isManager ? "29vw" : "100%",
            overflowY: showModalTable && !isManager ? "hidden" : "auto",
            overFlowX: "hidden",
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: showModalTable && isManager ? "35vh" : "59vh",
              overflowY: rows && rows.length === 0 ? "hidden" : "auto",
              paddingBottom: "10px",
            }}
          >
            <Table stickyHeader aria-label="custom pagination table">
              <SbuxEnhancedTableHead
                headerGroupCells={headerGroupCells}
                headerCells={headerCells}
                order={order}
                orderBy={orderBy}
                onRequestSort={onRequestSort}
                isTranslationRequired={isTranslationRequired}
                handleCheckboxChange={handleCheckboxChange}
                isAllChecked={isAllChecked}
              />

              <TableBody>
                {rows && rows.length === 0 && !isLoading ? (
                  <TableRow>
                    <TableCell colSpan={12} sx={{ border: "none" }}>
                      <Box
                        sx={{
                          marginTop:
                            showModalTable && isManager ? "-20%" : "-10%",
                        }}
                      >
                        <StyledGridOverlay>
                          <SbuxLogo className={classes.sbuxLogo} />

                          <Box className={classes.dataNotFound}>
                            {isTranslationRequired ? (
                              <SbuxTranslate>{`No data found`}</SbuxTranslate>
                            ) : (
                              <Box>{`No data found`}</Box>
                            )}
                            <Box className="actions">
                              <Button
                                variant="contained"
                                size="medium"
                                onClick={handleRefreshClick}
                              >
                                {isTranslationRequired ? (
                                  <SbuxTranslate>{`Refresh`}</SbuxTranslate>
                                ) : (
                                  "Refresh"
                                )}
                              </Button>
                            </Box>
                          </Box>
                        </StyledGridOverlay>
                      </Box>
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    <BodySource />
                    {emptyRows > 0 && <TableRow />}
                  </>
                )}
              </TableBody>
              {showFooter && (
                <TableFooter
                  className={classes.tableFooter}
                  sx={{
                    bottom: isManager ? "-1%" : 0,
                  }}
                >
                  <TableRow
                    sx={{
                      borderBottom: "1px solid #fff",
                    }}
                  >
                    <SbuxStyledTablePagination
                      rowsPerPageOptions={getRowsPerPage(defaultRowsPerPage)}
                      labelRowsPerPage={
                        isTranslationRequired ? (
                          <SbuxTranslate>{`Rows per page`}</SbuxTranslate>
                        ) : (
                          <>{`Rows per page`}</>
                        )
                      }
                      labelDisplayedRows={(from = page) =>
                        `${from.from}-${
                          from.to === -1 ? from.count : from.to
                        } ${textOfTranslatedText} ${from.count}`
                      }
                      count={rows.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      SelectProps={{
                        inputProps: { "aria-label": "rows per page" },
                        MenuProps: {
                          MenuProps: {
                            className: classes.pagination,
                          },
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={SbuxTablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              )}
            </Table>
          </Box>
        </TableContainer>
      </Paper>
    </>
  );
};

export default SbuxBasicTable;
