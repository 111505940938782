const styles = (theme) => ({
  root: {
    height: "70vh",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  center: {
    position: "absolute",
    width: "50%",
  },
});

export default styles;
