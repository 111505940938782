import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

export const StyledCloseButton = styled(({ ...props }) => (
  <IconButton {...props} slots={{ root: "span" }}>
    <CloseIcon />
  </IconButton>
))(({ theme }) => ({
  position: "absolute",
  right: 8,
  top: 8,
  color: theme.palette.darkGray[600],
}));
