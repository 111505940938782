import { createSelector } from "@reduxjs/toolkit";
const getDataMgtState = (state) => state.dataMgt;

export const getMetaConfigDataSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.metaConfigData
);

export const getTableListDataSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.tableListData
);

export const getTableListLoadingSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.tableListLoading
);

export const getDataMgtPendingSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.isDMPending
);
export const getDataMgtSaveSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.isDMSave
);

export const getDataMgtDeleteSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.isDMDelete
);

export const getDataMgtErrorSelector = createSelector(
  [getDataMgtState],
  (dataMgt) => dataMgt?.isDMError
);
