import {
  CONTACT_CONNECTING_STATUS,
  CALL_METHOD_MISSED,
  CALL_METHOD_DECLINED,
  DURATION_FOR_MISSED_CALL,
  CALL_METHOD_ENDED,
  CALL_METHOD_OUTBOUND,
} from "../../constants";
import { logMessage } from "../../utils/amplifyLogger";

export const getAttributesWithMissedCallStatus = (contact) => {
  let contactAttributes = { ...(contact.getAttributes() || {}) };
  try {
    if (!contactAttributes?.Method?.value) {
      logMessage(
        `ccp utils`,
        `contact attributes method value is undefined`,
        `error`
      );
      return;
    }

    let connection = contact
      ._getData()
      .connections.filter(
        (item) => item.type == CALL_METHOD_OUTBOUND.toLowerCase()
      );

    if (
      contactAttributes.Method.value == CALL_METHOD_OUTBOUND &&
      connection.length > 0 &&
      connection[0].state.type == CONTACT_CONNECTING_STATUS
    ) {
      contactAttributes.Method.value = CALL_METHOD_ENDED;
    } else if (contact.getContactDuration() == DURATION_FOR_MISSED_CALL) {
      contactAttributes.Method.value = CALL_METHOD_MISSED;
    } else {
      contactAttributes.Method.value = CALL_METHOD_DECLINED;
    }
  } catch (err) {
    contactAttributes.Method.value = CALL_METHOD_DECLINED;
    logMessage(
      `ccp utils`,
      `Cannot get the missed call status due to ${err}`,
      `error`
    );
  }

  return contactAttributes;
};
