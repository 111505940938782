import React from "react";
import { StyledGridOverlay } from "./styled";
import { ReactComponent as SbuxLogo } from "../../../../../../assets/image.svg";
import styles from "./styles";
import useCss from "../../../../../../hooks/useCss";

export const GenesysRecordingsOverlay = React.memo(() => {
  const classes = useCss(styles);

  return (
    <StyledGridOverlay>
      <SbuxLogo className={classes.sbuxLogo} />

      <div style={{ Top: 10, fontSize: 20, fontWeight: 600 }}>
        {"No data found"}
      </div>
    </StyledGridOverlay>
  );
});
