import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const createOrUpdateIvrTable = createAsyncThunk(
  "ivrTable/createOrUpdateIvrTable",
  async (ivrTable, { rejectWithValue }) => {
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/connectFlowTable`,
        method: "POST",
        body: { ...ivrTable },
      });
      return await response;
    } catch (error) {
      clientLog({
        component: `ivrTable`,
        message: `Something went wrong in createOrUpdateIvrTable API => ${error}`,
      });
      logMessage(
        `ivrTable`,
        `Something went wrong in createOrUpdateIvrTable API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in createOrUpdateIvrTable api endpoint");
    }
  }
);

export const setStatusUpdate = createAction("ivrTable/setStatusUpdate");
