import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { CustomNoRowsOverlay } from "./CustomNoRowsOverlay";
import styles from "./styles";
import useCss from "../../hooks/useCss";

const SbuxDataGrid = ({
  rows,
  columns,
  handleEditRowsModelChange,
  handleIsCellEditable,
  isLoading = false
}) => {
  const classes = useCss(styles);
  return (
    <div className={classes.root}>
      <DataGrid
        className={classes.dataGridRoot}
        rows={rows}
        columns={columns}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        hideFooterSelectedRowCount
        onEditRowsModelChange={handleEditRowsModelChange}
        isCellEditable={handleIsCellEditable}
        disableSelectionOnClick
        hideFooterPagination
        rowHeight={32}
        getRowClassName={(config) => {
          return config.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }}
        disableColumnMenu
        isLoading={isLoading}
      />
    </div>
  );
};

export default React.memo(SbuxDataGrid);
