import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  getTranslatedTextSelector,
  getTranslatedStatusSelector,
} from "../../selectors/settingsSelector";

const SbuxTranslate = ({ children }) => {
  const translatedText = useSelector(getTranslatedTextSelector);
  const translatedStatus = useSelector(getTranslatedStatusSelector);

  const [translatedData, setTranslatedData] = useState([]);
  useEffect(() => {
    translatedStatus === "success" && setTranslatedData(translatedText);
  }, [translatedStatus]);
  const translate = (text) => {
    const textValue = Array.isArray(text) ? text[1] : text;
    if (textValue && translatedData.length > 0) {
      const result =
        translatedData.length > 0 &&
        translatedData.find((f) => f.sourceText === textValue);
      return result?.translatedText
        ? result.translatedText
        : textValue;
    } else {
      return textValue;
    }
  };
  return translate(children);
};

export default SbuxTranslate;
