import { logMessage } from "./amplifyLogger";
import { clientLog } from "../services/logging";

const MAX_LENGTH = process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS ?? 25;

export const ellipse = (value) => {
  try {
    if (value && MAX_LENGTH && value.length > MAX_LENGTH) {
      value = `${value.substring(0, MAX_LENGTH - 3)}...`;
    }
  } catch (err) {
    clientLog({
      component: `stringUtil`,
      message: `Unable to ellipse [${value}]. Error: ${err}`,
    });
    logMessage(
      "stringUtil",
      `Unable to ellipse [${value}]. Error: ${err}`,
      "error"
    );
  }
  return value;
};

export const getTitleForEllipse = (value) => {
  if (value && value.length > MAX_LENGTH) {
    return value;
  } else {
    return "";
  }
};

export const isEmptyString = (value) => {
  return (typeof value === 'string' && value.trim() === "");
}

export const isNotEmptyString = (value) => {
  return (typeof value === 'string' && value.trim().length > 0);
}
