import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getUserInstanceState = (state) => state.userInstance;

// ConnectedInstance Selectors
export const getConnectedInstanceSelector = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance?.connectedInstance
);
export const selectUserInstances = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance.instances
);

export const selectUserShowModal = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance.showModal
);

export const selectUserStatus = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance.status
);
export const selectCallsWaitingWarningThreshold = createSelector(
  [getUserInstanceState],
  (connectedInstance) => connectedInstance.callsWaitingWarningThreshold
);

export const selectCallsWaitingErrorThreshold = createSelector(
  [getUserInstanceState],
  (connectedInstance) => connectedInstance.callsWaitingErrorThreshold
);

// AppConfig Selectors
export const getAppConfigSelector = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance?.connectedInstance?.appConfig
);

export const getVoiceSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.voice
);

export const getLanguagesSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.languages
);
export const getTranscriptsBucketNameSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig.transcriptsBucketName
);

export const getBusinessSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.business
);

export const getClosureTabsSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.closureTabs
);

export const getLanguageSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.language
);
export const getGenesysConfig = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.genesysConfig
);

export const getManagerNavSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.managerNav
);

export const getIsOutboundPhoneEnabledSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.isOutboundPhoneEnabled
);

export const getOutboundPhonesSelector = createSelector(
  [getAppConfigSelector],
  (appConfig) => appConfig?.outboundPhones
);

// Instance ID Selector
export const getInstanceIdSelector = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance?.connectedInstance?.instanceId
);

export const getInstanceUrlSelector = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance?.connectedInstance?.instanceUrl
);

export const getArnSelector = createSelector(
  [getUserInstanceState],
  (userInstance) => userInstance?.connectedInstance?.arn
);
