import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

const SbuxStyledTableRow = styled(TableRow)(({ theme, isHighlighted }) => ({
  // Apply conditional background color based on `isHighlighted`
  backgroundColor: isHighlighted ? "#cba258 !important" : "unset",

  "&:nth-of-type(even)": {
    backgroundColor: "rgba(232, 241, 239, 1)",
  },
  "&:nth-of-type(even):hover, &:nth-of-type(odd):hover": {
    backgroundColor: "rgba(204, 204, 204, 0.5)",
  },
  "&:last-of-type td, &:last-of-type th": {
    borderBottom: 0,
  },
}));

export default SbuxStyledTableRow;
