import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getCcpInitializedStatus = createAction(
  "ccp/getCcpInitializedStatus"
);

export const getCcpAgent = createAction("ccp/getCcpAgent");

export const getCcpContact = createAction("ccp/getCcpContact");

export const getCcpOverlayStatus = createAction("ccp/getCcpOverlayStatus");

export const getCcpContactStatus = createAction("ccp/getCcpContactStatus");

export const getCcpValidationButtonStatus = createAction(
  "ccp/getCcpValidationButtonStatus"
);

export const getCcpCredentials = createAsyncThunk(
  "ccp/credentials",
  async (instanceId) => {
    try {
      const queryParams = {
        queryParams: {
          instanceId: instanceId,
        },
      };
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: "/credentials",
        method: "GET",
        queryParams: queryParams,
      });
      if (!response) {
        throw new Error("ccp API reponse error");
      }
      return response;
    } catch (error) {
      clientLog({
        component: `ccp`,
        message: `Something went wrong in getCcpCredentials API => ${error}`,
      });
      logMessage(
        `ccp`,
        `Something went wrong in getCcpCredentials API => ${error}`,
        `error`
      );
    }
  }
);
