import { useState } from "react";
import NumberInput from "./NumberInput";
import styles from "./styles";
import useCss from "../../hooks/useCss";

const SbuxTimeDuration = ({ config, withValues, onChange }) => {
  const classes = useCss(styles);
  const [duration, setDuration] = useState(withValues);

  const handleOnChange = (event, slot, value) => {
    event?.preventDefault();

    const newValue = {
      ...(duration || {}),
      [slot]: value,
    };

    setDuration(newValue);
    onChange(event, newValue);
  };

  return (
    <>
      <NumberInput
        className={classes.numberInput}
        name={`${config.name}.hh`}
        placeholder="Hours"
        variant={"outlined"}
        min={config?.hh?.min ?? 0}
        max={config?.hh?.max}
        value={duration?.hh}
        onChange={(event, value) => handleOnChange(event, "hh", value)}
      />

      <NumberInput
        className={classes.numberInput}
        name={`${config.name}.mm`}
        placeholder="Minutes"
        variant={"outlined"}
        min={config?.mm?.min ?? 0}
        max={config?.hh?.max}
        value={duration?.mm}
        onChange={(event, value) => handleOnChange(event, "mm", value)}
      />

      <NumberInput
        className={classes.numberInput}
        name={`${config.name}.ss`}
        placeholder="Seconds"
        variant={"outlined"}
        min={config?.ss?.min ?? 0}
        max={config?.hh?.max}
        value={duration?.ss}
        onChange={(event, value) => handleOnChange(event, "ss", value)}
      />
    </>
  );
};

export default SbuxTimeDuration;
