import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CONTACT_ENDED_STATUS,
  CONTACT_CONNECTED_STATUS,
  OVERLAY_BUTTON_REQUIRED_MESSAGE,
  CALL_METHOD_NONE,
  CONTACT_CLOSED_STATUS,
} from "../../../constants";
import { getCcpValidationButtonStatus } from "../../../services/ccp";
import { useDispatch } from "react-redux";
import styles from "../styles";
import SbuxTranslate from "../../../components/SbuxTranslate";
import useCss from "../../../hooks/useCss";
import {
  getCcpContactStatusSelector,
  getCcpContactMethodSelector,
} from "../../../selectors/ccpSelector";
import { getTicketOrCaseFeatureFlagEnabledSelector } from "../../../selectors/featureFlagSelector";
import {
  getTicketOrCaseNumberSelector,
  getTicketOrCaseNumberIsValidSelector,
} from "../../../selectors/currentSelector";

const OverLayCcp = () => {
  const ccpContactStatus = useSelector(getCcpContactStatusSelector);
  const ccpContactMethod = useSelector(getCcpContactMethodSelector);
  const ticketOrCaseFeatureFlagEnabled = useSelector(
    getTicketOrCaseFeatureFlagEnabledSelector
  );
  const ticketOrCaseNumber = useSelector(getTicketOrCaseNumberSelector);
  const ticketOrCaseNumberIsValid = useSelector(
    getTicketOrCaseNumberIsValidSelector
  );
  const dispatch = useDispatch();
  const classes = useCss(styles);

  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [contactTrail, setContactTrail] = useState(new Set());
  const [isTicketOrCaseNumberValid, setIsTicketOrCaseNumberValid] =
    useState(true);

  useEffect(() => {
    /* NONE contact method initiated the beginning of a new call.
       trail is cleared up in this case. */
    let trail;
    if (
      ccpContactMethod === CALL_METHOD_NONE ||
      ccpContactStatus === CONTACT_CLOSED_STATUS
    ) {
      trail = new Set();
      setDisplayOverlay(false);
      setIsTicketOrCaseNumberValid(true);
    } else {
      trail = new Set([...contactTrail]);
      trail.add(ccpContactMethod).add(ccpContactStatus);
    }

    setContactTrail(trail);

    const presentOverlay = shouldDisplayOverlay(ccpContactStatus, trail);

    setDisplayOverlay(presentOverlay);
    dispatch(getCcpValidationButtonStatus(presentOverlay));
  }, [ccpContactStatus, ticketOrCaseNumber]);

  useEffect(() => {
    if (isTicketOrCaseNumberValid !== ticketOrCaseNumberIsValid) {
      ticketOrCaseFeatureFlagEnabled && // Only when flag is on
        setDisplayOverlay(!ticketOrCaseNumberIsValid); // Show Overlay when ticket# failed validator

      setIsTicketOrCaseNumberValid(ticketOrCaseNumberIsValid);
    }
  }, [ticketOrCaseNumberIsValid]);

  /**
   * Evaluate whether a ticket number is required for this call based on call method and status
   */
  const shouldDisplayOverlay = (contactStatus, trail) => {
    return (
      ticketOrCaseFeatureFlagEnabled &&
      contactStatus === CONTACT_ENDED_STATUS &&
      trail.has(CONTACT_CONNECTED_STATUS) &&
      !ticketOrCaseNumberIsValid
    );
  };

  return (
    <div className={classes.overlayContainer}>
      {displayOverlay && (
        <>
          <div className={classes.overlayButton}>
            <SbuxTranslate>{OVERLAY_BUTTON_REQUIRED_MESSAGE}</SbuxTranslate>
          </div>
          <div className={classes.iframeLockOverlay} />
        </>
      )}
    </div>
  );
};

export default OverLayCcp;
