import { logMessage } from "./amplifyLogger";

export const setAgentStateOffline = (screenName) => {
  return new Promise((resolve, reject) => {
    window.connect.agent((agent) => {
      const offlineState = agent.getAgentStates().filter((state) => {
        return state.type === window.connect.AgentStateType.OFFLINE;
      })[0];
      agent.setState(offlineState, {
        success: () => {
          logMessage(
            screenName,
            `Set agent status to Offline via Streams`,
            `info`
          );
          resolve("success");
        },
        failure: (err) => {
          logMessage(
            screenName,
            `Failed to set agent status to Offline via Streams. ${err}`,
            `error`
          );
          reject("failure");
        },
      });
    });
  });
};
