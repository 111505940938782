import React from "react";
import DragElement from "./DragElement";

const DragList = ({ elements }) => {
  const renderElement = () => {
    return (
      elements &&
      elements.length > 0 &&
      elements.map((element, index) => (
        <DragElement element={element} index={index} key={element.tabId} />
      ))
    );
  };

  return <>{renderElement()}</>;
};

export default React.memo(DragList);
