import { useState } from "react";

const errors = {
  valueMissing: "Field is required",
  typeMismatch: "Invalid value",
  // expand with other errors (badInput, customError, range, etc).
};

/**
 * Validate a single field ValidityState
 */
const validateField = (state) => {
  if (!state || state.valid) {
    return null;
  }

  const invalid = [];

  state.valueMissing && invalid.push("valueMissing");
  state.typeMismatch && invalid.push("typeMismatch");
  state.tooLong && invalid.push("tooLong");
  state.tooShort && invalid.push("tooShort");
  // expand to other HTML5 validity props (badInput, customError, range, etc).

  return invalid;
};

/**
 * HTML5 form validation. Collection of errors
 */
export const validateForm = (form, fields) => {
  const invalidFields = {};

  for (const field of fields) {
    const validation = validateField(form.elements[field]?.validity);
    if (Array.isArray(validation) && validation.length > 0) {
      invalidFields[field] = validation[0]; // Only the first error
    }
  }

  const formResults = {};
  for (const [key, value] of Object.entries(invalidFields)) {
    formResults[key] = errors[value];
  }

  formResults["invalid"] = Object.keys(formResults).length > 0;

  return formResults;
};
