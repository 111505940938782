const styles = (theme) => ({
  toggleButton: {
    fontSize: 14,
    outline: "0 !important",
    textTransform: "capitalize",
    height: "26px",
    backgroundColor: `${theme.palette.darkGray[200]} !important`,
    "&:hover": {
      color: `${theme.palette.white.main} !important`,
      backgroundColor: `${theme.palette.darkGray.main} !important`,
    },
    "&.Mui-selected":{
      color: `${theme.palette.white.neutral} !important`,
      backgroundColor: `${theme.palette.darkGray[700]} !important`,
      "&:hover": {
        color: `${theme.palette.white.main} !important`,
      }
    },
    flex: 1,
    textWrap: "nowrap",
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottom: 0,
    marginRight: 2,
  },
  active: {
    color: theme.palette.complementary.contrastText,
    backgroundColor: theme.palette.darkGray.main,
    "&:hover": {
      color: theme.palette.darkGray[900],
    },
  },
  disabled: {
    cursor: "not-allowed",
  },
});

export default styles;
