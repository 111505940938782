import { createSlice } from "@reduxjs/toolkit";
import {
  getQuickConnectsFilterItem,
  getClosureMsgFilterItem,
  getTemporaryMsgFilterItem,
  getMediaMgtFilterItem,
} from "../services/filter";

const initialState = {
  status: "idle",
  quickConnectsFilterItem: [],
  quickConnectsReset: false,
  closureMsgFilterItem: [],
  closureMsgReset: false,
  temporaryMsgFilterItem: [],
  temporaryMsgReset: false,
  mediaMgtFilterItem: [],
  mediaMgtReset: false,
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getQuickConnectsFilterItem, (state, action) => {
      const { payload } = action;
      state.quickConnectsFilterItem = payload;
      state.quickConnectsReset = true;
    }),
      builder.addCase(getClosureMsgFilterItem, (state, action) => {
        const { payload } = action;
        state.closureMsgFilterItem = payload;
        state.closureMsgReset = true;
      }),
      builder.addCase(getTemporaryMsgFilterItem, (state, action) => {
        const { payload } = action;
        state.temporaryMsgFilterItem = payload;
        state.temporaryMsgReset = true;
      }),
      builder.addCase(getMediaMgtFilterItem, (state, action) => {
        const { payload } = action;
        state.mediaMgtFilterItem = payload;
        state.mediaMgtReset = true;
      });
  },
});

export default filterSlice.reducer;
