import { createSelector } from "@reduxjs/toolkit";

const getQueueState = (state) => state.queue;

export const getQueueDeleteStatusSelector = createSelector(
  [getQueueState],
  (queue) => queue?.queueDeleteStatus
);

export const getQueueDeleteErrorSelector = createSelector(
  [getQueueState],
  (queue) => queue?.queueDeleteError
);

export const getQueueLoadingSelector = createSelector(
  [getQueueState],
  (queue) => queue?.queueLoading
);

export const getQueueDataSelector = createSelector(
  [getQueueState],
  (queue) => queue?.queueData
);
