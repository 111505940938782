import { createAction } from "@reduxjs/toolkit";

export const getQuickConnectsFilterItem = createAction(
  "filters/getQuickConnectsFilterItem"
);
export const getClosureMsgFilterItem = createAction(
  "filters/getClosureMsgFilterItem"
);
export const getTemporaryMsgFilterItem = createAction(
  "filters/getTemporaryMsgFilterItem"
);
export const getMediaMgtFilterItem = createAction(
  "filters/getMediaMgtFilterItem"
);
