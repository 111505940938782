import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const retrieveData = async (payload, rejectWithValue) => {
  const { apiName, instanceId } = payload;

  try {
    return await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/connectApis/${instanceId}?apiName=${apiName}`,
      method: "GET",
    });
  } catch (err) {
    clientLog({
      component: `connect`,
      message: `Something went wrong in invoking GET connectApis::${apiName} => ${err}`,
    });
    logMessage(
      `connect`,
      `Something went wrong invoking retrieveApiData => ${err}`,
      `error`
    );
    return rejectWithValue(`Error invoking GET connectApis::${apiName}`);
  }
};

export const resetApiData = createAction("connect/reset");

export const retrieveApiData = createAsyncThunk(
  "connect/retrieve",
  async (payload, { rejectWithValue }) => {
    return await retrieveData(payload, rejectWithValue);
  }
);

export const deleteApiData = createAsyncThunk(
  "connect/delete",
  async (payload, { rejectWithValue }) => {
    const { apiName, instanceId, id } = payload;

    try {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/connectApis/${instanceId}/${id}?apiName=${apiName}`,
        method: "DELETE",
      });

      return await retrieveData(payload, rejectWithValue);
    } catch (err) {
      clientLog({
        component: `connect`,
        message: `Something went wrong in invoking DELETE connectApis::${apiName} => ${err}`,
      });
      logMessage(
        `connect`,
        `Something went wrong invoking deleteApiData => ${err}`,
        `error`
      );
      return rejectWithValue(`Error invoking DELETE connectApis::${apiName}`);
    }
  }
);
