import SbuxCard from "../../../components/SbuxCard";
import SbuxFallback from "../../../components/SbuxFallback";
import { METRICS_SUB_TABS_LIST } from "../../../constants";
import { ErrorBoundary } from "react-error-boundary";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import MetricsTable from "./table/MetricsTable";

const Metrics = () => {
  const handleError = (error) => {
    clientLog({
      component: `Metrics`,
      message: `Something went wrong in Metrics Component => ${error}`,
    });
    logMessage(
      `Unhandle Error in Metrics`,
      `Something went wrong in Metrics Component => ${error}`,
      `error`
    );
  };

  return (
    <ErrorBoundary FallbackComponent={SbuxFallback} onError={handleError}>
      <SbuxCard className="insideTabContainer">
        <MetricsTable subTabs={METRICS_SUB_TABS_LIST} />
      </SbuxCard>
    </ErrorBoundary>
  );
};

export default Metrics;
