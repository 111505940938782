export const columns = [
  {
    label: "",
    id: "fileDownloadURL",
  },
  {
    label: "File Name",
    id: "promptName",
    minWidth: 700,
    sortable: true,
  },
  {
    label: "Description",
    id: "description",
    sortable: true,
    hide: true,
  },
  {
    label: "Type",
    id: "type",
    minWidth: 80,
    sortable: true,
  },
  {
    label: "Language",
    id: "language",
    minWidth: 80,
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "lastUpdated",
    minWidth: 500,
    sortable: true,
  },
  {
    label: "File Name",
    id: "fileName",
    sortable: true,
    hide: true,
  },
  {
    label: "File Path",
    id: "filePath",
    sortable: true,
    hide: true,
  },
  {
    label: "Media Id",
    id: "mediaId",
    sortable: true,
    hide: true,
  },
];

export const filterColumns = [
  {
    id: 0,
    key: "All",
    value: "all",
  },
  {
    id: 1,
    key: "System",
    value: "System",
  },
  {
    id: 2,
    key: "Custom",
    value: "Custom",
  },
];
