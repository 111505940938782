import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getMainHoursData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/businessHours/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `mainHours`,
      message: `Something went wrong in getMainHoursData API => ${error}`,
    });
    logMessage(
      `mainHours API`,
      `Something went wrong in getMainHoursData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getMainHoursData api endpoint");
  }
};

export const getMainHours = createAsyncThunk(
  "mainHours/getMainHours",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getMainHoursData(instanceId, rejectWithValue);
  }
);

export const createOrUpdateMainHours = createAsyncThunk(
  "mainHours/createOrUpdateMainHours",
  async (mainHours, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/businessHours/${instanceId}`,
        method: "POST",
        body: { ...mainHours },
      });
      return await getMainHoursData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `mainHours`,
        message: `Something went wrong in createOrUpdateMainHours API => ${error}`,
      });
      logMessage(
        `mainHours`,
        `Something went wrong in createOrUpdateMainHours API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in createOrUpdateMainHours api endpoint");
    }
  }
);
