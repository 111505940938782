import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const setOutboundCallerId = createAsyncThunk(
  "outboundCall/setOutboundCallerId",
  async (outboundPhoneNumber, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    const payload = { outboundPhone: outboundPhoneNumber.replace(/-/g, "") };

    try {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/outboundPhone/${instanceId}`,
        method: "POST",
        body: { ...payload },
      });
      return outboundPhoneNumber;
    } catch (error) {
      clientLog({
        component: `outboundCall`,
        message: `Something went wrong in setOutboundCallerId API => ${error}`,
      });
      logMessage(
        `outboundCall`,
        `Something went wrong in setOutboundCallerId API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in setOutboundCallerId api endpoint");
    }
  }
);

export const getCallEndedStatus = createAction(
  "outboundCall/getCallEndedStatus"
);
