import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getGlobalConfigState = (state) => state.globalConfig;

// GlobalConfig Selectors
export const getGlobalConfigUiSelector = createSelector(
  [getGlobalConfigState],
  (globalConfig) => globalConfig?.globalConfigUi
);

export const getTimezonesSelector = createSelector(
  [getGlobalConfigState],
  (globalConfig) => globalConfig?.globalConfigUi?.timezones
);
