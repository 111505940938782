import { useState, useRef, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/RadioButtonCheckedOutlined";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useReactMediaRecorder } from "react-media-recorder";
import { blobToBase64 } from "../../features/content/utils";
import SbuxTranslate from "../../components/SbuxTranslate";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { AUDIO_RECORDING_INPROGRESS_TEXT } from "../../constants";

const SbuxMediaRecorder = ({ row, handleMediaInfo }) => {
  const classes = useCss(styles);
  const theme = useTheme();
  const audioRef = useRef();
  const [reset, setReset] = useState(false);
  const [recordingDuration, setRecordingDuration] = useState(0);
  const { status, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl } =
    useReactMediaRecorder({ mediaRecorderOptions: { mimeType: "audio/wav" } });
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    if (reset && audioRef.current) {
      audioRef.current.load();
    }
  }, [reset]);

  useEffect(() => {
    if (mediaBlobUrl) {
      (async () => {
        let base64Result = await blobToBase64(mediaBlobUrl);
        const result = {
          fileContent: base64Result,
          fileName: "record.wav",
          file: mediaBlobUrl,
          isRecorded: true,
        };
        handleMediaInfo(result);
      })();
    }
  }, [mediaBlobUrl]);

  const handleReset = () => {
    clearBlobUrl();
    setReset((prevState) => !prevState);
    handleMediaInfo(null);
    setRecordingDuration(0);
  };

  const handleStart = () => {
    setReset(false);
    startRecording();
    const newTimerId = setInterval(() => {
      setRecordingDuration((prevDuration) => prevDuration + 1);
    }, 1000);
    setTimerId(newTimerId);
  };
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  const handleStop = async () => {
    clearInterval(timerId);
    stopRecording();
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          textTransform: "capitalize",
          marginLeft: 2,
          marginTop: 1,
          marginBottom: 1,
        }}
      >
        {status === "recording" ? (
          <>
            <Box
              sx={{ display: "flex", alignItems: "center", color: theme.palette.redAlert.main }}
            >
              <FiberManualRecordIcon
                sx={{
                  color: theme.palette.redAlert.main,
                  fontSize: 14,
                  marginRight: 1,
                }}
              />
              <SbuxTranslate>{status}</SbuxTranslate>
              <span className={classes.statusSpan}>
                {` ${AUDIO_RECORDING_INPROGRESS_TEXT} ${formatTime(
                  recordingDuration
                )} ....`}
              </span>
            </Box>
          </>
        ) : (
          <SbuxTranslate>{status}</SbuxTranslate>
        )}
      </Box>
      <Box sx={{ marginLeft: 1 }}>
        <audio
          ref={(audio) => {
            audioRef.current = audio;
          }}
          style={{
            height: 30,
            width: 365,
          }}
          src={mediaBlobUrl}
          controls
        />
      </Box>
      <Box className={classes.root}>
        <Button
          disabled={status === "recording" || status === "stopped"}
          variant="contained"
          size="medium"
          onClick={handleStart}
        >
          <SbuxTranslate>{`Start`}</SbuxTranslate>
        </Button>
        <Button
          disabled={status === "idle" || status === "stopped"}
          variant="contained"
          size="medium"
          onClick={handleStop}
        >
          <SbuxTranslate>{`Stop`}</SbuxTranslate>
        </Button>
        <Button
          disabled={status === "idle"}
          variant="outlined"
          size="medium"
          onClick={handleReset}
        >
          <SbuxTranslate>{`Clear`}</SbuxTranslate>
        </Button>
      </Box>
    </Box>
  );
};

export default SbuxMediaRecorder;
