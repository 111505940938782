import Checkbox from "@mui/material/Checkbox";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import styles from "../../styles";
import { columns } from "./columns";
import { COLUMN_ID_SNO } from "../../../../../constants/index";
import { dateWithFormat } from "../../../../../utils/dateUtil";
import useCss from "../../../../../hooks/useCss";

export const EditStopContactsRow = ({ row, handleEditRowClick }) => {
  const classes = useCss(styles);

  const handleCheckboxChange = (event) => {
    handleEditRowClick({ ...row, isChecked: event.target.checked });
  };

  return (
    <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      {columns.map((column) => {
        if (column.id === COLUMN_ID_SNO) {
          return (
            <SbuxStyledTableCell key={column.id} className={classes.cellFocus}>
              <Checkbox
                inputProps={{ "aria-label": "controlled" }}
                disableFocusRipple
                disableRipple
                checked={row?.isChecked}
                onChange={handleCheckboxChange}
                className={classes.cellCheckbox}
              />
            </SbuxStyledTableCell>
          );
        } else {
          return (
            <SbuxStyledTableCell
              key={column.id}
              className={classes.stopContactCell}
            >
              {column.id === "initiationTimestamp"
                ? dateWithFormat(row[column.id], "YYYY-MM-DD hh:mm A")
                : row[column.id]}
            </SbuxStyledTableCell>
          );
        }
      })}
    </SbuxStyledTableRow>
  );
};
