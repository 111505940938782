import { Box } from "@mui/material";
import SbuxCard from "../components/SbuxCard";
import { useSelector } from "react-redux";
import Content from "../features/content";
import ContentTabs from "../features/content/ContentTabs";
import styles from "./styles";
import useCss from "../hooks/useCss";
import {
  selectUserStatus,
  selectUserInstances,
  getConnectedInstanceSelector,
} from "../selectors/userInstanceSelector";

const ContentCrate = () => {
  const classes = useCss(styles);

  const status = useSelector(selectUserStatus);
  const instances = useSelector(selectUserInstances);
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  return (
    <Box className={classes.rightContent}>
      <SbuxCard>
        {connectedInstance ? (
          <Content />
        ) : (
          (status === "success" || status === "rejected") &&
          instances.length === 0 && <ContentTabs />
        )}
      </SbuxCard>
    </Box>
  );
};

export default ContentCrate;
