import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getTabsState = (state) => state.tabs;

export const getActiveTabIdSelector = createSelector(
  [getTabsState],
  (tabs) => tabs?.activeTabId
);

export const getTabsStatusSelector = createSelector(
  [getTabsState],
  (tabs) => tabs?.tabsStatus
);
