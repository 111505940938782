export const metricsGroupColumns = [
  {
    label: "Queue",
    id: "queue",
    flex: 1,
    minWidth: 100,
  },
  {
    label: "Agents",
    id: "agents",
    flex: 1,
    colSpan: 5,
    minWidth: 100,
  },
  {
    label: "Performance",
    id: "contact",
    flex: 1,
    colSpan: 4,
    minWidth: 100,
  },
];
export const metricsColumns = [
  {
    label: "Name",
    id: "queueName",
    flex: 1,
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Online",
    id: "agentsOnline",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Available",
    id: "agentsAvailable",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "On Call",
    id: "agentsOnContact",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "After Call Work",
    id: "agentsAfterContactWork",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Non-Productive",
    id: "agentsNonProductive",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Calls Waiting",
    id: "contactsInQueue",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Longest Wait",
    id: "oldestContactAge",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Scheduled for Callback",
    id: "contactsScheduled",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
];

export const myMetricsColumns = [
  {
    label: "Total Handled",
    id: "contactsHandledTotal",
    flex: 3,
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Handled in",
    id: "contactsHandledInbound",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Handled out",
    id: "contactsHandledOutbound",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Handled Callback",
    id: "contactsHandledCallback",
    flex: 2,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Missed or Rejected",
    id: "agentNonResponse",
    flex: 3,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Avg ACW",
    id: "avgAfterContactWorkTime",
    flex: 2,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Avg Handle Time",
    id: "avgHandleTime",
    flex: 1,
    align: "center",
    minWidth: 100,
    sortable: true,
  },
];
