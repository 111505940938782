import { createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const getTemporaryMsgData = async (instanceId, rejectWithValue) => {
  try {
    const response = await awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/temporaryMessages/${instanceId}`,
      method: "GET",
    });
    return response;
  } catch (error) {
    clientLog({
      component: `temporaryMsg`,
      message: `Something went wrong in getTemporaryMsgData API => ${error}`,
    });
    logMessage(
      `temporaryMsg`,
      `Something went wrong in getTemporaryMsgData API => ${error}`,
      `error`
    );
    return rejectWithValue("Error in getTemporaryMsgData api endpoint");
  }
};

export const getTemporaryMsg = createAsyncThunk(
  "temporaryMsg/getTemporaryMsg",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getTemporaryMsgData(instanceId, rejectWithValue);
  }
);

export const getTemporaryMsgMediaFile = createAsyncThunk(
  "temporaryMsg/getTemporaryMsgMediaFile",
  async (mediaMgtData, { rejectWithValue }) => {
    const { instanceId, languageCode, language, fileName, type } = mediaMgtData;
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/mediaFile/${instanceId}/${languageCode}-${language}/${fileName}/${type}`,
        method: "GET",
      });
      const result = {
        ...mediaMgtData,
        mediaContent: response.data.mediaContent,
      };
      return result;
    } catch (error) {
      clientLog({
        component: `clsoureMsg`,
        message: `Something went wrong in getTemporaryMsgMediaFile API => ${error}`,
      });
      logMessage(
        `clsoureMsg`,
        `Something went wrong in getTemporaryMsgMediaFile API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getTemporaryMsgMediaFile api endpoint");
    }
  }
);

export const createOrUpdateTemporaryMsg = createAsyncThunk(
  "temporaryMsg/createOrUpdateTemporaryMsg",
  async (temporaryMsgData, { getState, rejectWithValue }) => {
    try {
      const { connectedInstance: { instanceId = {} } = {} } =
        getState().userInstance;
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/temporaryMessages/${instanceId}`,
        method: "POST",
        body: { ...temporaryMsgData },
      });
      return await getTemporaryMsgData(instanceId, rejectWithValue);
    } catch (error) {
      clientLog({
        component: `temporaryMsg`,
        message: `Something went wrong in createOrUpdateTemporaryMsg API => ${error}`,
      });
      logMessage(
        `temporaryMsg`,
        `Something went wrong in createOrUpdateTemporaryMsg API => ${error}`,
        `error`
      );
      return rejectWithValue(
        "Error in createOrUpdateTemporaryMsg api endpoint"
      );
    }
  }
);

export const getResetTemporaryMsg = createAction(
  "temporaryMsg/getResetTemporaryMsg"
);
