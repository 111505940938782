import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import { Box, FormControlLabel, Radio } from "@mui/material";

const TemporaryMsgTimeOption = ({
  row,
  name,
  timeOption,
  handleTimeOptionValueChange,
}) => {
  return (
    <SbuxRadioGroup
      value={timeOption}
      handleRadioButtonChange={handleTimeOptionValueChange}
    >
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}>
          <FormControlLabel
            value={"immediately"}
            control={<Radio />}
            label={
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    pointerEvents: timeOption !== "immediately" && "none",
                    opacity: timeOption !== "immediately" && 0.6,
                  }}
                />
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.5,
                    flex: 1,
                    marginRight: 1,
                    minWidth: 100,
                  }}
                >
                  {name === "Start Time" ? "Immediately" : "No end date"}
                </Box>
              </Box>
            }
          />
        </Box>
        <Box sx={{ flex: 2 }}>
          <FormControlLabel
            value={"userDefined"}
            control={<Radio />}
            label={
              <Box
                sx={{
                  display: "flex",
                  fontSize: 13,
                  flex: 1,
                  pointerEvents: timeOption !== "userDefined" && "none",
                  opacity: timeOption !== "userDefined" && 0.6,
                }}
              >
                <Box
                  sx={{
                    fontSize: 13,
                    marginTop: 0.5,
                    flex: 1,
                    marginRight: 1,
                  }}
                >
                  {"User Defined"}
                </Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default TemporaryMsgTimeOption;
