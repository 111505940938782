import LinkListColumn from "@cxeweb/pattern-library/lib/components/link-list-column";

const SbuxLinkListColumn = ({
  headingText = "",
  tagName = "span",
  size = "xs",
  links,
  linkColumnStyle,
  linkStyle,
}) => {
  return (
    <LinkListColumn
      headingProps={{
        size: size,
        tagName: tagName,
      }}
      headingText={headingText}
      className={linkColumnStyle}
      linkClassName={linkStyle}
      links={links}
    />
  );
};

export default SbuxLinkListColumn;
