import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, TextField, IconButton, Autocomplete } from "@mui/material";
import {
  MEDIA_FILE_DOES_NOT_EXISTS,
  MEDIA_FILE_NOT_VALID,
} from "../../../../constants";
import { getMediaMgtDataSelector } from "../../../../selectors/mediaMgtSelector";

const MediafileAutoComplete = ({
  data,
  handleSelectChange = null,
  value,
  disabled,
  required,
  label = null,
  onErrorChange = null,
}) => {
  const [selectedValue, setSelectedValue] = useState(null);
  const [options, setOptions] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hasError, setHasError] = useState(false);

  const mediaMgtData = useSelector(getMediaMgtDataSelector);
  useEffect(() => {
    setOptions(data);
    setSelectedValue(null);
  }, [data]);

  useEffect(() => {
    if (value) {
      const result = mediaMgtData.data.find((item) => item.filePath === value);

      if (result) {
        setSelectedValue({
          name: result.fileName,
          value,
        });
        setErrorMessage(null);
        setHasError(false);
      } else {
        setSelectedValue(null);
        setErrorMessage(MEDIA_FILE_NOT_VALID);
        setHasError(true);
      }
    } else {
      setErrorMessage(null);
      setHasError(false);
    }
  }, [value, mediaMgtData]);

  useEffect(() => {
    if (onErrorChange) {
      onErrorChange(hasError);
    }
  }, [hasError, onErrorChange]);

  const handleHighlightChange = (event, option, reason) => {
    if (option && reason === "keyboard") {
      setSelectedValue(option);
    }
  };

  const handleOnChange = (event, newValue) => {
    const selected = newValue?.value ?? null;
    setSelectedValue(selected);
    handleSelectChange(selected);
    setErrorMessage(null);
    setHasError(false);
  };

  const handleClearButtonClick = () => {
    setSelectedValue(null);
    handleSelectChange(null);
    setErrorMessage(null);
    setHasError(false);
  };

  return (
    <Box>
      <Autocomplete
        className="mediaFile"
        size="small"
        label={label}
        disableClearable
        disablePortal
        options={!options ? [{ name: "loading...", value: "" }] : options}
        autoHighlight
        noOptionsText={
          <Box sx={{ fontSize: 14 }}>{MEDIA_FILE_DOES_NOT_EXISTS}</Box>
        }
        disabled={disabled}
        required={true}
        value={selectedValue}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        onHighlightChange={handleHighlightChange}
        onChange={handleOnChange}
        ListboxProps={{
          sx: { fontSize: 13 },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        getOptionLabel={(option) => option.name ?? ""}
        renderOption={(props, option) => (
          <Box component={"li"} {...props}>
            {option.name}
          </Box>
        )}
        renderInput={(params) => (
          <Box sx={{ position: "relative" }}>
            <TextField
              {...params}
              size="small"
              label={label}
              required={required}
              error={hasError}
              helperText={errorMessage}
            />
            {selectedValue && (
              <IconButton
                className="clearButton"
                disableFocusRipple
                disableRipple
                onClick={handleClearButtonClick}
              >
                <ClearIcon />
              </IconButton>
            )}
          </Box>
        )}
      />
    </Box>
  );
};

export default MediafileAutoComplete;
