import { createSlice } from "@reduxjs/toolkit";
import { signIn, getResetTimers } from "../services/auth";

const initialState = {
  status: "idle",
  isLoggedIn: false,
  isResetTimers: false,
  cognitoUserId: "",
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.status = "pending";
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.status = "success";
        state.isLoggedIn = true;
        // fallback value
        state.cognitoUserId = "no@email";

        // email is returned in the list of user attributes response
        if (Array.isArray(action.payload)) {
          state.cognitoUserId =
            action.payload[1]?.email ?? action?.payload[0]?.username;
        }
      })
      .addCase(signIn.rejected, (state) => {
        state.status = "rejected";
        state.isLoggedIn = false;
        state.cognitoUserId = "";
      })
      .addCase(getResetTimers, (state, action) => {
        state.isResetTimers = action.payload;
      });
  },
});

export default authSlice.reducer;
