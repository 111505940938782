import { useState, useEffect } from "react";
import { Paper, TextField, Box, Button } from "@mui/material";
import {
  CONTACT_CONNECTED_STATUS,
  CONTACT_ENDED_STATUS,
  MISSED_CALL_STATUS,
  REJECTED_CALL_STATUS,
  VAR_TRANSCRIPTS_ENABLED,
  TICKET_OR_CASE_NUMBER_NEW,
  CALL_METHOD_ENDED,
} from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getTicketOrCaseNumber,
  getTicketOrCaseNumberDefault,
  setTicketValidation,
  resetState,
} from "../../../services/current";
import useDebounce from "../hooks/useDebounce";
import useTextTranslate from "../hooks/useTextTranslate";
import SbuxTranslate from "../../../components/SbuxTranslate";
import styles from "./styles";
import { updateCurrentCtr } from "../../../services/current";
import useCss from "../../../hooks/useCss";
import { getConnectedInstanceSelector } from "../../../selectors/userInstanceSelector";
import { getTicketOrCaseNumberPersistedSelector } from "../../../selectors/currentSelector";
import { getCcpContactAttributesSelector } from "../../../selectors/ccpSelector";

const CellEditTextField = ({
  defaultValue,
  ccpContactStatus,
  ccpContactMethod,
  ticketOrCaseFeatureFlagEnabled,
}) => {
  const contactAttributes = useSelector(getCcpContactAttributesSelector);
  const connectedInstance = useSelector(getConnectedInstanceSelector);
  const ticketOrCaseNumberPersisted = useSelector(
    getTicketOrCaseNumberPersistedSelector
  );

  const instanceId = connectedInstance ? connectedInstance.instanceId : null;
  const classes = useCss(styles);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState(defaultValue);
  const [isValid, setIsValid] = useState(true);

  const [contactTrail, setContactTrail] = useState(new Set());
  const [disabled, setDisabled] = useState(true);

  const debounceInputValue = useDebounce(inputValue, 400);
  const invalidTicketOrCaseTranslate = useTextTranslate(
    "Invalid ticket or case #"
  );

  useEffect(() => {
    dispatch(getTicketOrCaseNumber(debounceInputValue));
  }, [debounceInputValue]);

  useEffect(() => {
    // Initize state
    dispatch(resetState({ defaultValue }));
  }, []);

  // Listen for contact changes to set a trail
  useEffect(() => {
    const trail = new Set([...contactTrail]);
    trail.add(ccpContactMethod).add(ccpContactStatus);
    setContactTrail(trail);

    if (ccpContactStatus === CONTACT_ENDED_STATUS) {
      updateValidationState(inputValue);
    }
  }, [ccpContactMethod, ccpContactStatus]);

  /**
   * Handle onChange event of the input text control
   */
  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);

    updateValidationState(value);

    if (isValidInput(value) && value !== ticketOrCaseNumberPersisted) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  /**
   * Input validator.
   * @param  {String}  value    input Value
   * @return {Boolean}          returns true when input value is valid. False otherwise.
   */
  const isValidInput = (value) => {
    return (
      value &&
      value.trim().length > 0 &&
      value?.toLowerCase() !== TICKET_OR_CASE_NUMBER_NEW.toLowerCase()
    );
  };

  /**
   * Handle onFocus event
   */
  const handleFocus = (event) => {
    event.target.select();
  };

  /**
   * Handle click event of Save button
   */
  const handleSave = async () => {
    let areTranscriptsEnabledForCall = false;
    const areTranscriptsEnabledInCallFlow =
      (contactAttributes?.[VAR_TRANSCRIPTS_ENABLED]?.value ?? "true") ===
      "true";

    if (
      ccpContactMethod &&
      [MISSED_CALL_STATUS, REJECTED_CALL_STATUS, CALL_METHOD_ENDED].includes(
        ccpContactMethod
      )
    ) {
      areTranscriptsEnabledForCall = false;
    } else {
      areTranscriptsEnabledForCall = areTranscriptsEnabledInCallFlow;
    }

    await Promise.all([
      dispatch(
        updateCurrentCtr({
          instanceId,
          contactId: contactAttributes["ContactID"]?.value,
          ticketOrCaseNumberValue: inputValue,
        })
      ),
    ]);

    setDisabled(true);
    dispatch(getTicketOrCaseNumber(inputValue));
    dispatch(getTicketOrCaseNumberDefault(null));
  };

  /**
   * Whether the current call has been connected at some point
   */
  const hasBeenConnected = () => {
    return contactTrail.has(CONTACT_CONNECTED_STATUS);
  };

  /**
   * Input value validator
   * @param  string value   input value to validate
   */
  const validateValue = (value) => {
    if (ccpContactStatus === CONTACT_ENDED_STATUS) {
      if (!value || value.trim().length === 0) {
        return false;
      }

      if (ticketOrCaseFeatureFlagEnabled && hasBeenConnected()) {
        if (
          defaultValue &&
          value?.toLowerCase() === TICKET_OR_CASE_NUMBER_NEW.toLowerCase()
        ) {
          return false;
        }
      }
    }

    return true;
  };

  /**
   * Validate input value and update validation state locally and in the store
   */
  const updateValidationState = (ticketValue) => {
    const isValidInput = validateValue(ticketValue); // validator

    dispatch(setTicketValidation(isValidInput));
    setIsValid(isValidInput);
  };

  return (
    <div className={classes.inputContainer}>
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Paper
          className={
            ticketOrCaseFeatureFlagEnabled && !isValid
              ? classes.inputError
              : classes.input
          }
        >
          <TextField
            InputProps={{
              disableUnderline: true,
              readOnly: !hasBeenConnected(),
              className: classes.root,
            }}
            variant="standard"
            value={inputValue}
            defaultValue={defaultValue}
            onChange={handleInputChange}
            onFocus={handleFocus}
            className={classes.input}
            onKeyDown={(e) => e.stopPropagation()}
          />
        </Paper>

        <Box>
          <Button
            disabled={disabled}
            variant="contained"
            size="small"
            onClick={handleSave}
          >
            <SbuxTranslate>{"Save"}</SbuxTranslate>
          </Button>
        </Box>
      </Box>

      <div className={classes.error}>
        {ticketOrCaseFeatureFlagEnabled &&
          !isValid &&
          invalidTicketOrCaseTranslate}
      </div>
    </div>
  );
};
export default CellEditTextField;
