import { useEffect } from "react";
import { setAgentOffline } from "../../../components/SbuxIdleTimer/helper";

const useBrowserTabClose = (instanceUrl, userEmail) => {
  useEffect(() => {
    const handleBeforeUnload = async () => {
      await setAgentOffline("BrowserTabClose", instanceUrl, userEmail);
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, []);
};
export default useBrowserTabClose;
