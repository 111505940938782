import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import { useTheme } from "@mui/material/styles";

const ClosureMsgAutoCompleteTimeTextField = ({
  isError,
  errorMessage,
  params,
}) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex" }}>
      <TextField
        size="small"
        autoComplete="chrome-off"
        {...params}
        InputProps={{
          ...params.InputProps,
          type: "search",
        }}
        error={isError}
        sx={{
          width: 110,
          height: 20,
          m: theme.spacing(0.5, 0.5, 0.5),
          "& .MuiInput-root": {
            fontSize: 14,
            height: 21,
            width: "100%",
          },
          "& .MuiInput-underline": {
            borderBottom: 1.5,
            borderBottomColor: isError ? theme.palette.redAlert.main : `${theme.palette.primary.main} !important`,
          },
          "& .MuiInput-underline:focus-within": {
            borderBottomColor: isError ? theme.palette.redAlert.main : theme.palette.primary.main,
          },
          "& .MuiInput-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "& .MuiInput-root:before": {
            borderBottom: 0,
          },
          "& .MuiInputBase-input": {
            fontSize: 14,
            padding: 0,
          },
          "& .MuiInput-underline:after": {
            borderBottom: 0,
          },
          "input[type='time']::-webkit-calendar-picker-indicator": {
            display: "none",
          },
        }}
      />
      {isError && (
        <Tooltip
          title={
            <Box
              sx={{
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.white.main,
                fontSize: 14,
              }}
            >
              {errorMessage}
            </Box>
          }
        >
          <IconButton
            disableFocusRipple
            disableRipple
            sx={{
              cursor: "pointer",
              color: theme.palette.redAlert.main,
              fontSize: 16,
              width: "1vw",
              marginTop: 0.8,
              height: 18,
              "&:focus": {
                outline: 0,
              },
              "&.MuiIconButton-root": {
                padding: 0,
                paddingLeft: "5px",
              },
            }}
          >
            <ErrorOutlineOutlined />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default ClosureMsgAutoCompleteTimeTextField;
