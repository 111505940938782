export const columns = [
  {
    label: "Name",
    id: "Name",
    flex: 1,
    sortable: true,
  },
  {
    label: "Action",
    id: "Action",
    // flex: 1,
    minWidth: 20,
    width: "40px",
  },
];
