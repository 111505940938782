import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getClosureMsgState = (state) => state.closureMsg;

export const getClosureMsgDataSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.closureMsgData
);

export const getClosureMsgMediaFileInfoSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.closureMsgMediaFileInfo
);

export const getClosureMsgStatusSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.closureMsgStatus
);

export const getClosureMsgPendingSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.isCMPending
);
export const getClosureMsgSaveSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.isCMSave
);
export const getClosureMsgDeleteSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.isCMDelete
);

export const getClosureMsgErrorSelector = createSelector(
  [getClosureMsgState],
  (closureMsg) => closureMsg?.isCMError
);
