import moment from "moment-timezone";
import { CLOSUREMSG_DEFAULT_FILTER_ITEMS } from "../../../constants";

export const closureMsgRepeatPattern = (obj) => {
  let repeatPattern = null;
  switch (obj.repeatPattern.frequency) {
    case "DAILY": {
      if (obj.isDailyOption2Selected) {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          byWeekDay: obj.repeatPattern.byWeekDay
            ? obj.repeatPattern.byWeekDay
            : ["MO"],
          until: obj.repeatPattern.until,
        };
      } else {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          interval: obj.repeatPattern.interval
            ? Number(obj.repeatPattern.interval)
            : 1,
          until: obj.repeatPattern.until,
        };
      }
      break;
    }
    case "WEEKLY": {
      repeatPattern = {
        frequency: obj.repeatPattern.frequency,
        interval: obj.repeatPattern.interval
          ? Number(obj.repeatPattern.interval)
          : 1,
        byWeekDay: obj.repeatPattern.byWeekDay
          ? obj.repeatPattern.byWeekDay
          : ["MO"],
        until: obj.repeatPattern.until,
      };
      break;
    }
    case "MONTHLY": {
      if (obj.isMonthlyOption2Selected) {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          byWeekDay: obj.repeatPattern.byWeekDay
            ? obj.repeatPattern.byWeekDay
            : ["MO"],
          interval: obj.repeatPattern.interval
            ? Number(obj.repeatPattern.interval)
            : 1,
          byWeekDayN: obj.repeatPattern.byWeekDayN
            ? obj.repeatPattern.byWeekDayN
            : 1,
          until: obj.repeatPattern.until,
        };
      } else {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          byDay: obj.repeatPattern.byDay ? obj.repeatPattern.byDay : 1,
          interval: obj.repeatPattern.interval
            ? Number(obj.repeatPattern.interval)
            : 1,
          until: obj.repeatPattern.until,
        };
      }
      break;
    }
    case "YEARLY": {
      if (obj.isYearlyOption2Selected) {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          byWeekDay: obj.repeatPattern.byWeekDay
            ? obj.repeatPattern.byWeekDay
            : ["MO"],
          interval: obj.repeatPattern.interval
            ? Number(obj.repeatPattern.interval)
            : 1,
          byWeekDayN: obj.repeatPattern.byWeekDayN
            ? obj.repeatPattern.byWeekDayN
            : 1,
          byMonth: obj.repeatPattern.byMonth ? obj.repeatPattern.byMonth : 1,
          until: obj.repeatPattern.until,
        };
      } else {
        repeatPattern = {
          frequency: obj.repeatPattern.frequency,
          byDay: obj.repeatPattern.byDay ? obj.repeatPattern.byDay : 1,
          byMonth: obj.repeatPattern.byMonth ? obj.repeatPattern.byMonth : 1,
          interval: obj.repeatPattern.interval ? obj.repeatPattern.interval : 1,
          until: obj.repeatPattern.until,
        };
      }
      break;
    }
    default: {
      break;
    }
  }
  return repeatPattern;
};

const closureMsgFunnelFilterData = (closureMsgData, filteredItem) => {
  let filterData = [];
  const isAllSelected = filteredItem?.includes("all");

  const isDefaultFilterItem =
    filteredItem.length === CLOSUREMSG_DEFAULT_FILTER_ITEMS.length &&
    filteredItem.every(
      (element, index) => element === CLOSUREMSG_DEFAULT_FILTER_ITEMS[index]
    );
  if (isAllSelected) {
    filterData = [...closureMsgData];
  } else if (isDefaultFilterItem) {
    filterData = closureMsgData?.filter((f) => f.status !== "Expired");
  } else {
    filterData = closureMsgData?.filter(
      (f) =>
        filteredItem.includes(f.category) &&
        filteredItem.includes(f.type) &&
        filteredItem.includes(f.status)
    );
  }
  return filterData;
};
export const closureMsgFilterItem = (
  tabValue,
  closureMsgData,
  filteredItem,
  fromDate,
  toDate
) => {
  let funnelFilterData = closureMsgFunnelFilterData(
    closureMsgData,
    filteredItem
  );
  funnelFilterData = addFormattedTimeToFunnelFilterData(funnelFilterData);
  let filterClosureMsgData = null;
  const currentDate = moment().startOf("day");
  const after30Days = moment().add(30, "days").endOf("day");
  switch (tabValue) {
    case "month": {
      filterClosureMsgData =
        funnelFilterData &&
        funnelFilterData.filter((item) => {
          return currentDate.isSame(item.formattedStartDate, "month");
        });
      break;
    }
    case "30days": {
      filterClosureMsgData =
        funnelFilterData &&
        funnelFilterData.filter((item) => {
          return (
            item.formattedStartDate.isSameOrAfter(currentDate) &&
            item.formattedStartDate.isSameOrBefore(after30Days)
          );
        });
      break;
    }
    case "week": {
      filterClosureMsgData =
        funnelFilterData &&
        funnelFilterData.filter((item) => {
          const result = currentDate.week() - item.formattedStartDate.week();
          return result === 0;
        });
      break;
    }
    case "day": {
      filterClosureMsgData =
        funnelFilterData &&
        funnelFilterData.filter((item) => {
          return (
            currentDate.startOf("day").isSameOrAfter(item.startDate) &&
            currentDate.startOf("day").isSameOrBefore(item.endDate)
          );
        });
      break;
    }
    case "custom": {
      filterClosureMsgData =
        funnelFilterData &&
        funnelFilterData.filter((item) => {
          return (
            item.formattedStartDate.isSameOrAfter(
              moment(fromDate).startOf("day").tz(item.startTimeZone)
            ) &&
            item.formattedStartDate.isSameOrBefore(
              moment(toDate).endOf("day").tz(item.startTimeZone)
            )
          );
        });
      break;
    }
    default: {
      break;
    }
  }
  return filterClosureMsgData;
};

const addFormattedTimeToFunnelFilterData = (funnelFilterData) => {
  return (
    funnelFilterData?.map((data) => {
      const result = { ...data };
      if (result?.startDate && result?.startTime && result?.startTimeZone) {
        result.formattedStartDate = moment(
          `${result.startDate} ${result.startTime}`,
          ["YYYY-MM-DD h:mm A"]
        ).tz(result.startTimeZone);
      }
      return result;
    }) ?? []
  );
};

export const isValidDateTime = (dateTimeString) => {
  const givenDateTime = moment(dateTimeString, "MM/DD/YYYY hh:mm A");
  const currentDateTime = moment();
  return givenDateTime.isSameOrAfter(currentDateTime);
};

export const isValidDateTimeWithStartEnd = (startDate, endDate) => {
  const startDateTime = moment(startDate, "MM/DD/YYYY hh:mm A");
  const endDateTime = moment(endDate, "MM/DD/YYYY hh:mm A");
  return endDateTime.isSameOrAfter(startDateTime);
};

export const isEndDateTimeValid = (
  startDate,
  endDate,
  startTime,
  endTime,
  startTimeOption,
  endTimeOption,
  timeZone = "America/Los_Angeles"
) => {
  const format = "YYYYMMDDHHmmss";
  const sDateTime = moment(
    `${moment(startDate).format("MM/DD/YYYY")} ${startTime}`
  );

  const eDateTime = moment(
    `${moment(endDate).format("MM/DD/YYYY")} ${endTime}`
  );

  const currentDateTime = moment().tz(timeZone).format(format);

  if (
    (startTimeOption === "immediately" ||
      (startTimeOption === "userDefined" &&
        sDateTime &&
        sDateTime.isValid() &&
        eDateTime.format(format) > sDateTime.format(format))) &&
    eDateTime &&
    eDateTime.isValid() &&
    eDateTime.format(format) > currentDateTime
  ) {
    return true;
  }
  return false;
};

export const isStartDateTimeValid = (
  startTime,
  startDate,
  endTime,
  timeZone = "America/Los_Angeles"
) => {
  const format = "YYYYMMDDHHmmss";
  const compareTime = moment(endTime, "hh:mm A", true);
  const newTime = moment(startTime, "hh:mm A", true);
  const cnewTime = newTime.format("hh:mm A");
  const currentDateTime = moment().tz(timeZone).format(format);
  const finalSDateTime = moment(
    `${moment(startDate).format("MM/DD/YYYY")} ${cnewTime}`
  ).format(format);
  if (
    newTime &&
    newTime.isValid() &&
    compareTime &&
    compareTime.isValid() &&
    finalSDateTime > currentDateTime
  ) {
    return true;
  }
  return false;
};

export const isStartAndEndDateTimeValid = (
  startDate,
  endDate,
  startTime,
  endTime,
  startTimeOption,
  endTimeOption,
  timeZone = "America/Los_Angeles",
  fromDialog = false
) => {
  if (startTimeOption === "immediately") {
    timeZone = "America/Los_Angeles";
  }

  const format = "YYYYMMDDHHmmss";
  const finalSDateTime = moment(
    `${moment(startDate).format("MM/DD/YYYY")} ${startTime}`
  ); //.tz('Asia/Calcutta')//.valueOf();
  const finalEDateTime = moment(
    `${moment(endDate).format("MM/DD/YYYY")} ${endTime}`
  ); //.tz('Asia/Calcutta').valueOf()
  const currentDateTime = moment().tz(timeZone); //.valueOf()//moment().utc()//.tz(timeZone)

  const isSValid =
    finalSDateTime.format(format) > currentDateTime.format(format);
  const isEValid =
    finalEDateTime.format(format) > currentDateTime.format(format);
  return (
    (startTimeOption === "immediately" && endTimeOption === "immediately") ||
    (startTimeOption === "userDefined" &&
      endTimeOption === "immediately" &&
      isSValid) ||
    (startTimeOption === "immediately" &&
      endTimeOption === "userDefined" &&
      isEValid) ||
    (startTimeOption === "userDefined" &&
      endTimeOption === "userDefined" &&
      isSValid &&
      isEValid &&
      finalEDateTime.format(format) > finalSDateTime.format(format))
  );
};

export const sortingCaseInSensitive = (strArray) => {
  return strArray?.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase()
      ? 1
      : b.name.toLowerCase() > a.name.toLowerCase()
      ? -1
      : 0
  );
};

export function closureRepeatEndDate() {
  return moment().add(1, "days").toDate();
}

export function momentToISOString(date) {
  return moment(date).toISOString();
}

export function getTextLengthErrorMessage(length) {
  return `Text length exceeds ${length} characters`;
}

export function getDuplicateNameErrorMessage(name) {
  return `${name} already exist. Please try different`;
}

export function getNumberErrorMessage(filedName, type) {
  return `${filedName} type must be ${type}`;
}
export const isNumeric = (value) => {
  return /^[0-9]+$/.test(value);
};
