import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getTemporaryMsgState = (state) => state.temporaryMsg;

export const getTemporaryMsgDataSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.temporaryMsgData
);

export const getTemporaryMsgMediaFileInfoSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.temporaryMsgMediaFileInfo
);

export const getTemporaryMsgStatusSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.temporaryMsgStatus
);

export const getTemporaryMsgPendingSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.isTMPending
);

export const getTemporaryMsgErrorSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.isTMError
);
export const getTemporaryMsgSaveSelector = createSelector(
  [getTemporaryMsgState],
  (temporaryMsg) => temporaryMsg?.isTMSave
);
