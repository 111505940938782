import { createSlice } from "@reduxjs/toolkit";
import {
  getMediaMgt,
  getMediaFile,
  createOrUpdateMediaMgt,
  deleteMediaMgt,
  getSubActiveTabId,
  getMediaExpandCollapseInfo,
  getResetMediaMgt,
  getMediaShowEdit,
  getMediaHideEdit,
} from "../services/mediaMgt";

const initialState = {
  mediaMgtStatus: "idle",
  activeSubTabId: "subTab1",
  mediaMgtFileStatus: "idle",
  mediaExpandCollapseInfo: null,
  isMMPending: false,
  isMMSave: false,
  isMMDelete: false,
  isMMError: false,
  mediaContent: null,
  mediaMgtData: [],
  isEditOpen: false,
  editData: null,
  languageId: "",
};

const processPayload = (payload) => {
  const clonedPayload = { ...payload };
  delete clonedPayload["__typename"];
  delete clonedPayload["__operation"];
  return clonedPayload;
};

const mediaMgtSlice = createSlice({
  name: "mediaMgt",
  initialState,
  reducers: {
    updateLanguageId: (state, { payload }) => {
      state.languageId = payload;
    },

    createMMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      state.mediaMgtData.data.push({
        ...processedPayload,
        highLight: true,
      });
    },
    updateMMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      state.mediaMgtData.data = state.mediaMgtData.data.map((item) => {
        if (item.mediaId === processedPayload.mediaId) {
          return { ...processedPayload, highLight: true };
        } else {
          return { ...item, highLight: false };
        }
      });
    },
    deleteMMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      const idToDelete = processedPayload.mediaId;
      state.mediaMgtData.data = state.mediaMgtData.data.filter(
        (item) => item.mediaId !== idToDelete
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMediaMgt.pending, (state) => {
        state.mediaMgtStatus = "pending";
        state.isMMPending = false;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = false;
        state.mediaMgtData = [];
      })
      .addCase(getMediaMgt.fulfilled, (state, action) => {
        const { payload } = action;
        state.mediaMgtStatus = "success";
        state.mediaMgtData = payload.data;
      })
      .addCase(getMediaMgt.rejected, (state) => {
        state.mediaMgtStatus = "rejected";
        state.isMMPending = false;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = true;
        state.mediaMgtData = [];
      })
      .addCase(getMediaFile.pending, (state) => {
        state.mediaMgtFileStatus = "pending";
        state.isMMPending = true;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = false;
        state.mediaContent = null;
      })
      .addCase(getMediaFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.isMMPending = false;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = false;
        state.mediaMgtFileStatus = "success";
        state.mediaContent = payload.data.mediaContent;
      })
      .addCase(getMediaFile.rejected, (state) => {
        state.mediaMgtFileStatus = "rejected";
        state.isMMPending = false;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = true;
        state.mediaContent = null;
      })
      .addCase(createOrUpdateMediaMgt.pending, (state) => {
        state.isMMPending = true;
        state.isMMSave = false;
        state.isMMError = false;
        state.mediaMgtStatus = "pending";
      })
      .addCase(createOrUpdateMediaMgt.fulfilled, (state, action) => {
        const { payload } = action;
        state.mediaMgtStatus = "created";
        state.isMMSave = true;
        state.isMMError = false;
        state.isMMPending = false;
        state.mediaMgtData = payload.data;
      })
      .addCase(createOrUpdateMediaMgt.rejected, (state) => {
        state.mediaMgtStatus = "rejected";
        state.isMMSave = false;
        state.isMMPending = false;
        state.isMMError = true;
      })
      .addCase(deleteMediaMgt.pending, (state) => {
        state.isMMPending = true;
        state.isMMSave = false;
        state.isMMError = false;
        state.mediaMgtStatus = "pending";
      })
      .addCase(deleteMediaMgt.fulfilled, (state, action) => {
        const { payload } = action;
        state.mediaMgtStatus = "deleted";
        state.mediaMgtData = payload.data;
        state.isMMPending = false;
        state.isMMDelete = true;
        state.isMMError = false;
      })
      .addCase(deleteMediaMgt.rejected, (state) => {
        state.mediaMgtStatus = "rejected";
        state.isMMPending = false;
        state.isMMDelete = false;
        state.isMMError = true;
      })
      .addCase(getSubActiveTabId, (state, action) => {
        const { payload } = action;
        state.activeSubTabId = payload;
      })
      .addCase(getMediaExpandCollapseInfo, (state, action) => {
        const { payload } = action;
        state.mediaExpandCollapseInfo = payload;
      })
      .addCase(getResetMediaMgt, (state) => {
        state.mediaMgtStatus = "idle";
        state.mediaMgtFileStatus = "idle";
        state.isMMPending = false;
        state.isMMSave = false;
        state.isMMDelete = false;
        state.isMMError = false;
        state.mediaExpandCollapseInfo = null;
      })
      .addCase(getMediaShowEdit, (state, action) => {
        const { payload } = action;
        state.isEditOpen = true;
        state.editData = payload.data;
      })
      .addCase(getMediaHideEdit, (state, action) => {
        state.isEditOpen = false;
        state.editData = null;
      });
  },
});

export const { updateMMData, createMMData, deleteMMData, updateLanguageId } =
  mediaMgtSlice.actions;
export default mediaMgtSlice.reducer;
