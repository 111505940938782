import { createSlice } from "@reduxjs/toolkit";
import {
  getTemporaryMsg,
  createOrUpdateTemporaryMsg,
  getTemporaryMsgMediaFile,
  getResetTemporaryMsg,
} from "../services/temporaryMsg";

const initialState = {
  temporaryMsgStatus: "idle",
  isTMPending: false,
  isTMSave: false,
  isTMDelete: false,
  isTMError: false,
  temporaryMsgData: [],
  temporaryMsgMediaFileInfo: null,
};

const processPayload = (payload) => {
  const clonedPayload = { ...payload };
  delete clonedPayload["__typename"];
  delete clonedPayload["__operation"];
  return clonedPayload;
};

const processMessages = (processedPayload) => {
  const parsedMessages = JSON.parse(processedPayload.messages);

  return {
    message_en: parsedMessages?.en || null,
    message_fr: parsedMessages?.fr || null,
    message_sp: parsedMessages?.sp || null,
    message_zh: parsedMessages?.zh || null,
    message_zhTW: parsedMessages?.["zh-TW"] || null,
  };
};
const temporaryMsgSlice = createSlice({
  name: "temporaryMsg",
  initialState,
  reducers: {
    createTMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      state.temporaryMsgData.push({
        ...processedPayload,
        highLight: true,
      });
    },

    updateTMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      const messages = processMessages(processedPayload);
      state.temporaryMsgData = state.temporaryMsgData.map((item) => {
        if (item.sequenceNumber === processedPayload.sequenceNumber) {
          return { ...processedPayload, ...messages, highLight: true };
        } else {
          return { ...item, highLight: false };
        }
      });
    },

    resetHighlight: (state, { payload }) => {
      state.temporaryMsgData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTemporaryMsg.pending, (state) => {
        state.temporaryMsgStatus = "pending";
        state.isTMPending = false;
        state.isTMSave = false;
        state.isTMDelete = false;
        state.isTMError = false;
        state.temporaryMsgData = [];
      })
      .addCase(getTemporaryMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.temporaryMsgStatus = "success";
        state.isTMPending = false;
        state.temporaryMsgData = payload.data.data;
      })
      .addCase(getTemporaryMsg.rejected, (state) => {
        state.temporaryMsgStatus = "rejected";
        state.isTMPending = false;
        state.isTMError = true;
        state.temporaryMsgData = [];
      })
      .addCase(getTemporaryMsgMediaFile.pending, (state) => {
        state.temporaryMsgStatus = "pending";
        state.temporaryMsgMediaFileInfo = null;
      })
      .addCase(getTemporaryMsgMediaFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.temporaryMsgStatus = "success";
        state.temporaryMsgMediaFileInfo = payload;
      })
      .addCase(getTemporaryMsgMediaFile.rejected, (state) => {
        state.temporaryMsgStatus = "rejected";
        state.temporaryMsgMediaFileInfo = null;
      })
      .addCase(createOrUpdateTemporaryMsg.pending, (state) => {
        state.isTMPending = true;
        state.isTMSave = false;
        state.isTMDelete = false;
        state.isTMError = false;
        state.temporaryMsgStatus = "pending";
      })
      .addCase(createOrUpdateTemporaryMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.temporaryMsgStatus = "created";
        state.closureOriginalData = payload.data.data;
        state.temporaryMsgData = payload.data.data;
        state.isTMPending = false;
        state.isTMSave = true;
        state.isTMDelete = false;
        state.isTMError = false;
      })
      .addCase(createOrUpdateTemporaryMsg.rejected, (state) => {
        state.temporaryMsgStatus = "rejected";
        state.isTMPending = false;
        state.isTMSave = false;
        state.isTMDelete = false;
        state.isTMError = true;
      })
      .addCase(getResetTemporaryMsg, (state) => {
        state.temporaryMsgStatus = "idle";
        state.isTMPending = false;
        state.isTMSave = false;
        state.isTMDelete = false;
        state.isTMError = false;
      });
  },
});
export const { updateTMData, createTMData, resetHighlight } =
  temporaryMsgSlice.actions;
export default temporaryMsgSlice.reducer;
