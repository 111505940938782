import { createSlice } from "@reduxjs/toolkit";
import { updateCotactRecordingStateAction } from "../services/screenRecording";
import { DEFAULT_SCREEN_RECORDING_API_ERROR_MESSAGE } from "../constants";

const initialState = {
  contactRecordingState: null,
  loading: false,
  error: null,
};

const screenRecordingSlice = createSlice({
  name: "screenRecording",
  initialState,
  reducers: {
    resetContactRecordingStateAction(state, { payload }) {
      state.contactRecordingState = payload ?? null;
      state.loading = null;
      state.error = null;
    },
    resetError(state) {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCotactRecordingStateAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCotactRecordingStateAction.fulfilled, (state, action) => {
        const { payload } = action ?? {};
        const { action: contactRecordingState, status } = payload?.data ?? {};
        if (status === "success") {
          state.contactRecordingState = contactRecordingState;
          state.error = null;
        } else {
          // show only default error message
          // since this is only for the agent
          state.error = DEFAULT_SCREEN_RECORDING_API_ERROR_MESSAGE;
        }
        state.loading = false;
      })
      .addCase(updateCotactRecordingStateAction.rejected, (state) => {
        state.loading = false;
        state.error = DEFAULT_SCREEN_RECORDING_API_ERROR_MESSAGE;
      });
  },
});

export const screenRecordingReducer = screenRecordingSlice.reducer;
export const screenRecordingActions = screenRecordingSlice.actions;
