const getFormattedDuration = (duration, translatedObj, isMinutes) => {
  let formattedDuration = "";
  if (duration > 1) {
    formattedDuration = `${duration.toString()} ${
      isMinutes
        ? translatedObj.minutesTranslated
        : translatedObj.secondsTranslated
    }`;
  } else if (duration > 0) {
    formattedDuration = `${duration.toString()} ${
      isMinutes
        ? translatedObj.minuteTranslated
        : translatedObj.secondTranslated
    }`;
  }
  return formattedDuration;
};

export const getDurationForSeconds = (seconds, translatedObj) => {
  const minutes = Math.floor(seconds / 60);
  const sec = seconds % 60;
  const formattedMinutes = getFormattedDuration(minutes, translatedObj, true);
  const formattedSeconds = getFormattedDuration(sec, translatedObj, false);
  const result = formattedSeconds
    ? `${formattedMinutes} ${formattedSeconds}`
    : formattedMinutes;
  return result;
};

export const isNumeric = (value) => {
  return !isNaN(parseFloat(value)) && !isNaN(value - 0);
};
