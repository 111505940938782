import moment from "moment";

export const today = (dateFormat) => {
  return moment().format(dateFormat);
};

export const formatMillisToMinutesAndSeconds = (ms) => {
  return moment.utc(ms).format("mm:ss");
};

export const dateWithFormat = (date, format) => {
  return moment(new Date(date)).format(format);
};
