import apigClientFactory from "aws-api-gateway-client";
import { jwtToken } from "../utils/token";
import { logMessage } from "../../utils/amplifyLogger";

export const awsClientGateway = async ({
  endpoint,
  params = {},
  pathTemplate,
  method,
  queryParams = {},
  body = {},
}) => {
  try {
    const config = { invokeUrl: endpoint };
    const authHeader = await jwtToken();
    const additionalParams = { ...authHeader, ...queryParams };
    const apiClient = apigClientFactory.newClient(config);

    const response = apiClient.invokeApi(
      params,
      pathTemplate,
      method,
      additionalParams,
      body
    );
    if (!response) {
      throw new Error(`Http error in ApiGateway Request! status: ${response}`);
    }
    return response;
  } catch (err) {
    logMessage(
      `Gateway`,
      `Something went wrong in ApiGateway Request : ${err}`,
      `error`
    );
  }
};
