import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import CCP from "../features/ccp";
import SbuxFallback from "../components/SbuxFallback";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "../services/logging";
import styles from "./styles";
import useCss from "../hooks/useCss";
import {
  selectUserStatus,
  selectUserInstances,
  getConnectedInstanceSelector,
} from "../selectors/userInstanceSelector";

const HeaderCrate = () => {
  const classes = useCss(styles);

  const status = useSelector(selectUserStatus);
  const instances = useSelector(selectUserInstances);
  const connectedInstance = useSelector(getConnectedInstanceSelector);

  const renderFallback = () => {
    clientLog({
      component: `HeaderCreate`,
      message: `There was no instance provided.`,
    });
    logMessage(`HeaderCreate`, `${`There was no instance provided.`}`, `error`);
    return (
      <div className={classes.container}>
        <div className={classes.heading}>
          <SbuxFallback />
        </div>
      </div>
    );
  };
  return (
    <Box className={classes.leftContent}>
      {connectedInstance ? (
        <CCP />
      ) : (
        (status === "success" || status === "rejected") &&
        instances.length === 0 &&
        renderFallback()
      )}
    </Box>
  );
};

export default HeaderCrate;
