import { useMemo } from "react";
import { useTheme } from "@mui/material/styles";
import { css } from "@emotion/css";

const useCss = (stylesSpec) => {
  const theme = useTheme();

  return useMemo(() => {
    const normalized =
      typeof stylesSpec === "function" ? stylesSpec(theme) : stylesSpec;
    const transformed = {};

    Object.entries(normalized).forEach(([key, value = ""]) => {
      transformed[key] = css(value);
    });

    return transformed;
  }, [stylesSpec, theme]);
};

export default useCss;
