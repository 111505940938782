import { createSelector } from "@reduxjs/toolkit";

const getResourcesState = (state) => state.resources;

export const getResourcesDataSelector = createSelector(
  [getResourcesState],
  (resources) => resources?.resourcesData
);

export const getResourceStatusSelector = createSelector(
  [getResourcesState],
  (resources) => resources?.resourceStatus
);
