export const columns = [
  {
    label: "Business",
    id: "groupName",
    sortable: true,
  },
  {
    label: "Description",
    id: "description",
    sortable: true,
  },
  {
    label: "Category",
    id: "category",
    sortable: true,
  },
  {
    label: "Start Date",
    id: "startDate",
    sortable: true,
  },
  {
    label: "Start Time",
    id: "startTime",
    sortable: true,
  },
  {
    label: "End Date",
    id: "endDate",
    sortable: true,
  },
  {
    label: "End Time",
    id: "endTime",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "lastUpdated",
    sortable: true,
  },
];

export const closureColumns = [
  {
    label: "Name",
    id: "name",
    minWidth: 100,
    sortable: false,
  },
  {
    label: "Value",
    id: "value",
    minWidth: 500,
    sortable: false,
  },
];

export const filterColumns = [
  {
    id: 0,
    key: "All",
    value: "all",
  },
  {
    id: 1,
    subTitle: "Category",
    filterList: [
      {
        id: 0,
        key: "Emergency",
        value: "Emergency",
      },
      {
        id: 1,
        key: "Holiday",
        value: "Holiday",
      },
      {
        id: 2,
        key: "Maintenance",
        value: "Maintenance",
      },
      {
        id: 3,
        key: "Meeting",
        value: "Meeting",
      },
      {
        id: 4,
        key: "Other",
        value: "Other",
      },
    ],
  },
  {
    id: 2,
    subTitle: "Series Setting",
    filterList: [
      {
        id: 0,
        key: "Single",
        value: "Single",
      },
      {
        id: 1,
        key: "Repeat",
        value: "Repeat",
      },
    ],
  },

  {
    id: 3,
    subTitle: "Status",
    filterList: [
      {
        id: 0,
        key: "Active",
        value: "Active",
      },
      {
        id: 1,
        key: "Expired",
        value: "Expired",
      },
      {
        id: 2,
        key: "Scheduled",
        value: "Scheduled",
      },
    ],
  },
];
