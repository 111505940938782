import { useEffect, useState } from "react";
import SbuxLoader from "../../components/SbuxLoader";
import { useDispatch, useSelector } from "react-redux";
import InitCcp from "./components/InitCcp";
import { getCcpCredentials } from "../../services/ccp";
import { clearCcpCredentials } from "../../slices/ccpSlice";
import {
  getInstanceIdSelector,
  getInstanceUrlSelector,
} from "../../selectors/userInstanceSelector";
import {
  getCcpStatusSelector,
  getCcpInitializedSelector,
  getAccessTokenSelector,
  getAccessTokenExpirationSelector,
  getRefreshTokenSelector,
  getRefreshTokenExpirationSelector,
} from "../../selectors/ccpSelector";

const CCP = () => {
  const dispatch = useDispatch();
  const instanceId = useSelector(getInstanceIdSelector);
  const instanceUrl = useSelector(getInstanceUrlSelector);
  const status = useSelector(getCcpStatusSelector);
  const isCcpInitialized = useSelector(getCcpInitializedSelector);
  const AccessToken = useSelector(getAccessTokenSelector);
  const AccessTokenExpiration = useSelector(getAccessTokenExpirationSelector);
  const RefreshToken = useSelector(getRefreshTokenSelector);
  const RefreshTokenExpiration = useSelector(getRefreshTokenExpirationSelector);

  const [loginUrl, setLoginUrl] = useState(null);
  const [ccpUrl, setCcpUrl] = useState(null);

  useEffect(() => {
    instanceId && !isCcpInitialized && dispatch(getCcpCredentials(instanceId));
  }, [instanceId, isCcpInitialized]);

  useEffect(() => {
    if (AccessToken) {
      const actionUrl = `${instanceUrl}/auth/sign-in`;
      const loginFormUrl = `connect-login.html?accessToken=${AccessToken.trim()}
      &accessTokenExpiration=${AccessTokenExpiration.trim()}
      &refreshToken=${RefreshToken.trim()}
      &refreshTokenExpiration=${RefreshTokenExpiration.trim()}
      &actionUrl=${actionUrl.trim()}`;
      setLoginUrl(loginFormUrl);
      setCcpUrl(`${instanceUrl}/${process.env.REACT_APP_CONNECT_CCP_VERSION}`);
      dispatch(clearCcpCredentials());
    }
  }, [AccessToken]);

  return (
    <>
      {status !== "success" ? (
        <SbuxLoader />
      ) : (
        loginUrl && ccpUrl && <InitCcp ccpUrl={ccpUrl} loginUrl={loginUrl} />
      )}
    </>
  );
};

export default CCP;
