import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../../components/SbuxTable/SbuxEnhancedTable";
import EditFeatureFlagsRow from "./EditFeatureFlagsRow";
import { columns } from "./columns";

const FeatureFlagsTable = ({ featureFlags, handleRefrehClick }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  return (
    <SbuxEnhancedTable
      rows={featureFlags}
      columns={columns}
      defaultSortingField={"name"}
      defaultSortOrder={"asc"}
      RowSource={EditFeatureFlagsRow}
      showFooter={true}
      showSearchBar={false}
      isManager={true}
      handleRefreshClick={handleRefrehClick}
      isLoading={isLoading}
    />
  );
};

export default FeatureFlagsTable;
