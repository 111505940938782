import { createSlice } from "@reduxjs/toolkit";
import { getGlobalConfig } from "../services/globalConfig";

const initialState = {
  globalConfigStatus: "idle",
  globalConfigUi: [],
};

const getGlobalConfigFromPayload = (payload) => {
  return (
    payload &&
    JSON.parse(payload).reduce((acc, cur, index) => {
      acc.push({
        ...cur,
        id: index,
      });
      return acc;
    }, [])
  );
};

const globalConfigSlice = createSlice({
  name: "globalConfig",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGlobalConfig.pending, (state) => {
        state.globalConfigStatus = "pending";
      })
      .addCase(getGlobalConfig.fulfilled, (state, action) => {
        const {
          payload: {
            data: { config },
          },
        } = action;
        state.globalConfigStatus = "success";
        state.globalConfigUi = config;
      })
      .addCase(getGlobalConfig.rejected, (state) => {
        state.globalConfigStatus = "rejected";
      });
  },
});

export default globalConfigSlice.reducer;
