import { createSlice } from "@reduxjs/toolkit";
import { getActiveTabId, getTabsStatus } from "../services/tabs";

const initialState = {
  status: "idle",
  activeTabId: "tab1",
  tabsStatus: "disabled",
};

const tabsSlice = createSlice({
  name: "tabs",
  initialState,
  reducers: {
    resetTabToCurrent: (state) => {
      state.activeTabId = "tab1"; // Resetting activeTabId to "tab1"
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveTabId, (state, action) => {
        const { payload } = action;
        state.activeTabId = payload;
      })
      .addCase(getTabsStatus, (state, action) => {
        const { payload } = action;
        state.tabsStatus = payload;
      });
  },
});
export const { resetTabToCurrent } = tabsSlice.actions;
export default tabsSlice.reducer;
