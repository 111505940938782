import { createSelector } from "@reduxjs/toolkit";

const getIvrTableState = (state) => state.ivrTable;

export const getIvrTableStatusCodeSelector = createSelector(
  [getIvrTableState],
  (ivrTable) => ivrTable?.statusCode
);

export const getIvrTableMessageSelector = createSelector(
  [getIvrTableState],
  (ivrTable) => ivrTable?.message
);

export const getIvrTableStatusSelector = createSelector(
  [getIvrTableState],
  (ivrTable) => ivrTable?.status
);
