const styles = (theme) => ({
  gridContainer: {
    display: "flex",
    height: "100%",
  },
  gridGrow: {
    flexGrow: 1,
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
});

export default styles;
