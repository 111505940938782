import { createSlice } from "@reduxjs/toolkit";
import {
  getCallHistory,
  addCallHistory,
  updateCallHistory,
  setHistoryDialNumberValidateStatus,
} from "../services/history";

const initialState = {
  showLoadingIndicator: false,
  status: "idle",
  historyData: [],
  updatedTicketOrCaseNumber: null,
  timestamp: null,
  error: null,
  historyDialNumberValidateStatus: null,
};

const historySlice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCallHistory.pending, (state) => {
        state.showLoadingIndicator = true;
        state.status = "pending";
        state.error = null;
        state.historyData = [];
      })
      .addCase(getCallHistory.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.status = "success";
        state.historyData = data;
        state.showLoadingIndicator = false;
        state.error = null;
      })
      .addCase(getCallHistory.rejected, (state) => {
        state.status = "rejected";
        state.showLoadingIndicator = false;
        state.historyData = [];
      })
      .addCase(addCallHistory.pending, (state) => {
        state.status = "pending";
        state.isLoading = true;
      })
      .addCase(addCallHistory.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.status = "added";
        state.historyData = data;
      })
      .addCase(addCallHistory.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(updateCallHistory.pending, (state) => {
        state.status = "pending";
      })
      .addCase(updateCallHistory.fulfilled, (state, action) => {
        const { payload: data } = action;
        state.timestamp = data.timestamp;
        state.updatedTicketOrCaseNumber = data.updates["Ticket or Case #"];
        state.status = "updated";
      })
      .addCase(updateCallHistory.rejected, (state) => {
        state.status = "rejected";
      })
      .addCase(setHistoryDialNumberValidateStatus, (state, action) => {
        const { payload } = action;
        state.historyDialNumberValidateStatus = payload;
      });
  },
});

export default historySlice.reducer;
