import { CONTACT_ENDED_STATUS } from "../../../constants";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
import { setHistoryDialNumberValidateStatus } from "../../../services/history";

export const dialByPhoneNumber = (
  phoneNumber,
  toggleOpenDialPrompt = null,
  dispatch
) => {
  window.connect.agent((agent) => {
    const contact = agent.getContacts(window.connect.ContactType.VOICE)[0];
    const status = contact && contact.getStatus();
    if (status && status.type !== CONTACT_ENDED_STATUS) {
      agentCallTransfer(phoneNumber, dispatch);
    } else {
      const endpoint = window.connect.Endpoint.byPhoneNumber(phoneNumber);
      agent.connect(endpoint, {
        success: (data) => {
          logMessage(`agentCall`, `Dial Phonenumber success, ${data}`, `info`);
        },
        failure: (err) => {
          clientLog({
            component: `agentCall`,
            message: `Dial Phonenumber failed. ${err}`,
          });
          logMessage(`agentCall`, `Dial Phonenumber failed. ${err}`, `error`);
          dispatch(setHistoryDialNumberValidateStatus("failure"));
        },
      });
    }
  });
  toggleOpenDialPrompt && toggleOpenDialPrompt();
};

const agentCallTransfer = (phoneNumber, dispatch) => {
  dial(phoneNumber, null, dispatch);
};

export const transferToEndpoint = (endpoint, dispatch) => {
  dial(null, endpoint, dispatch);
};

const dial = (phoneNumber, endpointInfo, dispatch) => {
  window.connect.agent((agent) => {
    let endpoint;
    if (phoneNumber) {
      endpoint = window.connect.Endpoint.byPhoneNumber(phoneNumber);
    } else {
      endpoint = endpointInfo;
    }

    agent
      .getContacts(window.connect.ContactType.VOICE)[0]
      .addConnection(endpoint, {
        success: (data) => {
          logMessage(`agentCall`, `Transfer success, ${data}`, `info`);
        },
        failure: (err) => {
          clientLog({
            component: `agentCall`,
            message: `Transfer failed. ${err}`,
          });
          logMessage(`agentCall`, `Transfer failed. ${err}`, `error`);
          dispatch(setHistoryDialNumberValidateStatus("failure"));
        },
      });
  });
};
