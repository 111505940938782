import {
  CALL_METHOD_OUTBOUND,
  ACCEPTED_CALL_STATUS,
  CALLBACK_CALL_STATUS,
} from "../../../constants";
import { logMessage } from "../../../utils/amplifyLogger";
import { clientLog } from "../../../services/logging";
const callStatus = [ACCEPTED_CALL_STATUS, CALLBACK_CALL_STATUS];

export const isTicketOrCaseEditable = (callInfo) => {
  let editable = true;
  try {
    // Inbound calls or transfers that are declined/missed, do not allow ticket or case# to be edited
    if (
      (callInfo.Method !== CALL_METHOD_OUTBOUND || callInfo.AllowAuxTransfer) &&
      !callStatus.includes(callInfo.Method)
    ) {
      editable = false;
    }
  } catch (err) {
    clientLog({
      component: `utils`,
      message: `Unable to determine ticket or case# edit status due to ${err}`,
    });
    logMessage(
      "history util",
      `Unable to determine ticket or case# edit status due to ${err}`,
      "error"
    );
  }

  return editable;
};

export const omitKeyFromObject = (key, object) => {
  const obj = { ...object };
  delete obj?.[`${key}`];
  return obj;
};
