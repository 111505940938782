import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";

const MyMetricsRow = ({ row }) => {
  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell align="center">
          {row.contactsHandledTotal}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.contactsHandledInbound}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.contactsHandledOutbound}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.contactsHandledCallback}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.agentNonResponse}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.avgAfterContactWorkTime}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell align="center">
          {row.avgHandleTime}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default MyMetricsRow;
