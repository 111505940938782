import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SbuxCollapsiblePanel from "../SbuxCollapsiblePanel";

const drawerWidth = 250;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    position: "absolute",
    marginLeft: "inherit",
    width: "100%",
    height: "74vh",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: `calc(${drawerWidth}px)`,
      width: `calc(100% - ${drawerWidth}px)`,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  height: 35,
  justifyContent: "flex-end",
}));

const SbuxDrawer = ({ panelData, children }) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="sticky"
        open={open}
        elevation={0}
        sx={{
          marginTop: 0.8,
          zIndex: 1,
          color: "#000000",
          backgroundColor: "rgb(242, 242, 242)",
          minHeight: "75vh",
          outline: "0 !important",
          width: !open ? 40 : 0,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginLeft: -3,
              marginTop: -4,
              outline: "0 !important",
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon sx={{ fontSize: 24 }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Divider orientation="vertical" />
      <Drawer
        sx={{
          zIndex: 1,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            position: "absolute",
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton
            onClick={handleDrawerClose}
            sx={{ outline: "0 !important" }}
          >
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon sx={{ fontSize: 28 }} />
            ) : (
              <ChevronRightIcon sx={{ fontSize: 28 }} />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <SbuxCollapsiblePanel panelData={panelData} />
        </List>
      </Drawer>
      <Main open={open}>
        <Box sx={{ flex: 1 }}>
          <DrawerHeader />
        </Box>
        <Box
          sx={{
            flex: 3,
            marginLeft: open ? 0.6 : 6,
            marginTop: -3.5,
          }}
        >
          {children}
        </Box>
      </Main>
    </Box>
  );
};
export default SbuxDrawer;
