import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { Box } from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import { useState, useCallback } from "react";
import { audioToBase64 } from "../../../utils";
import { useDropzone } from "react-dropzone";
import { useTheme } from "@mui/material/styles";

const MediaMgtFileUpload = ({ row, handleMediaInfo }) => {
  const theme = useTheme();
  const [files, setFiles] = useState([]);
  const [base64String, setBase64String] = useState("");

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const mediaFiles = [...files, ...acceptedFiles];
      if (mediaFiles.length === 0) {
        setBase64String(null);
        setFiles([]);
        handleMediaInfo({
          errorType: "unsupported",
        });
      } else {
        let base64Result = await audioToBase64(mediaFiles[0]);
        const filesWithId =
          mediaFiles.length > 0 &&
          mediaFiles.map((item, index) => {
            return {
              id: index,
              lastModified: item.lastModified,
              lastModifiedDate: item.lastModifiedDate,
              name: item.name,
              size: item.size,
              type: item.type,
              webkitRelativePath: item.webkitRelativePath,
            };
          });
        setBase64String(base64Result);
        setFiles(filesWithId);
        if (mediaFiles.length > 0) {
          handleMediaInfo({
            fileName: filesWithId[0].name,
            fileContent: base64Result,
            isRecorded: false,
            isUploaded: true,
          });
        }
      }
    },
    [files]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "audio/.wav": [".wav"] },
    onDrop,
  });

  const removeFile = () => {
    setFiles([]);
    handleMediaInfo(null);
  };

  const handleOnError = () => {
    handleMediaInfo({
      errorType: "invalid",
    });
  };

  return (
    <section className="container">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
      </div>
      <Box className="border p-3">
        {files.length === 0 && (
          <>
            <Box
              sx={{
                fontSize: 11,
                color: theme.palette.redAlert.main,
                marginLeft: -3.5,
                marginTop: 1,
                marginBottom: 0.5,
                width: 320,
                "@media (max-width: 1024px)": {
                  marginLeft: -1,
                },
              }}
            >
              <SbuxTranslate>{`Warning: Only upload files you have legal permission to use.`}</SbuxTranslate>
            </Box>

            <Box
              sx={{
                fontSize: 11,
                marginLeft: -3.5,
                marginTop: 0.2,
                marginBottom: 1,
                width: 320,
                "@media (max-width: 1024px)": {
                  marginLeft: -1,
                },
              }}
            >
              <SbuxTranslate>{`Accepted file type: .wav with 32bit or ULAW encoding`}</SbuxTranslate>
            </Box>

            <Box {...getRootProps({ className: "dropzone" })}>
              <input {...getInputProps()} accept="audio/wav" />
              <Box
                sx={{
                  display: "flex",
                  border: "2px dotted green",
                  marginTop: 1,
                  marginLeft: -3.5,
                  marginBottom: 2,
                  "@media (max-width: 1024px)": {
                    marginLeft: -1,
                  },
                }}
              >
                <FileUploadIcon sx={{ fontSize: 24 }} />
                <Box
                  sx={{
                    width: 270,
                    paddingLeft: 1,
                    cursor: "pointer",
                    minHeight: 90,
                  }}
                >
                  <SbuxTranslate>{`Drag and drop file here, or click to select file`}</SbuxTranslate>
                </Box>
              </Box>
            </Box>
          </>
        )}

        {files.length > 0 && (
          <Box sx={{ marginLeft: -3 }}>
            {files.map((row, idx) => (
              <Box key={idx} sx={{ display: "flex", marginTop: 1 }}>
                <Box
                  sx={{
                    paddingLeft: 1,
                    maxWidth: 260,
                    marginLeft: -1.5,
                    "@media (max-width: 1024px)": {
                      marginLeft: 1,
                    },
                  }}
                >
                  {row.name}
                </Box>
                <Box sx={{ paddingLeft: 1, minWidth: 20 }}>
                  <IconButton
                    disableFocusRipple
                    disableRipple
                    onClick={removeFile}
                    sx={{
                      cursor: "pointer",
                      color: "black",
                      fontSize: 16,
                      width: "2vw",
                      marginTop: -0.5,
                      height: 18,
                      "&:focus": {
                        outline: 0,
                      },
                      "&.MuiIconButton-root": {
                        padding: 0,
                        paddingLeft: "5px",
                      },
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
              </Box>
            ))}
            <Box
              sx={{
                marginTop: 2,
                marginLeft: -1,
                "@media (max-width: 1024px)": {
                  marginLeft: 2,
                },
              }}
            >
              {files.length > 0 && (
                /* eslint-disable react/no-unknown-property */
                <audio
                  style={{
                    height: 26,
                    width: 310,
                    marginRight: 4,
                  }}
                  title={row.fileName}
                  onError={handleOnError}
                  controls
                  autobuffer
                  autoPlay
                >
                  <source src={base64String} type="audio/wav" />
                </audio>
                /* eslint-enable react/no-unknown-property */
              )}
            </Box>
          </Box>
        )}
      </Box>
    </section>
  );
};

export default MediaMgtFileUpload;
