const styles = (theme) => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "12%",
    padding: "2%",
  },
  img: {
    textAlign: "center",
  },

  headingPrimary: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: "24px !important",
    color: theme.palette.redAlert.main,
    fontWeight: 600,
  },
  headingSecondary: {
    paddingBottom: 5,
    fontSize: "20px !important",
    "& > div": {
      paddingTop: 40,
    },
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
});

export default styles;
