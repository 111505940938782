import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getResources = createAsyncThunk(
  "resources/getResources",
  async (arg, { getState, rejectWithValue }) => {
    const {
      connectedInstance: {
        instanceId = {},
        appConfig: { resourcesByLob = true },
      } = {},
    } = getState().userInstance;
    const { ccpAgent: { agentRoutingLob = {} } = {} } = getState().ccp;
    try {
      const response = await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/resourceInfo/${instanceId}/${agentRoutingLob}`,
        method: "POST",
        body: { resourcesByLob },
      });
      return response;
    } catch (error) {
      clientLog({
        component: `resources`,
        message: `Something went wrong in getResources API => ${error}`,
      });
      logMessage(
        `Resources`,
        `Something went wrong in getResources API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getResources api endpoint");
    }
  }
);
