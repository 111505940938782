const styles = (theme) => ({
  insideTabContainer: {
    "&.sb-card": {
      borderRadius: 0,
      boxShadow: "none",
    },
  },
  root: {},
});

export default styles;
