import React from "react";
import { useSelector } from "react-redux";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import styles from "../../styles";
import useCss from "../../../../../hooks/useCss";
import { StyledDeleteButton } from "../../styled";
import { getLastUpdated, getDataManagementDateTime } from "../../../utils";
import { getTimezoneSelector } from "../../../../../selectors/settingsSelector";

const DataManagementRow = ({
  row,
  handleEditRowClick,
  handleDeleteRowClick,
  columns,
}) => {
  const classes = useCss(styles);
  const timezone = useSelector(getTimezoneSelector);

  const handleEditClick = () => {
    handleEditRowClick(row);
  };

  const handleDeleteClick = (event) => {
    event.stopPropagation();
    event.preventDefault();
    handleDeleteRowClick(row);
  };

  const getTypeValue = (type) => {
    if (type === "true" || type === true) {
      return "true";
    }
    if (type === "false" || type === false) {
      return "false";
    }
    return type;
  };

  const renderValue = (row, cell) => {
    if (cell?.id === "lastUpdated") {
      return getLastUpdated(row, timezone);
    }

    if (cell?.id === "dateTime") {
      return getDataManagementDateTime(row[cell.id], timezone);
    }

    if (cell?.id !== "action" && cell?.type === "boolean") {
      return getTypeValue(row[cell.id]);
    }

    return row[cell?.id];
  };

  return (
    <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      {columns?.map((cell) => (
        <SbuxStyledTableCell
          key={cell.id}
          className={classes.cellFocus}
          onClick={cell?.id !== "action" ? handleEditClick : undefined}
          sx={{
            ...(cell?.id === "action" && {
              width: "100px",
              maxWidth: "100px",
            }),
          }}
        >
          {cell?.id === "action" ? (
            <StyledDeleteButton
              color="primary"
              onClick={handleDeleteClick}
              disableFocusRipple
              disableRipple
            />
          ) : (
            renderValue(row, cell)
          )}
        </SbuxStyledTableCell>
      ))}
    </SbuxStyledTableRow>
  );
};

export default DataManagementRow;
