import { useState } from "react";
import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxSnackbars from "../../../../components/SbuxSnackbars";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useSelector, useDispatch } from "react-redux";
import {
  getMediaExpandCollapseInfo,
  getMediaFile,
} from "../../../../services/mediaMgt";
import {
  PLEASE_WAIT,
  SNACK_BARS_SEVERITY_SUCCESS,
  SNACK_BARS_SEVERITY_ERROR,
  OOPS_ERROR_MESSAGE,
} from "../../../../constants";
import {
  getIsPendingSelector,
  getIsErrorSelector,
  getMediaExpandCollapseInfoSelector,
} from "../../../../selectors/mediaMgtSelector";

const ExpandableRow = ({
  mediaId,
  mediaObj,
  children,
  expandComponent,
  isHighlighted,
}) => {
  const dispatch = useDispatch();

  const isPending = useSelector(getIsPendingSelector);
  const isError = useSelector(getIsErrorSelector);
  const mediaExpandCollapseInfo = useSelector(
    getMediaExpandCollapseInfoSelector
  );

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const isExpanded = false;
  const handleExpand = async () => {
    if (
      mediaExpandCollapseInfo &&
      mediaId === mediaExpandCollapseInfo.mediaId
    ) {
      !mediaExpandCollapseInfo.isExpanded &&
        (await dispatch(getMediaFile(mediaObj)));
      await dispatch(
        getMediaExpandCollapseInfo({
          mediaId: mediaId,
          isExpanded: !mediaExpandCollapseInfo.isExpanded,
        })
      );
    } else {
      !isExpanded && (await dispatch(getMediaFile(mediaObj)));
      await dispatch(
        getMediaExpandCollapseInfo({
          mediaId: mediaId,
          isExpanded: !isExpanded,
        })
      );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackBar(false);
  };

  return (
    <>
      {openSnackBar && (isPending || isError) && (
        <SbuxSnackbars
          open={openSnackBar}
          message={isPending ? PLEASE_WAIT : isError ? OOPS_ERROR_MESSAGE : ""}
          severity={
            isError ? SNACK_BARS_SEVERITY_ERROR : SNACK_BARS_SEVERITY_SUCCESS
          }
          handleClose={handleClose}
        />
      )}
      <SbuxStyledTableRow isHighlighted={isHighlighted}>
        <SbuxStyledTableCell padding="checkbox">
          <IconButton
            disableFocusRipple
            disableRipple
            onClick={handleExpand}
            sx={{ outline: "0 !important" }}
          >
            {mediaExpandCollapseInfo &&
            mediaExpandCollapseInfo.mediaId === mediaId &&
            mediaExpandCollapseInfo.isExpanded ? (
              <KeyboardArrowDown sx={{ fontSize: 24, marginLeft: -1.5 }} />
            ) : (
              <ChevronRightIcon sx={{ fontSize: 24, marginLeft: -1.5 }} />
            )}
          </IconButton>
        </SbuxStyledTableCell>
        {children}
      </SbuxStyledTableRow>
      {mediaExpandCollapseInfo &&
        mediaExpandCollapseInfo.mediaId === mediaId &&
        mediaExpandCollapseInfo.isExpanded && (
          <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            <SbuxStyledTableCell padding="checkbox" />
            {expandComponent}
          </SbuxStyledTableRow>
        )}
    </>
  );
};

export default ExpandableRow;
