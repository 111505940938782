export const columns = [
  {
    label: " ",
    id: "sno",
    minWidth: "3px",
    isChecked: false,
  },

  {
    label: "Initiation timestamp",
    id: "initiationTimestamp",
    sortable: true,
    minWidth: "1000px",
  },
  {
    label: "Initial ContactId",
    id: "initialContactId",
    sortable: true,
    minWidth: "1000px",
  },
  {
    label: "Contact Id",
    id: "contactId",
    sortable: true,
    minWidth: "1000px",
  },

  {
    label: "Queue Name",
    id: "queueName",
    sortable: true,
    minWidth: "1000px",
  },
];
