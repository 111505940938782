const styles = (theme) => ({
  button: {
    cursor: "pointer",
    backgroundColor: "transparent",
    "&:focus": {
      outline: 0,
    },
    "&.MuiIconButton-root": {
      padding: 0,
      paddingLeft: "5px",
    },
  },
  red: {
    color: theme.palette.redAlert.main,
  },
});

export default styles;
