export const columns = [
  {
    label: "Name",
    id: "name",
    flex: 1,
    sortable: true,
  },
  {
    label: "Description",
    id: "label",
    flex: 1,
    sortable: true,
  },
  {
    label: "Enabled",
    id: "enabled",
    flex: 1,
    height: 20,
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "lastUpdated",
    minWidth: 320,
    sortable: true,
  },
];
