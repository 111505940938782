export const filterList = [
  { name: "agentName", label: "Agent Name", type: "text" },
  { name: "agentUserName", label: "Agent Username", type: "text" },
  { name: "callUUID", label: "Ext ID", type: "text" },
  { name: "connId", label: "Conn ID", type: "text" },
  { name: "ani", label: "Inbound Caller #", type: "text" },
  {
    name: "startTime",
    label: "Start Date/Time",
    type: "datetime",
    required: true,
  },
  { name: "endTime", label: "End Date/Time", type: "datetime" },
  { name: "callDuration", label: "Call Duration", type: "timeDuration" },
].toSorted((a, b) => {
  return a.label < b.label ? -1 : a.label > b.label ? 1 : 0;
});
