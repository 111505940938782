import { createSlice } from "@reduxjs/toolkit";
import {
  getTextToSpeech,
  getResetTextToSpeech,
} from "../services/textToSpeech";

const initialState = {
  audioContent: "",
  textToSpeechStatus: "",
};
const textToSpeechSlice = createSlice({
  name: "textToSpeech",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getTextToSpeech.pending, (state) => {
        state.audioContent = "";
      })
      .addCase(getTextToSpeech.fulfilled, (state, action) => {
        const { payload } = action;
        state.audioContent = payload.data.audioContent;
      })
      .addCase(getTextToSpeech.rejected, (state) => {
        state.audioContent = "";
      })
      .addCase(getResetTextToSpeech, (state) => {
        state.audioContent = "";
      });
  },
});
export default textToSpeechSlice.reducer;
