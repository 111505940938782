import { createSlice } from "@reduxjs/toolkit";
import { getQueue, deleteQueue, getResetQueue } from "../services/queue";

const initialState = {
  queueStatus: "idle",
  queueDeleteStatus: "idle",
  queueLoading: false,
  queueDeleteError: "",
  queueData: [],
};

const queueSlice = createSlice({
  name: "queue",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQueue.pending, (state) => {
        state.queueStatus = "pending";
        state.queueLoading = true;
        state.queueData = [];
      })
      .addCase(getQueue.fulfilled, (state, action) => {
        const { payload } = action;
        state.queueStatus = "success";
        state.queueLoading = false;
        state.queueData = payload.data;
      })
      .addCase(getQueue.rejected, (state) => {
        state.queueStatus = "rejected";
        state.queueLoading = false;
        state.queueData = [];
      })
      .addCase(deleteQueue.pending, (state) => {
        state.queueDeleteStatus = "pending";
      })
      .addCase(deleteQueue.fulfilled, (state, action) => {
        const { payload } = action;
        state.queueDeleteStatus = "deleted";
        state.queueData = payload.data;
      })
      .addCase(deleteQueue.rejected, (state, action) => {
        state.queueDeleteStatus = "rejected";
        state.queueDeleteError = action.meta.response.data.error.message;
      })
      .addCase(getResetQueue, (state) => {
        state.queueDeleteStatus = "Idle";
        state.queueDeleteError = "";
      });
  },
});

export default queueSlice.reducer;
