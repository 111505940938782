import SbuxStyledTableRow from "../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxContentCopy from "../../../../components/SbuxContentCopy";
import {
  isPhoneNumber,
  phoneNumberDetail,
} from "../../../../utils/phoneNumber";
import { ellipse } from "../../../../utils/stringUtil";
import { getHistoryUpdatedTimezone } from "../../utils";
import scroll from "@cxeweb/pattern-library/lib/icons/scroll";
import styles from "../styles";
import SbuxContentButton from "../../../../components/SbuxContentButton";
import { COLORS } from "../../../../constants";
import useCss from "../../../../hooks/useCss";
import { useSelector } from "react-redux";
import { getTimezoneSelector } from "../../../../selectors/settingsSelector";

const MasterRow = ({
  row,
  toggleDialogOpen,
  transcriptBtnClickEvent,
  linkRenderer,
  areTranscriptsEnabledForInstance,
}) => {
  const classes = useCss(styles);
  const timezone = useSelector(getTimezoneSelector);

  return (
    <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
      <SbuxStyledTableCell
        onClick={toggleDialogOpen}
        className={classes.cellFocus}
      >
        {getHistoryUpdatedTimezone(row, timezone)}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell
        onClick={toggleDialogOpen}
        className={classes.cellFocus}
      >
        {row["Method"]}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell
        onClick={toggleDialogOpen}
        className={classes.cellFocus}
      >
        {ellipse(
          row["Queue"],
          process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS
        )}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell
        onClick={toggleDialogOpen}
        className={classes.cellFocus}
      >
        {ellipse(
          row["Ticket or Case #"],
          process.env.REACT_APP_COLUMN_LENGTH_BEFORE_ELLIPSIS
        )}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell>
        {isPhoneNumber(row["Caller ID"]) ? (
          <div>
            {linkRenderer(row["Caller ID"])}
            <SbuxContentCopy
              content={phoneNumberDetail(row["Caller ID"])?.phoneNumber ?? ""}
            />
          </div>
        ) : (
          row["Caller ID"]
        )}
      </SbuxStyledTableCell>
      <SbuxStyledTableCell>
        {isPhoneNumber(row["Dialed #"]) ? (
          <div>
            {linkRenderer(row["Dialed #"])}
            <SbuxContentCopy
              content={phoneNumberDetail(row["Dialed #"])?.phoneNumber ?? ""}
            />
          </div>
        ) : (
          row["Dialed #"]
        )}
      </SbuxStyledTableCell>
      {areTranscriptsEnabledForInstance && (
        <SbuxStyledTableCell>
          <SbuxContentButton
            disabled={!row?.areTranscriptsEnabledForCall}
            handleClick={() => transcriptBtnClickEvent(false)}
            iconName={scroll}
            color={COLORS.PRIMARY}
            iconSize="16px"
          />
          <SbuxContentCopy
            disabled={!row?.areTranscriptsEnabledForCall}
            content={""}
            handleClick={() => transcriptBtnClickEvent(true)}
          />
        </SbuxStyledTableCell>
      )}
    </SbuxStyledTableRow>
  );
};

export default MasterRow;
