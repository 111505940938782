import React from "react";
import { Box, Drawer, Divider } from "@mui/material";
import TabList from "./components/TabList";
import HeaderList from "./components/HeaderList";
import FooterList from "./components/FooterList";
import LanguageList from "./components/LanguageList";
import TimeZoneList from "./components/TimeZoneList";

const SbuxSettings = ({ openSettings, toggleHandleSettings }) => {
  const handleModal = () => {
    toggleHandleSettings();
  };

  return (
    <Drawer
      anchor={"right"}
      open={openSettings}
      PaperProps={{
        sx: {
          minWidth: "25%",
          overflowY: "auto",
        },
      }}
      ModalProps={{
        disableEnforceFocus: true,
        onClose: handleModal,
        sx: {
          overflow: "hidden",
          marginTop: 7.5,
          marginRight: 2,
          marginBottom: 2,
        },
      }}
      variant="temporary"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box>
          <HeaderList handleModal={handleModal} />
        </Box>

        <Divider />

        <Box>
          <LanguageList />
          <TimeZoneList />
          <TabList />
        </Box>

        <Box sx={{ minHeight: "90px" }}>
          <FooterList handleModal={handleModal} />
        </Box>
      </Box>
    </Drawer>
  );
};

export default React.memo(SbuxSettings);
