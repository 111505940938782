const styles = (theme) => ({
  navHeading: {
    display: "flex",
    flexWrap: "wrap",
    marginRight: "15px",
    flexDirection: "row",
    "& > button": {
      width: "auto",
    },
  },
  navTitle: {
    maxWidth: 600,
    fontSize: "1.6rem",
    paddingTop: "4px",
    "@media (max-width:499px)": {
      paddingBottom: 10,
    },
  },
  navCog: {
    marginLeft: 10,
  },
  navButton: {
    flex: 1,
    fontSize: 14,
    height: 33,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    border: "1px solid lightgrey",
    height: "78.8vh",
    // marginTop: "-15%",
  },
  heading: {
    flex: 1,
    // marginTop: "15%",
    marginBottom: "15%",
  },
  leftContent: {
    paddingLeft: "16px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  rightContent: {
    paddingRight: "16px",
    [theme.breakpoints.down("md")]: {
      paddingLeft: "8px",
      paddingRight: "8px",
    },
  },
  toolbarActions: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    flexGrow: 0.7,
    justifyContent: "flex-end",

    "& > .MuiButton-root": {
      width: "100px",
      maxWidth: "100px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    "& > .MuiFormControl-root": {
      width: "250px",
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
      "& > .MuiFormLabel-root": {
        [theme.breakpoints.up("md")]: {
          display: "none",
        },
      },
    },
  },
  drawerMenu: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  drawerContainer: {
    minWidth: 240,
    padding: 20,
    "& > .MuiDivider-root": {
      marginTop: 10,
    },
  },
  drawerContent: {
    "& > div": {
      marginTop: 20,
    },
    "& > h5": {
      marginTop: 20,
    },
    "& > button": {
      marginTop: 20,
    },
  },
  sbuxLogo: {
    height: 51,
    width: 51,
    [theme.breakpoints.down("md")]: {
      height: 40,
      width: 40,
    },
  },
  appName: {
    letterSpacing: "0.1em",
    textTransform: "uppercase",
    fontWeight: "700 !important",
    flexGrow: 1,
    marginLeft: "10px !important",
  },
  recordingButtons: {
    display: "inline-flex",
    float: "left",
    position: "absolute",
    left: 300,
  },
});

export default styles;
