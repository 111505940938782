/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onProcessedClosureMessage = /* GraphQL */ `
  subscription OnProcessedClosureMessage($instanceId: String!) {
    onProcessedClosureMessage(instanceId: $instanceId) {
      instanceId
      id
      type
      hoursCode
      groupName
      category
      occurrences
      repeatPattern
      description
      startDate
      startTime
      startTimeZone
      endDate
      endTimeZone
      endTime
      messages
      status
      lastUpdatedBy
      lastUpdated
      __operation
      __typename
    }
  }
`;
export const onProcessedMedia = /* GraphQL */ `
  subscription OnProcessedMedia($instanceId: String!) {
    onProcessedMedia(instanceId: $instanceId) {
      instanceId
      mediaId
      description
      fileName
      filePath
      language
      languageCode
      promptName
      type
      lastUpdatedBy
      lastUpdated
      __operation
      __typename
    }
  }
`;
export const onProcessedTemporaryMessage = /* GraphQL */ `
  subscription OnProcessedTemporaryMessage($instanceId: String!) {
    onProcessedTemporaryMessage(instanceId: $instanceId) {
      instanceId
      sequenceNumber
      draft
      disabled
      info
      description
      startDate
      startTime
      startTimeZone
      endDate
      endTimeZone
      endTime
      messages
      status
      lastUpdatedBy
      lastUpdated
      __operation
      __typename
    }
  }
`;
