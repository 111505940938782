import { createSelector } from "@reduxjs/toolkit";

// Root selectors

const getConnectState = (state) => state.connect;

// Connect Selectors
export const getConnectDatasetsSelector = createSelector(
  [getConnectState],
  (connect) => connect?.datasets
);

export const getConnectStatusSelector = createSelector(
  [getConnectState],
  (connect) => connect?.status
);

export const getConnectLoadingIndicatorSelector = createSelector(
  [getConnectState],
  (connect) => connect?.showLoadingIndicator
);

export const getConnectErrorMessageSelector = createSelector(
  [getConnectState],
  (connect) => connect?.errorMessage
);
