import { createSlice } from "@reduxjs/toolkit";
import {
  getClosureMsg,
  createOrUpdateClosureMsg,
  deleteClosureMsg,
  updateRepeatOccureneceClosureMsg,
  getClosureMsgMediaFile,
  getResetClosureMsg,
} from "../services/closureMsg";

const initialState = {
  closureMsgStatus: "idle",
  closureMsgDeleteStatus: "idle",
  closureMsgUpdateRepeatOccrStatus: "idle",
  isCMPending: false,
  isCMSave: false,
  isCMDelete: false,
  isCMError: false,
  closureOriginalData: [],
  closureMsgMediaFileInfo: null,
};

const normalizeMessages = (data) => {
  return data.reduce((acc, cur) => {
    if (cur.type === "Repeat" && !!cur.occurrences) {
      let occurencesArray =
        cur.occurrences &&
        Object.keys(cur.occurrences).map((key) => {
          return cur.occurrences[key];
        });

      acc = [
        ...acc, // Anti-pattern [bad-performance]
        ...occurencesArray.map((item) => ({
          ...cur,
          occurrenceId: item.id,
          startDate: item.startDate,
          startTime: item.startTime,
          endDate: item.endDate,
          endTime: item.endTime,
          startTimeZone: item.startTimeZone,
          endTimeZone: item.endTimeZone,
          status: item.status,
          ...(Object.keys(item).length > 0 &&
            Object.keys(item)
              .filter((a) => a.includes("message"))
              .reduce((acc, cur) => ((acc[cur] = item[cur]), acc), {})),
        })),
      ];
    } else {
      acc.push({
        ...cur,
      });
    }

    return acc;
  }, []);
};

const processPayload = (payload) => {
  const clonedPayload = { ...payload };
  delete clonedPayload["__typename"];
  delete clonedPayload["__operation"];
  return clonedPayload;
};

const processMessages = (processedPayload) => {
  const parsedMessages = JSON.parse(processedPayload.messages);

  return {
    message_en: parsedMessages?.en || null,
    message_fr: parsedMessages?.fr || null,
    message_sp: parsedMessages?.sp || null,
    message_zh: parsedMessages?.zh || null,
    message_zhTW: parsedMessages?.["zh-TW"] || null,
  };
};

const updateClosureMsgData = (state, processedPayload) => {
  const newOccurrenceClosureMsgData = normalizeMessages([processedPayload]);
  const excludedClosureData = state.closureMsgData.filter(
    (item) => item.id !== processedPayload.id
  );

  state.closureMsgData = [
    ...excludedClosureData,
    ...newOccurrenceClosureMsgData,
  ];

  state.closureMsgData = state.closureMsgData.map((item) => {
    if (item.id === processedPayload.id) {
      return {
        ...item,
        highLight: true,
        ...processMessages(processedPayload),
      };
    } else {
      return {
        ...item,
        highLight: false,
      };
    }
  });
};

/**
 * Delete operation of a closure message in the store
 * @param  {Object} state                          current state
 * @param  {Object} incomingMessage               received payload representing a series of messages
 */
const deleteClosureMessage = (state, incomingMessage) => {
  // Filter out the series of message to alter
  const otherMessages = state.closureMsgData.filter(
    (message) => message.id !== incomingMessage.id
  );

  /**
   * Number of messages of the repeat pattern being altered by a DELETE op
   * @type {Number}
   */
  const countDiff = state.closureMsgData.length - otherMessages.length;

  if (
    incomingMessage.type === "Repeat" &&
    countDiff > 0 && // Whether any message was matched by the filter
    countDiff != Object.keys(incomingMessage.occurrences).length // Whether an occurrence was deleted
  ) {
    const normalizedMessages = normalizeMessages([
      { ...incomingMessage, ...processMessages(incomingMessage) },
    ]);

    state.closureMsgData = otherMessages.concat(normalizedMessages);
  } else {
    // Delete the entire series OR leave the collection prestine
    state.closureMsgData = otherMessages;
  }
};

const closureMsgSlice = createSlice({
  name: "closureMsg",
  initialState,
  reducers: {
    createCMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      const messages = processMessages(processedPayload);

      state.closureOriginalData.push(processedPayload);
      const tmpOccurrenceClosureMsgData = normalizeMessages([processedPayload]);

      const occurrenceClosureMsgData = tmpOccurrenceClosureMsgData.map(
        (item) => ({
          ...item,
          highLight: true,
          ...messages,
        })
      );

      state.closureMsgData = [
        ...state.closureMsgData,
        ...occurrenceClosureMsgData,
      ];
    },

    updateCMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      updateClosureMsgData(state, processedPayload);
    },

    deleteCMData: (state, { payload }) => {
      const processedPayload = processPayload(payload);
      deleteClosureMessage(state, processedPayload);
    },

    resetHighlight: (state, { payload }) => {
      state.closureMsgData = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClosureMsg.pending, (state) => {
        state.closureMsgStatus = "pending";
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = false;
        state.closureMsgData = [];
      })
      .addCase(getClosureMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.closureMsgStatus = "success";
        state.isCMPending = false;
        state.closureOriginalData = payload.data.data;
        state.closureMsgData = normalizeMessages(payload.data.data);
      })
      .addCase(getClosureMsg.rejected, (state) => {
        state.closureMsgStatus = "rejected";
        state.isCMPending = false;
        state.isCMError = true;
        state.closureMsgData = [];
      })
      .addCase(getClosureMsgMediaFile.pending, (state) => {
        state.closureMsgStatus = "pending";
        state.closureMsgMediaFileInfo = null;
      })
      .addCase(getClosureMsgMediaFile.fulfilled, (state, action) => {
        const { payload } = action;
        state.closureMsgStatus = "success";
        state.closureMsgMediaFileInfo = payload;
      })
      .addCase(getClosureMsgMediaFile.rejected, (state) => {
        state.closureMsgStatus = "rejected";
        state.closureMsgMediaFileInfo = null;
      })
      .addCase(createOrUpdateClosureMsg.pending, (state) => {
        state.isCMPending = true;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = false;
        state.closureMsgStatus = "pending";
      })
      .addCase(createOrUpdateClosureMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.closureMsgStatus = "created";
        state.closureOriginalData = payload.data.data;
        state.closureMsgData = normalizeMessages(payload.data.data);
        state.isCMPending = false;
        state.isCMSave = true;
        state.isCMDelete = false;
        state.isCMError = false;
      })
      .addCase(createOrUpdateClosureMsg.rejected, (state) => {
        state.closureMsgStatus = "rejected";
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = true;
      })
      .addCase(deleteClosureMsg.pending, (state) => {
        state.isCMPending = true;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = false;
        state.closureMsgDeleteStatus = "pending";
      })
      .addCase(deleteClosureMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.closureMsgDeleteStatus = "deleted";
        state.closureOriginalData = payload.data.data;
        state.closureMsgData = normalizeMessages(payload.data.data);
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = true;
        state.isCMError = false;
      })
      .addCase(deleteClosureMsg.rejected, (state) => {
        state.closureMsgDeleteStatus = "rejected";
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = true;
      })
      .addCase(updateRepeatOccureneceClosureMsg.pending, (state) => {
        state.closureMsgUpdateRepeatOccrStatus = "pending";
        state.isCMPending = true;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = false;
      })
      .addCase(updateRepeatOccureneceClosureMsg.fulfilled, (state, action) => {
        const { payload } = action;
        state.closureMsgUpdateRepeatOccrStatus = "created";
        state.closureOriginalData = payload.data.data;
        state.closureMsgData = normalizeMessages(payload.data.data);
        state.isCMPending = false;
        state.isCMSave = true;
        state.isCMDelete = false;
        state.isCMError = false;
      })
      .addCase(updateRepeatOccureneceClosureMsg.rejected, (state) => {
        state.closureMsgUpdateRepeatOccrStatus = "rejected";
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = true;
      })
      .addCase(getResetClosureMsg, (state) => {
        state.closureMsgStatus = "idle";
        state.closureMsgDeleteStatus = "idle";
        state.closureMsgUpdateRepeatOccrStatus = "idle";
        state.isCMPending = false;
        state.isCMSave = false;
        state.isCMDelete = false;
        state.isCMError = false;
      });
  },
});

export const { updateCMData, createCMData, deleteCMData, resetHighlight } =
  closureMsgSlice.actions;
export default closureMsgSlice.reducer;
