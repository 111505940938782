export const masterColumns = [
  {
    label: "#",
    id: "hoursCode",
    minWidth: 3,
    sortable: true,
    flex: 1,
  },
  {
    label: "Name",
    id: "hoursName",
    sortable: true,
    minWidth: 450,
    flex: 1,
  },
  {
    label: "Description",
    id: "description",
    sortable: true,
    minWidth: 450,
    flex: 1,
  },
  {
    label: "Last Updated",
    id: "lastUpdated",
    sortable: true,
    minWidth: 350,
    flex: 1,
  },
];

export const detailColumns = [
  {
    label: "",
    id: "delete",
    flex: 1,
  },
  {
    label: "Day",
    id: "day",
    flex: 1,
    minWidth: 300,
    sortable: true,
  },
  {
    label: "Start",
    id: "start",
    flex: 1,
    minWidth: 300,
    sortable: true,
  },
  {
    label: "End",
    id: "end",
    flex: 1,
    minWidth: 300,
    sortable: true,
  },
  {
    label: "Open 24 Hours",
    id: "isOpenFor24Hours",
    flex: 1,
    minWidth: 400,
    sortable: false,
  },
];
