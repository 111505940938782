import { styled } from "@mui/material/styles";
import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";

export const StyledDrawerButton = styled(({ ...props }) => (
  <IconButton {...props} slots={{ root: "span" }}>
    <MenuIcon sx={{ fontSize: 30 }} />
  </IconButton>
))(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));
