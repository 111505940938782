export const masterColumns = [
  {
    label: "Time",
    id: "timestamp",
    flex: 1,
    minWidth: 160,
    sortable: true,
  },
  {
    label: "Method",
    id: "Method",
    flex: 1,
    minWidth: 80,
    sortable: true,
  },
  {
    label: "Queue",
    id: "Queue",
    flex: 1,
    minWidth: 100,
    sortable: true,
  },
  {
    label: "Ticket or Case #",
    id: "Ticket or Case #",
    flex: 1,
    minWidth: 150,
    sortable: true,
  },
  {
    label: "Caller ID",
    id: "Caller ID",
    flex: 1,
    minWidth: 80,
    sortable: true,
  },
  {
    label: "Dialed #",
    id: "Dialed #",
    flex: 1,
    minWidth: 130,
    sortable: true,
  },
  {
    label: "Transcripts",
    id: "transcripts",
    flex: 1,
    minWidth: 50,
    sortable: false,
  },
];

export const detailColumns = [
  {
    label: "Name",
    id: "name",
    minWidth: 90,
    sortable: false,
  },
  {
    label: "Value",
    id: "value",
    minWidth: 200,
    sortable: false,
  },
];
