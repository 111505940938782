import SbuxRadioGroup from "../../../../../../components/SbuxRadioGroup";
import SbuxSelect from "../../../../../../components/SbuxSelect";
import { Box, FormControlLabel, Radio } from "@mui/material";
import styles from "./styles";
import useCss from "../../../../../../hooks/useCss";

const ClosureMsgMonthlyFrequency = ({
  monthlyOption,
  monthlyRecDayOfList,
  monthlyRecDayOfValue,
  monthlyRecWeekDaysList,
  monthlyRecWeekDays,
  monthlyRecOption1EveryDayInputValue,
  monthlyRecOption1EveryMonthsInputValue,
  monthlyRecOption2EveryMonthsInputValue,
  handleMonthlyOptionsValueChange,
  handleMonthlyRecDayOfValueChange,
  handleMonthlyRecWeekDaysChange,
  handleMonthlyRecOption1EveryDayInputValueChange,
  handleMonthlyRecOption1EveryMonthsInputValueChange,
  handleMonthlyRecOption2EveryMonthsInputValueChange,
}) => {
  const classes = useCss(styles);

  return (
    <SbuxRadioGroup
      value={monthlyOption}
      handleRadioButtonChange={handleMonthlyOptionsValueChange}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"monthlyOption1Day"}
            control={<Radio />}
            label={
              <Box
                className={classes.rowMultiCtrl}
                sx={{
                  pointerEvents:
                    monthlyOption !== "monthlyOption1Day" && "none",
                  opacity: monthlyOption !== "monthlyOption1Day" && 0.6,
                }}
              >
                <Box component="label">{"Day"}</Box>
                <Box>
                  <input
                    type={"number"}
                    value={monthlyRecOption1EveryDayInputValue}
                    style={{
                      width: 40,
                      height: 24,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption1EveryDayInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>

                <Box component="label">{"of every"}</Box>

                <Box>
                  <input
                    type={"number"}
                    value={monthlyRecOption1EveryMonthsInputValue}
                    style={{
                      width: 40,
                      height: 24,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption1EveryMonthsInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>

                <Box component="label">{"month(s)"}</Box>
              </Box>
            }
          />
        </Box>

        <Box sx={{ flex: 1, marginLeft: 3 }}>
          <FormControlLabel
            value={"monthlyOption2The"}
            control={<Radio />}
            label={
              <Box
                className={classes.rowMultiCtrl}
                sx={{
                  pointerEvents:
                    monthlyOption !== "monthlyOption2The" && "none",
                  opacity: monthlyOption !== "monthlyOption2The" && 0.6,
                }}
              >
                <Box component="label">{"The"}</Box>

                <Box sx={{ minWidth: "68px" }}>
                  <SbuxSelect
                    menuItems={monthlyRecDayOfList}
                    defaultValue={monthlyRecDayOfValue}
                    value={monthlyRecDayOfValue}
                    handleSelectChange={(value) =>
                      handleMonthlyRecDayOfValueChange(value)
                    }
                  />
                </Box>

                <Box sx={{ minWidth: "86px" }}>
                  <SbuxSelect
                    menuItems={monthlyRecWeekDaysList}
                    defaultValue={monthlyRecWeekDays}
                    value={monthlyRecWeekDays}
                    handleSelectChange={(value) =>
                      handleMonthlyRecWeekDaysChange(value)
                    }
                  />
                </Box>
                <Box component="label">{"of every"}</Box>
                <Box>
                  <input
                    type={"number"}
                    value={monthlyRecOption2EveryMonthsInputValue}
                    style={{
                      width: 40,
                      height: 24,
                    }}
                    onChange={(event) =>
                      handleMonthlyRecOption2EveryMonthsInputValueChange(
                        event.target.value
                      )
                    }
                  />
                </Box>
                <Box component="label">{"month(s)"}</Box>
              </Box>
            }
          />
        </Box>
      </Box>
    </SbuxRadioGroup>
  );
};

export default ClosureMsgMonthlyFrequency;
