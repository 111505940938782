import { createSelector } from "@reduxjs/toolkit";

// Root selectors
const getFilterState = (state) => state.filter;

export const getClosureMsgFilterItemSelector = createSelector(
  [getFilterState],
  (filter) => filter?.closureMsgFilterItem
);

export const getTemporaryMsgFilterItemSelector = createSelector(
  [getFilterState],
  (filter) => filter?.temporaryMsgFilterItem
);

export const getMediaMgtFilterItemSelector = createSelector(
  [getFilterState],
  (filter) => filter?.mediaMgtFilterItem
);

export const getQuickConnectsFilterItemSelector = createSelector(
  [getFilterState],
  (filter) => filter?.quickConnectsFilterItem
);

export const getQuickConnectsResetSelector = createSelector(
  [getFilterState],
  (filter) => filter?.quickConnectsReset
);

export const getClosureMsgResetSelector = createSelector(
  [getFilterState],
  (filter) => filter?.closureMsgReset
);

export const getTemporaryMsgResetSelector = createSelector(
  [getFilterState],
  (filter) => filter?.temporaryMsgReset
);

export const getMediaMgtResetSelector = createSelector(
  [getFilterState],
  (filter) => filter?.mediaMgtReset
);
