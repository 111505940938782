import { createSlice } from "@reduxjs/toolkit";
import { getResources } from "../services/resources";

const initialState = {
  resourceStatus: "idle",
  showLoadingIndicator: false,
  resourcesData: [],
};

const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state) => {
        state.resourceStatus = "pending";
        state.showLoadingIndicator = true;
        state.resourcesData = [];
      })
      .addCase(getResources.fulfilled, (state, action) => {
        const { payload } = action;
        state.resourceStatus = "success";
        state.showLoadingIndicator = false;
        state.resourcesData = payload && payload.data ? payload.data : [];
      })
      .addCase(getResources.rejected, (state, action) => {
        state.resourceStatus = "rejected";
        state.showLoadingIndicator = false;
        state.resourcesData = [];
      });
  },
});

export default resourcesSlice.reducer;
