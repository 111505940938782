import { createRoot } from "react-dom/client";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import "@cxeweb/pattern-library/lib/styles/index.css";
import "react-datepicker/dist/react-datepicker.css";

const container = document.querySelector("#root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <CssBaseline />
    <App />
  </Provider>
);
