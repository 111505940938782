// https://m2.material.io/inline-tools/color/

export const primary = {
  main: "#006241", // 800
  900: "#004728",
  800: "#006241",
  700: "#09724e",
  600: "#0e815c",
  500: "#128e67",
  400: "#2e9f7b",
  300: "#50b090",
  200: "#80c7af",
  100: "#b1dcce",
  50: "#dff1ec",
};

export const secondary = {
  main: "#e6f0ed", // 50
  900: "#204833",
  800: "#2c644b",
  700: "#337358",
  600: "#398366",
  500: "#3f9071",
  400: "#4ea083",
  300: "#67b197",
  200: "#90c7b4",
  100: "#bcdcd1",
  50: "#e6f0ed",
};

export const complementary = {
  main: "#b51d27", // 900
  900: "#b51d27",
  800: "#c42832",
  700: "#d12f3a",
  600: "#e33840",
  500: "#f34142",
  400: "#ed5359",
  300: "#e2737a",
  200: "#ec9a9f",
  100: "#fdcdd5",
  50: "#feebef",
};

export const darkGray = {
  main: "#4a4a4a", // 800
  900: "#282828",
  800: "#4a4a4a",
  700: "#6a6a6a",
  600: "#7e7e7e",
  500: "#a8a8a8",
  400: "#c6c6c6",
  300: "#e8e8e8",
  200: "#f2f2f2",
  100: "#f6f6f6",
  50: "#fbfbfb",
};

export const redAlert = {
  main: "#d23e3e", // 700
  900: "#b52f2d",
  800: "#c53837",
  700: "#d23e3e",
  600: "#e44745",
  500: "#f34f46",
  400: "#ee5e5d",
  300: "#e37b7d",
  200: "#ed9fa1",
  100: "#fdd0d6",
  50: "#feecef",
};

export const black = {
  main: "#000000de",
  border: "#0000003b",
  900: "#000000",
  800: "#262626",
  700: "#434343",
  600: "#555555",
  500: "#7b7b7b",
  400: "#9d9d9d",
  300: "#c4c4c4",
  200: "#d9d9d9",
  100: "#e9e9e9",
  50: "#f5f5f5",
};

export const white = {
  main: "#fff",
  neutral: "#f2f0eb",
};

export const appBarBoxShadow = {
  tp0: "1a",
  tp1: "0f",
  tp2: "12",
};
