import { styled } from "@mui/material/styles";
import TableSortLabel, {
  tableSortLabelClasses,
} from "@mui/material/TableSortLabel";

const SbuxStyledTabelSortLabel = styled(TableSortLabel)(() => ({
  [`&.${tableSortLabelClasses.root}`]: {
    color: "#f2f0eb",
  },
  [`&.${tableSortLabelClasses.active}`]: {
    color: "#f2f0eb",
  },
  [`&.${tableSortLabelClasses.icon}`]: {
    color: "#f2f0eb",
  },
  "& .MuiTableSortLabel-icon": {
    color: "#f2f0eb !important"
  }
}));

export default SbuxStyledTabelSortLabel;
