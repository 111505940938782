const styles = (theme) => ({
  container: {
    paddingRight: "5px",
    paddingLeft: "5px",
    flexWrap: "wrap",
    marginTop: "0px",
  },
});

export default styles;
