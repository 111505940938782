import { useState, useEffect } from "react";
import SbuxEnhancedTable from "../../../../components/SbuxTable/SbuxEnhancedTable";
import { TRANSCRIPTS_COLUMN_NAME } from "../../../../constants";
import Row from "../common/Row";
import { masterColumns } from "./columns";

const MasterTable = ({
  historyData,
  handleRefrehClick,
  areTranscriptsEnabledForInstance,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 100);
  }, []);

  const columns = areTranscriptsEnabledForInstance
    ? masterColumns
    : masterColumns.filter(
        (masterColumn) => ![TRANSCRIPTS_COLUMN_NAME].includes(masterColumn.id)
      );

  return (
    <SbuxEnhancedTable
      rows={historyData}
      columns={columns}
      groupColumns={null}
      defaultSortingField={"timestamp"}
      RowSource={Row}
      handleRefreshClick={handleRefrehClick}
      isLoading={isLoading}
    />
  );
};

export default MasterTable;
