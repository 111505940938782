import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

const handleRequest = async (request, rejectWithValue) => {
  try {
    const response = await request();
    return response;
  } catch (error) {
    clientLog({
      component: "stopContact",
      message: `Something went wrong => ${error}`,
    });
    logMessage(
      "Stop Contact Callback",
      `Something went wrong => ${error}`,
      "error"
    );
    return rejectWithValue(`Error: ${error.message}`);
  }
};

const getStopContact = async (instanceId, rejectWithValue) => {
  const request = async () => {
    return awsClientGateway({
      endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
      pathTemplate: `/stopContact/${instanceId}`,
      method: "GET",
    });
  };
  return await handleRequest(request, rejectWithValue);
};

export const getStopContactData = createAsyncThunk(
  "stopContact/getStopContact",
  async (args, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    return await getStopContact(instanceId, rejectWithValue);
  }
);

export const deleteStopContact = createAsyncThunk(
  "/stopContact/deleteStopContact",
  async (deleteAttribute, { getState, rejectWithValue }) => {
    const { connectedInstance: { instanceId = {} } = {} } =
      getState().userInstance;
    const request = async () => {
      await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/stopContact/${instanceId}`,
        method: "POST",
        body: { records: deleteAttribute },
      });
      return await getStopContact(instanceId, rejectWithValue);
    };
    return await handleRequest(request, rejectWithValue);
  }
);
