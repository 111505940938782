import { useSelector, useDispatch } from "react-redux";
import SbuxSelect from "../../SbuxSelect";
import { useState } from "react";
import { getSelectedTimezone } from "../../../services/settings";
import { Box } from "@mui/material";
import { Divider } from "@mui/material";
import { getTimezonesSelector } from "../../../selectors/globalConfigSelectors";
import { getTimezoneSelector } from "../../../selectors/settingsSelector";

const TimeZoneList = () => {
  const dispatch = useDispatch();

  const timezones = useSelector(getTimezonesSelector);
  const timezone = useSelector(getTimezoneSelector);

  const [timeZoneValue, setTimeZoneValue] = useState(timezone);
  const handleTimeZoneChange = async (timezone) => {
    setTimeZoneValue(timezone);
    await dispatch(getSelectedTimezone(timezone));
  };
  return (
    <Box
      sx={{
        paddingLeft: 2,
        paddingTop: 0.5,
        paddingBottom: 0.5,
        paddingRight: 1,
      }}
    >
      <Box>
        <SbuxSelect
          label="Timezone"
          menuItems={timezones}
          defaultValue={timeZoneValue}
          value={timeZoneValue}
          handleSelectChange={(timezone) => handleTimeZoneChange(timezone)}
        />
      </Box>

      <Divider sx={{ marginTop: 1 }} />
    </Box>
  );
};
export default TimeZoneList;
