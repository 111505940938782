import { styled } from "@mui/material/styles";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircleIcon from "@mui/icons-material/Circle";
import { Box, Divider } from "@mui/material";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-of-type)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&.MuiAccordion-root": {
    border: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    sx={{ padding: 0, maxHeight: "32px", minHeight: "32px" }}
    expandIcon={
      <ExpandMoreIcon
        sx={{ fontSize: "2.4rem", transform: "rotate(270deg)" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const SbuxCollapsiblePanel = ({ panelData }) => {
  return (
    <Box>
      {panelData &&
        panelData.map((panel, index) => {
          return (
            <div key={index}>
              <Accordion>
                <AccordionSummary
                  aria-controls={`${panel.id}-content`}
                  id={`${panel.id}-header`}
                >
                  <CircleIcon
                    sx={{
                      color: "rgb(0, 98, 65)",
                      fontSize: 14,
                      marginTop: 0.9,
                      marginLeft: -1,
                      marginRight: 0.8,
                      marginBottom: 0.5,
                    }}
                  />
                  <span style={{ fontSize: 18, paddingBottom: 1 }}>
                    {panel.name}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  {panel.panels.length > 0 &&
                    panel.panels.map((item, index) => (
                      <Box
                        sx={{
                          flex: 1,
                          fontSize: 14,
                          marginLeft: 4,
                          marginTop: -1,
                          marginBottom: -1.8,
                          paddingTop: 0.5,
                          paddingBottom:
                            panel.panels.length - 1 !== index ? 2 : 0,
                        }}
                        key={index}
                      >
                        {item.url}
                      </Box>
                    ))}
                </AccordionDetails>
              </Accordion>
              <Divider />
            </div>
          );
        })}
    </Box>
  );
};

export default SbuxCollapsiblePanel;
