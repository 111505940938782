import { createSlice } from "@reduxjs/toolkit";
import {
  updateCurrentCtr,
  getTicketOrCaseNumberDefault,
  getTicketOrCaseNumber,
  saveContactToHistoryAction,
  setTicketValidation,
  resetState,
} from "../services/current";

const initialState = {
  status: "idle",
  ticketOrCaseNumberDefault: null,
  ticketOrCaseNumber: null,
  saveContact: {
    history: false,
    historyData: null,
  },
  ticketOrCaseNumberIsValid: true,
  ticketOrCaseNumberPersisted: null,
};

const currentSlice = createSlice({
  name: "current",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCurrentCtr.pending, (state) => {
        state.status = "pending";
        state.ticketOrCaseNumberPersisted = null;
      })
      .addCase(updateCurrentCtr.fulfilled, (state, action) => {
        state.status = "success";

        state.ticketOrCaseNumberDefault = null;
        state.ticketOrCaseNumber = null;

        const {
          meta: {
            arg: { ticketOrCaseNumberValue },
          },
        } = action;
        state.ticketOrCaseNumberPersisted = ticketOrCaseNumberValue;
      })
      .addCase(updateCurrentCtr.rejected, (state) => {
        state.status = "rejected";
        state.ticketOrCaseNumberDefault = null;
        state.ticketOrCaseNumber = null;
        state.ticketOrCaseNumberPersisted = null;
      })
      .addCase(getTicketOrCaseNumberDefault, (state, action) => {
        const { payload } = action;
        state.ticketOrCaseNumberDefault = payload;
      })
      .addCase(getTicketOrCaseNumber, (state, action) => {
        const { payload } = action;
        state.ticketOrCaseNumber = payload;
      })
      .addCase(saveContactToHistoryAction, (state, { payload }) => {
        state.saveContact.history = payload?.history ?? false;
        state.saveContact.historyData = payload?.historyData ?? null;
      })
      .addCase(setTicketValidation, (state, action) => {
        const { payload } = action;

        if (state.ticketOrCaseNumberIsValid !== payload) {
          state.ticketOrCaseNumberIsValid = payload;
        }
      })
      .addCase(resetState, (state, action) => {
        const { payload: { defaultValue = null } } = action;

        state.status = "idle";
        state.ticketOrCaseNumberIsValid = true;
        state.ticketOrCaseNumberPersisted = null;
        state.ticketOrCaseNumberDefault = defaultValue;
        state.ticketOrCaseNumber = null;
      });
  },
});

export default currentSlice.reducer;
