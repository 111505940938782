/**
 * Makiung sure data is sent to the backend with the expected attributes
 * @param  {Object} data               contact event object
 * @return {object}      mapped object
 */
export const mapContactEvent = (data) => {
  return {
    contactId: data.contactId,
    queueName: data.queueName,
    initialContactId: data.initialContactId,
    queueArn: data.queueArn,
    initiationTimestamp: data.initiationTimestamp,
    instanceId: data.instanceId,
    eventType: data.eventType,
    initiationMethod: data.initiationMethod,
    ttlExpiryTime: data.ttlExpiryTime,
    channel: data.channel,
    previousContactId: data.previousContactId,
  };
};
