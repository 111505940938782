import { forwardRef } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SbuxSnackbars = ({
  open,
  severity,
  message,
  handleClose,
  anchorOriginVertical = "top",
  anchorOriginHorizontal = "right",
  width = "100%",
  top = "12%",
  right = "2%",
  left = null,
  autoHide = true,
  position = null,
  height = null,
}) => {
  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        sx={{
          "&.MuiSnackbar-root": {
            top: top,
            right: right,
            left: left,
            position: position,
            height: height,
          },
        }}
        open={open}
        anchorOrigin={{
          vertical: anchorOriginVertical,
          horizontal: anchorOriginHorizontal,
        }}
        autoHideDuration={autoHide ? 6000 : null}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={severity}
          sx={{ width: width, fontSize: 14 }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default SbuxSnackbars;
