import { useEffect, useCallback } from "react";
import SbuxLinkListColumn from "../../components/SbuxLinkListColumn";
import SbuxLoader from "../../components/SbuxLoader";
import SbuxDialog2 from "../../components/SbuxDialog2";
import { useDispatch, useSelector } from "react-redux";
import { getUserInstance, getConnectedInstance } from "../../services/instance";
import classNames from "classnames";
import styles from "./styles";
import useCss from "../../hooks/useCss";
import { ReactComponent as SbuxLogo } from "../../assets/image.svg";
import {
  Box,
  DialogContent,
  Typography,
} from "@mui/material";
import { getCognitoUserIdSelector } from "../../selectors/authSelector";
import {
  selectUserInstances,
  selectUserShowModal,
  selectUserStatus,
} from "../../selectors/userInstanceSelector";

const SbuxInstance = () => {
  const dispatch = useDispatch();
  const classes = useCss(styles);

  const cognitoUserId = useSelector(getCognitoUserIdSelector);
  const instances = useSelector(selectUserInstances);
  const showModal = useSelector(selectUserShowModal);
  const status = useSelector(selectUserStatus);
  useEffect(() => {
    if (cognitoUserId) {
      (async () => {
        await dispatch(getUserInstance(cognitoUserId));
      })();
    }
  }, [cognitoUserId]);

  const handleDialog = useCallback(
    async (instance) => {
      await dispatch(getConnectedInstance(instance));
    },
    [dispatch]
  );

  const createLinks = () => {
    if (!instances || instances.length === 0) {
      return [];
    }

    const sortedInstances = [...instances].sort((a, b) =>
      a.friendlyName.localeCompare(b.friendlyName)
    );
    return sortedInstances.reduce((acc, cur) => {
      acc.push({
        text: cur.friendlyName,
        onClick: () => handleDialog(cur),
        tagName: "button",
      });
      return acc;
    }, []);
  };

  const dialogConfig = {
    hasBackdrop: false,
    asModal: true,
    noCloseButton: true,
    fullWidth: false,
  };

  return (
    <>
      {status !== "success" && status !== "rejected" ? (
        <Box className={classes.loading}>
          <SbuxLoader />
        </Box>
      ) : (
        status === "success" && (
          <SbuxDialog2 open={showModal} config={dialogConfig}>
            <DialogContent>
              <SbuxLogo className={classes.sbuxLogo} />

              <Typography variant="h4">{`Available Instances`}</Typography>

              <Typography variant="h5">{`Please make a selection`}</Typography>

              <Box>
                <SbuxLinkListColumn
                  linkColumnStyle={classes.linkColumn}
                  linkStyle={classNames(
                    classes.linkButton,
                    classes["&.button:focus"]
                  )}
                  links={createLinks()}
                />
              </Box>
            </DialogContent>
          </SbuxDialog2>
        )
      )}
    </>
  );
};

export default SbuxInstance;
