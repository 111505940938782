import { createSelector } from "@reduxjs/toolkit";

// Base selectors
const getCurrentState = (state) => state.current;

export const getTicketOrCaseNumberSelector = createSelector(
  [getCurrentState],
  (current) => current?.ticketOrCaseNumber
);

export const getTicketOrCaseNumberIsValidSelector = createSelector(
  [getCurrentState],
  (current) => current?.ticketOrCaseNumberIsValid
);

export const getTicketOrCaseNumberDefaultSelector = createSelector(
  [getCurrentState],
  (current) => current?.ticketOrCaseNumberDefault
);
export const getTicketOrCaseNumberPersistedSelector = createSelector(
  [getCurrentState],
  (current) => current?.ticketOrCaseNumberPersisted
);

export const getSaveContactHistorySelector = createSelector(
  [getCurrentState],
  (current) => current?.saveContact?.history
);

export const getSaveContactHistoryDataSelector = createSelector(
  [getCurrentState],
  (current) => current?.saveContact?.historyData
);
