export const columns = [
  {
    label: "#",
    id: "sequenceNumber",
    minWidth: 3,
    sortable: true,
  },
  {
    label: "Description",
    id: "description",
    minWidth: 500,
    sortable: true,
  },

  {
    label: "Start Date",
    id: "startDate",
    sortable: true,
  },
  {
    label: "Start Time",
    id: "startTime",
    sortable: true,
  },
  {
    label: "End Date",
    id: "endDate",
    sortable: true,
  },
  {
    label: "End Time",
    id: "endTime",
    sortable: true,
  },
  {
    label: "Status",
    id: "status",
    sortable: true,
  },
  {
    label: "Last Updated",
    id: "lastUpdated",
    sortable: true,
  },
];

export const temporaryColumns = [
  {
    label: "Name",
    id: "name",
    minWidth: 100,
    sortable: false,
  },
  {
    label: "Value",
    id: "value",
    minWidth: 500,
    sortable: false,
  },
];

export const filterColumns = [
  {
    id: 0,
    key: "All",
    value: "all",
  },
  {
    id: 1,
    key: "Draft",
    value: "Draft",
  },
  {
    id: 2,
    key: "Scheduled",
    value: "Scheduled",
  },
  {
    id: 3,
    key: "Active",
    value: "Active",
  },
  {
    id: 4,
    key: "Disabled",
    value: "Disabled",
  },
  {
    id: 5,
    key: "Expired",
    value: "Expired",
  },
];
