const styles = (theme) => ({
  select: {
    textTransform: "none",
    paddingBottom: 4,
    marginRight: 4,
    "& .MuiSvgIcon-root": {
      fontSize: "3rem",
      color: `${theme.palette.primary.main}`,
      right: 1,
      paddingBottom: 2,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: `${theme.palette.primary.main} !important`,
    },

    "& .MuiInputBase-input": {
      borderRadius: 4,
      fontSize: 13,
      padding: "12px 26px 3px 12px",
      "&:focus": {
        borderRadius: 4,
      },
    },
  },
  menuItem: {
    height: 26,
    "&.MuiMenuItem-root": {
      fontSize: 14,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
      fontWeight: 400,
    },
    "&.MuiMenuItem-root.Mui-selected": {
      backgroundColor: `${theme.palette.primary.main}`,
      color: "#f9f9f9",
      "&:hover": {
        backgroundColor: `${theme.palette.primary.main}`,
      },
    },
  },
});

export default styles;
