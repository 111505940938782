import { createSelector } from "@reduxjs/toolkit";

const getCcpState = (state) => state.ccp;

export const getCcpValidationButtonStatusSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpValidationButtonStatus
);

export const getCcpContactStatusSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpContactStatus
);

export const getCcpContactMethodSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpContactMethod
);
export const getCcpContactIdSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpContact?.contactId
);

export const getCcpContactAttributesSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpContact?.contactAttributes
);

export const getCcpInitializedSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.isCcpInitialized
);
export const getCcpStatusSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.status
);
export const getCcpEndpointsSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpAgent?.endpoints
);
export const getCcpQueuesSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpAgent?.queues
);

export const getCcpAgentRoutingQueuesSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpAgent?.agentRoutingQueues
);

export const getCcpAgentRoutingNameSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpAgent?.agentRoutingName
);

export const getAccessTokenSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpCredentials?.AccessToken
);

// Selector for AccessTokenExpiration
export const getAccessTokenExpirationSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpCredentials?.AccessTokenExpiration
);

// Selector for RefreshToken
export const getRefreshTokenSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpCredentials?.RefreshToken
);

// Selector for RefreshTokenExpiration
export const getRefreshTokenExpirationSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpCredentials?.RefreshTokenExpiration
);

export const getCcpContactDataSelector = createSelector(
  [getCcpState],
  (ccp) => ccp?.ccpContact?.contactData
);
