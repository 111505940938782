import { createSlice } from "@reduxjs/toolkit";
import { getMetricsQueue, getMyMetricsData } from "../services/metrics";

const initialState = {
  status: "idle",
  showLoadingIndicator: false,
  queueMetrics: [],
  myMetricsData: [],
};

const metricsSlice = createSlice({
  name: "queueMetrics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMetricsQueue.pending, (state) => {
        state.status = "pending";
        state.showLoadingIndicator =
          state.queueMetrics.length > 0 ? false : true;
      })
      .addCase(getMetricsQueue.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.status = "success";
        state.queueMetrics = data;
        state.showLoadingIndicator = false;
      })
      .addCase(getMetricsQueue.rejected, (state) => {
        state.status = "rejected";
        state.showLoadingIndicator = false;
        state.queueMetrics = [];
      })
      .addCase(getMyMetricsData.pending, (state) => {
        state.status = "pending";
        state.showLoadingIndicator =
          state.myMetricsData.length > 0 ? false : true;
      })
      .addCase(getMyMetricsData.fulfilled, (state, action) => {
        const {
          payload: { data },
        } = action;
        state.status = "success";
        state.myMetricsData = data;
        state.showLoadingIndicator = false;
      })
      .addCase(getMyMetricsData.rejected, (state) => {
        state.status = "rejected";
        state.showLoadingIndicator = false;
        state.myMetricsData = [];
      });
  },
});

export default metricsSlice.reducer;
