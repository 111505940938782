import { useState } from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import SbuxTranslate from "../SbuxTranslate";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import styles from "./styles";
import useCss from "../../hooks/useCss";

const SbuxButtonGroup = ({ buttonList, value, handleButtonToggle, height }) => {
  const classes = useCss(styles);
  const [buttonValue, setButtonValue] = useState(value);

  const handleToggle = (event, newValue, isDisabled) => {
    if (isDisabled === undefined || isDisabled === false) {
      handleButtonToggle(newValue);
      setButtonValue(newValue);
    }
  };

  const CustomWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 200,
    },
  });

  return (
    <ToggleButtonGroup
      sx={{ display: "flex", width: "40%" }}
      value={buttonValue}
      exclusive
    >
      {buttonList &&
        buttonList.length > 0 &&
        buttonList.map((item, index) => {
          const styles = [classes.toggleButton];
          if (buttonValue === item.value) {
            styles.push(classes.active);
          }
          if (item.disabled) {
            styles.push(classes.disabled);
          }

          return (
            <CustomWidthTooltip
              title={
                item.disabled ? (
                  <span style={{ fontSize: 14 }}>{item.toolTipMsg}</span>
                ) : (
                  ""
                )
              }
              placement="right"
              key={index}
            >
              <ToggleButton
                onChange={(event, newValue) =>
                  handleToggle(event, newValue, item.disabled)
                }
                value={item.value}
                size="small"
                key={index}
                className={styles.join(" ")}
              >
                <SbuxTranslate>{item.name}</SbuxTranslate>
              </ToggleButton>
            </CustomWidthTooltip>
          );
        })}
    </ToggleButtonGroup>
  );
};

export default SbuxButtonGroup;
