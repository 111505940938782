const styles = (theme) => ({
  root: {
    height: "calc(74vh)",
  },
  dataGridRoot: {
    "& .MuiDataGrid-iconSeparator": {
      // visibility: "hidden",
    },
    display: "flex",
    border: "0 !important",
    paddingTop: "5px",
  },
  sbuxLogo: {
    height: 120,
    width: 120,
    maxWidth: 120,
    maxHeight: 120,
    [theme.breakpoints.down("md")]: {
      height: "auto",
      width: "auto",
    },
  },
});

export default styles;
