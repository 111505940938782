import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import { getLastUpdated } from "../../../utils";
import { ellipse } from "../../../../../utils/stringUtil";
import styles from "../../styles";
import useCss from "../../../../../hooks/useCss";
import { useSelector } from "react-redux";
import { getTimezoneSelector } from "../../../../../selectors/settingsSelector";

const MainHoursMasterRow = ({ row, handleEditRowClick }) => {
  const classes = useCss(styles);
  const timezone = useSelector(getTimezoneSelector);

  const handleEditClick = () => {
    handleEditRowClick(row);
  };

  return (
    <>
      <SbuxStyledTableRow
        // sx={{ "& > *": { borderBottom: "unset" } }}
        onClick={handleEditClick}
      >
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["hoursCode"]}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {ellipse(row["hoursName"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {ellipse(row["description"])}
        </SbuxStyledTableCell>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {getLastUpdated(row, timezone)}
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>
    </>
  );
};

export default MainHoursMasterRow;
