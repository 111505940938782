import { createAsyncThunk } from "@reduxjs/toolkit";
import { awsClientGateway } from "./common/gateway";
import { logMessage } from "../utils/amplifyLogger";
import { clientLog } from "./logging";

export const getGlobalConfig = createAsyncThunk(
  "globalConfig/getGlobalConfig",
  async (args, { getState, rejectWithValue }) => {
    try {
      return await awsClientGateway({
        endpoint: process.env.REACT_APP_BACKEND_API_ENDPOINT,
        pathTemplate: `/globalConfig`,
        method: "GET",
      });
    } catch (error) {
      clientLog({
        component: `globalConfig`,
        message: `Something went wrong in getGlobalConfig API => ${error}`,
      });
      logMessage(
        `globalConfig API`,
        `Something went wrong in getGlobalConfig API => ${error}`,
        `error`
      );
      return rejectWithValue("Error in getGlobalConfig api endpoint");
    }
  }
);
