import {
  CONTACT_NEW_ATTRIBUTE_ORDER_ID,
  CONTACT_HIDE_ATTRIBUTES,
} from "../../../constants";
import { predicateBy, getTemplateData } from "../utils";
import { today } from "../../../utils/dateUtil";

export const getDataGridRows = (
  contactAttributes,
  timeStampTranslatedText,
  contactAttributeOrdering
) => {
  let attributes = [],
    newAttributeId = CONTACT_NEW_ATTRIBUTE_ORDER_ID;

  attributes.push({
    id: 0,
    name: timeStampTranslatedText,
    value: today("YYYY-MM-DD hh:mm A z"),
  });

  for (let contactName in contactAttributes) {
    const templateData = getTemplateData(contactName, contactAttributeOrdering);
    const isContactNameStartWithVar = contactName
      .toLowerCase()
      .includes("var_");
    if (!isContactNameStartWithVar) {
      templateData
        ? attributes.push({
            id: templateData.id,
            name:
              templateData.name === "ContactID"
                ? "Contact ID"
                : templateData.name,
            ...contactAttributes[contactName],
          })
        : !CONTACT_HIDE_ATTRIBUTES.includes(contactName) &&
          attributes.push({
            id: newAttributeId++,
            name: contactName,
            ...contactAttributes[contactName],
          });
    }
  }

  attributes = attributes.sort(predicateBy("id"));
  return attributes;
};
