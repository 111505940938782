import { useState } from "react";
import SbuxStyledTableCell from "../../../../../components/SbuxTable/SbuxStyledTableCell";
import SbuxStyledTableRow from "../../../../../components/SbuxTable/SbuxStyledTableRow";
import SbuxTranslate from "../../../../../components/SbuxTranslate";
import QueueDeleDialog from "./components/QueueDeleteDialog";
import styles from "../../styles";
import useCss from "../../../../../hooks/useCss";
import { StyledDeleteButton } from "../../styled";

const QueueRow = ({ row }) => {
  const classes = useCss(styles);

  const [isDeleteOpen, setIsDeleteOpen] = useState(false);

  const handleDelete = () => {
    setIsDeleteOpen((prevState) => !prevState);
  };

  return (
    <>
      <SbuxStyledTableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <SbuxStyledTableCell className={classes.cellFocus}>
          {row["Name"]}
        </SbuxStyledTableCell>

        <SbuxStyledTableCell sx={{ padding: 0 }}>
          <StyledDeleteButton
            disableFocusRipple
            disableRipple
            onClick={handleDelete}
          />
        </SbuxStyledTableCell>
      </SbuxStyledTableRow>

      {isDeleteOpen && (
        <QueueDeleDialog
          row={row}
          title={<SbuxTranslate>{`Delete Queue`}</SbuxTranslate>}
          name={row.Name}
          open={isDeleteOpen}
          toggleOpen={handleDelete}
        />
      )}
    </>
  );
};

export default QueueRow;
