import { createSlice } from "@reduxjs/toolkit";
import {
  getGenesysRecordings,
  getResetGenesysRecordings,
  getGenesysRecordingsVideo,
  getGenesysRecordingsAudio,
} from "../services/genesysRecordings";

const initialState = {
  genesysRecordingsStatus: "idle",
  isQMPending: false,
  isQMError: false,
  videoMediaContent: "",
  audioMediaContent: "",
  genesysRecordingsData: {},
  pageKeyTrail: [],
};

const genesysRecordingsSlice = createSlice({
  name: "genesysRecordings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGenesysRecordings.pending, (state) => {
        state.genesysRecordingsStatus = "pending";
        state.isQMPending = true;
        state.isQMError = false;
        // state.genesysRecordingsData = {};
      })
      .addCase(getGenesysRecordings.fulfilled, (state, action) => {
        const { payload } = action;
        state.genesysRecordingsStatus = "success";
        state.isQMPending = false;
        state.isQMError = false;

        const {
          currentEvaluatedKey,
          lastEvaluatedKey,
          pageNumber = 0,
        } = payload.data;

        if (!state.pageKeyTrail[pageNumber]) {
          state.pageKeyTrail[pageNumber] = !!lastEvaluatedKey
            ? { currentEvaluatedKey: lastEvaluatedKey }
            : lastEvaluatedKey;
        }

        if (!state.pageKeyTrail[+pageNumber + 1] && !!currentEvaluatedKey) {
          state.pageKeyTrail[+pageNumber + 1] = { currentEvaluatedKey };
        }

        state.genesysRecordingsData = payload.data;
      })
      .addCase(getGenesysRecordings.rejected, (state) => {
        state.genesysRecordingsStatus = "rejected";
        state.isQMPending = false;
        state.isQMError = true;
        state.genesysRecordingsData = {};
        state.pageKeyTrail = [];
      })
      .addCase(getGenesysRecordingsVideo.pending, (state) => {
        state.videoMediaContent = "";
        state.isQMPending = true;
        state.isQMError = false;
      })
      .addCase(getGenesysRecordingsVideo.fulfilled, (state, action) => {
        const { payload } = action;
        state.videoMediaContent = payload.data;
        state.isQMPending = false;
      })
      .addCase(getGenesysRecordingsVideo.rejected, (state) => {
        state.videoMediaContent = "";
        state.isQMPending = false;
        state.isQMError = true;
      })

      .addCase(getGenesysRecordingsAudio.pending, (state) => {
        state.audioMediaContent = "";
        state.isQMPending = true;
        state.isQMError = false;
      })
      .addCase(getGenesysRecordingsAudio.fulfilled, (state, action) => {
        const { payload } = action;
        state.audioMediaContent = payload.data;
        state.isQMPending = false;
        state.isQMError = false;
      })
      .addCase(getGenesysRecordingsAudio.rejected, (state) => {
        state.audioMediaContent = "";
        state.isQMPending = false;
        state.isQMError = true;
      })

      .addCase(getResetGenesysRecordings, (state) => {
        state.genesysRecordingsStatus = "idle";
        state.isQMPending = false;
        state.isQMError = false;
        state.genesysRecordingsData = {};
        state.pageKeyTrail = [];
      });
  },
});

export default genesysRecordingsSlice.reducer;
