import { useState, useEffect } from "react";
import { getDurationForSeconds } from "../../utils/numberUtil";
import {
  WINDOW_NOTIFICATION_TITLE,
  WINDOW_NOTIFICATION_BODY,
} from "../../constants";
import useTextTranslate from "../../features/content/hooks/useTextTranslate";
import { logMessage } from "../../utils/amplifyLogger";

const useNotification = (open, warningTime, handleOnAction) => {
  const [notification, setNotification] = useState(null);
  const windowNotficationBodyTranslate = useTextTranslate(
    WINDOW_NOTIFICATION_BODY
  );
  const windowNotficationTitleTranslate = useTextTranslate(
    WINDOW_NOTIFICATION_TITLE
  );
  const sessionIdleWarningTimes = {
    minutesTranslated: useTextTranslate("minutes"),
    minuteTranslated: useTextTranslate("minute"),
    secondsTranslated: useTextTranslate("seconds"),
    secondTranslated: useTextTranslate("second"),
  };
  const title = windowNotficationTitleTranslate;
  const options = {
    body: `${windowNotficationBodyTranslate} ${getDurationForSeconds(
      warningTime,
      sessionIdleWarningTimes
    )}`,
    icon: "/images/instance.png?auto=compress&cs=tinysrgb&dpr=1&w=200",
    dir: "ltr",
    requireInteraction: true,
  };

  useEffect(() => {
    if ("Notification" in window) {
      Notification.requestPermission()
        .then((permission) => {
          logMessage(
            `Idle Timer`,
            `Request permission from user ${permission}`,
            `info`
          );
        })
        .catch((err) => {
          logMessage(
            `Idle Timer`,
            `Failed in request permission from user ${err}`,
            `error`
          );
        });
    }
  }, []);

  useEffect(() => {
    open ? showNotification() : destoryNotification();
  }, [open]);

  const showNotification = () => {
    if (notification === null) {
      const webNotification = new window.Notification(title, options);
      webNotification.addEventListener("click", onNotificationClick);
      webNotification.addEventListener("close", onCloseClick);
      setNotification(webNotification, () => {
        window.setTimeout(() => {
          notification.close();
        }, process.env.SYSTEM_IDLE_WARNING_TIME * 2000 * 5 || 5000);
      });
    }
  };

  const destoryNotification = () => {
    if (notification !== null) {
      notification.close();
      setNotification(null);
    }
  };

  const onNotificationClick = () => {
    handleOnAction();
  };

  const onCloseClick = () => {
    handleOnAction();
  };

  return null;
};

export default useNotification;
