import { createSlice } from "@reduxjs/toolkit";
import { getStopContactData, deleteStopContact } from "../services/stopContact";

const initialState = {
  stopContactStatus: "idle",
  stopContactLoading: false,
  stopContactData: [],
  isSCDelete: false,
  deleteStopContactLoading: false,
  isSCPending: false,
  isSCError: false,
};

const stopContactSlice = createSlice({
  name: "stopContact",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStopContactData.pending, (state) => {
        state.stopContactStatus = "pending";
        state.stopContactLoading = true;
        state.stopContactData = [];
        state.isSCPending = true;
        state.isSCDelete = false;
        state.isSCError = false;
      })
      .addCase(getStopContactData.fulfilled, (state, action) => {
        const { payload } = action;
        state.stopContactStatus = "success";
        state.stopContactLoading = false;
        state.stopContactData = payload.data;
        state.isSCPending = false;
        state.isSCError = false;
        state.isSCDelete = false;
      })
      .addCase(getStopContactData.rejected, (state) => {
        state.stopContactStatus = "rejected";
        state.stopContactLoading = false;
        state.stopContactData = [];
        state.isSCPending = false;
        state.isSCError = true;
        state.isSCDelete = false;
      })
      .addCase(deleteStopContact.pending, (state) => {
        state.isSCDelete = false;
        state.deleteStopContactLoading = true;
      })
      .addCase(deleteStopContact.fulfilled, (state, action) => {
        const { payload } = action;
        state.stopContactData = payload.data;
        state.isSCDelete = true;
        state.deleteStopContactLoading = false;
      })
      .addCase(deleteStopContact.rejected, (state) => {
        state.isSCDelete = false;
        state.deleteStopContactLoading = false;
      });
  },
});

export default stopContactSlice.reducer;
