import { styled } from "@mui/material/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

export const StyledDeleteButton = styled(({ ...props }) => (
  <IconButton {...props} slots={{ root: "span" }}>
    <DeleteIcon />
  </IconButton>
))(({ theme }) => ({
  cursor: "pointer",
  color: theme.palette.redAlert.main,
  fontSize: 16,
  marginTop: -0.5,
  height: 18,
  "&:focus": {
    outline: 0,
  },
  "&.MuiIconButton-root": {
    padding: 0,
    paddingLeft: "5px",
  },
}));
